import CreateStorePage from "../components/pages/stores/CreateStorePage";
import StoresPage from "../components/pages/stores/StoresPage";

const routes = [
    {
        path: "/stores",
        element: <StoresPage />
    },
    {
        path: "/stores/create",
        element: <CreateStorePage />
    }
];

export default routes;
