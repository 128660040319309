import React from "react";
import styled from "styled-components";
import GridCardComponent from "./generic/GridCardComponent";
import { defaultStatusColors, getSpanWithColor } from "../../../utils/CardUtils";

/* #region  SCOPED CSS */
const StyledPage = styled.div``;
/* #endregion */

/**
 * Renders a grid card component for displaying user information.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.className - The CSS class name for the component.
 * @param {Object} props.primaryButton - The primary button configuration.
 * @param {string} props.primaryButton.title - The title of the primary button.
 * @param {string} props.title - The title of the grid card.
 * @param {string} props.id - The ID of the grid card.
 * @param {boolean} props.hasBorders - Indicates whether the card has borders.
 * @param {string} props.titlePrefix - The prefix for the title.
 * @param {string} props.idPrefix - The prefix for the ID.
 * @param {boolean} props.isCardEnabled - Indicates whether the card is enabled.
 * @param {boolean} props.isCardSuspended - Indicates if the card is suspended.
 * @param {string} props.image - The image URL for the card.
 * @param {boolean} props.hideImage - Indicates whether the image is hidden.
 * @param {Object[]} props.details - The array of key-value pairs for displaying additional details.
 * @param {string} props.details[].key - The key of the detail.
 * @param {string} props.details[].value - The value of the detail.
 * @param {Object} props.data - The user data object.
 * @param {string} props.data.role - The role of the user.
 * @param {string} props.data.currentStatus - The current status of the user.
 * @param {string} props.data.createdBy - The creator of the user.
 * @param {string} props.data.createdOn - The creation date of the user.
 * @returns {JSX.Element} The rendered UsersGridCard component.
 */
export default function OrdersGridCard(props) {
    const status = props?.data?.status || "--";

    return (
        <StyledPage className={`${props?.className || ""}`}>
            <GridCardComponent
                primaryButton={{
                    title: props?.primaryButton?.title ?? "Preview",
                    onClick: props?.primaryButton?.onClick ? item => props?.primaryButton?.onClick(item) : undefined
                }}
                secondaryButton={props?.secondaryButton}
                title={props?.title}
                posLogo={props?.posLogo}
                id={props?.id}
                hasBorders={props?.hasBorders}
                titlePrefix={props?.titlePrefix || "ORDER NR."}
                idPrefix={props?.idPrefix || "BOTAG ID"}
                isCardEnabled={props?.isCardEnabled}
                isCardSuspended={props?.isCardSuspended}
                //useTitleLogo={props?.title || "-"}
                hideImage={props?.hideImage ?? false}
                image={props?.image}
                imageHasPadding={true}
                details={[
                    { key: "From Brand:", value: props?.data?.fromBrand || "--" },
                    { key: "To store:", value: props?.data?.toStore || "--" },
                    { key: "Total:", value: props?.data?.total || "--" },
                    { key: "Status:", value: getSpanWithColor(status ?? "--", defaultStatusColors) }
                ]}
                edit={{ hidden: true }}
                suspend={{ hidden: true }}
                delete={{ hidden: true }}
                cardData={props?.cardData}
            />
        </StyledPage>
    );
}
