import moment from "moment/moment";

/**
 *
 * @param {String} text - The text to be displayed.
 * @param {Object} colors - The colors object containing the color classes and their corresponding values.
 * @param {String} notFoundDefault - The default color class to be used.
 * @param {String} textEmptyDefault - The default text to be displayed if the text is empty.
 * @returns {JSX.Element} The span element with the color class.
 *
 * @example
 * const colors = [
 *    {
 *      colorClass: "text-green-500",
 *      forValues: ["Active", "Available"]
 *   },
 *   {
 *     colorClass: "text-red-500",
 *     forValues: ["Inactive", "Unavailable"]
 *   }];
 *
 * const span = getSpanWithColor("Active", colors, notFoundDefault);
 *
 */
export const getSpanWithColor = (
    text,
    colors = defaultStatusColors,
    notFoundDefault = "text-blue-500",
    textEmptyDefault = "--"
) => {
    try {
        if (!text || text === "" || text === undefined || text === null) {
            return <span className={notFoundDefault}>{textEmptyDefault}</span>;
        }

        let colorValue = notFoundDefault;
        let found = false;
        for (const color of colors) {
            for (const value of color.forValues) {
                if (value?.toLowerCase() === text?.toLowerCase()) {
                    found = true;
                    colorValue = color.colorClass;
                    break;
                }
            }
            if (found) break;
        }

        return <span className={colorValue}>{text}</span>;
    } catch (error) {
        console.error("getSpanWithColor", text);
        return <span className={notFoundDefault}>{textEmptyDefault}</span>;
    }
};

export const defaultStatusColors = [
    {
        colorClass: "text-blue-600",
        forValues: ["Active"]
    },
    {
        colorClass: "text-green-500",
        forValues: ["Available", "Exported to POS", "Invited", "Accepted", "Completed", "Connected"]
    },
    {
        colorClass: "text-orange-500",
        forValues: ["Suspended", "Pending", "N/A"]
    },
    {
        colorClass: "text-red-500",
        forValues: ["Inactive", "Unavailable", "Not connected", "Failed", "Cancelled"]
    },
    {
        colorClass: "text-gray-300",
        forValues: ["--"]
    }
];

export const getStatusValue = (row, statusFromPath) => {
    const fromPath = statusFromPath ?? "status";
    const status = getArrayFieldValue(row, fromPath.split(".") || "Unknown");
    //console.log("status", status);
    return status;
};

const getArrayFieldValue = (row, array) => {
    if (!Array.isArray(array)) return "Unknown";

    let res = row;
    for (let i = 0; i < array.length; i++) {
        res = res?.[array[i]];
    }
    return res;
};
