import React, { useEffect, useState } from "react";
import InfinityGridFFCsCards from "../../common/cardContainers/InfinityGridFFCsCards";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import ListComponent from "../../common/lists/ListComponent";
import { AppQueryClient } from "../../../api/queryClient";
import { useOutletContext, useParams } from "react-router";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: hidden;
        height: 100%;
        margin-right: -23px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }
`;
/* #endregion */

export default function FFCStock() {
    document.title = `${APP_TITLE} - Fullfillment Centers Stock`;

    /* #region STATES */
    const { MainLayoutProps, layoutType } = useOutletContext();
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };

    const { storeID, storeName } = useParams();
    const [viewLayoutStyle, setViewLayoutStyle] = useState("Grid");

    const infiniteGridQuerykey = "FFCStockGrid";
    const listQueryKey = "FFCStockList";
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(props, onClickRefreshAction, storeName);
    }, [props?.layoutType, storeName]);
    /* #endregion */

    /* #region LIST COMPONENT PROPS */
    const listColumns = [
        {
            name: "Product ID",
            selector: row => row?.BOtagID ?? "--"
        },
        {
            name: "Reference",
            selector: row => row?.reference ?? "--"
        },
        {
            name: "FFC Stock QTY",
            selector: row => row?.stockSum ?? "--"
        },
        {
            name: "SOLD",
            selector: row => row?.sold ?? "0" //TODO: NOT RETRIEVING FROM API... HARDCODED AS 0
        },
        {
            name: "Barcode",
            selector: row => row?.barcode ?? "--"
        },
        {
            name: "Color",
            selector: row => (row?.colorCode?.length ? getMultipleColors(row?.colorCode) : "--")
        },
        {
            name: "Size",
            selector: row => row?.size ?? "--"
        }
    ];

    const expandedRowsColumns = [
        {
            name: "Product ID",
            selector: row => row?.BOtagID ?? "--"
        },
        {
            name: "Reference",
            selector: row => row?.reference ?? "--"
        },
        {
            name: "FFC Stock QTY",
            selector: row => row?.ffcStock ?? "--"
        },
        {
            name: "SOLD",
            selector: row => row?.sold ?? "--"
        },
        {
            name: "Barcode",
            selector: row => row?.barcode ?? "--"
        },
        {
            name: "Color",
            selector: row => (row?.colors?.code ? getColorSquare(row?.colors?.code) : "--")
        },
        {
            name: "Size",
            selector: row => row?.size ?? "--"
        }
    ];
    /* #endregion */

    /* #region METHODS */
    const onClickRefreshAction = () => {
        if (props?.layoutType === "List") AppQueryClient.invalidateQueries(listQueryKey);
        else AppQueryClient.invalidateQueries(infiniteGridQuerykey);
    };

    const mapListData = data => {
        const result = data?.result?.map(item => {
            let stockTotal = 0;
            return {
                ...item,
                expandItems: item?.expandItems?.map(expandItem => {
                    stockTotal = stockTotal + expandItem?.ffcStock;
                    return {
                        ...expandItem,
                        BOtagID: item?.BOtagID,
                        reference: item?.reference
                    };
                }),
                stockSum: stockTotal
            };
        });

        //console.log("Mapped List Data: ", result);

        return {
            ...data,
            result: result
        };
    };

    /* #endregion */

    return (
        <StyledPage>
            {viewLayoutStyle === "Grid" && (
                <InfinityGridFFCsCards minColumnWidth="238px" limit={12} queryKey={infiniteGridQuerykey} />
            )}
            {viewLayoutStyle === "List" && (
                <ListComponent
                    title="Fulfilment Centers"
                    columns={listColumns}
                    api={{
                        endpoint: "getStocksData",
                        formData: {
                            arrayOfStoresIDs: [storeID],
                            companyID: localStorage.getItem("currentCompanyID")
                        },
                        onSuccess: mapListData
                    }}
                    pageLimit={10}
                    currentPage={1}
                    queryKey={listQueryKey}
                    selectableRows={false}
                    actions={{
                        includeActions: true,
                        editIsVisible: false,
                        suspendIsVisible: false,
                        removeIsVisible: false,
                        extraActions: [
                            {
                                title: "Details"
                            }
                        ]
                    }}
                    includeStatus={{
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: [
                            { colorClass: "text-green-500", forValues: ["Invited"] },
                            { colorClass: "text-blue-600", forValues: ["Active", "Fulfilling"] },
                            { colorClass: "text-red-500", forValues: ["Inactive", "Suspended", "Not connected"] }
                        ]
                    }}
                    expandableRows={true}
                    expandedRowsColumns={expandedRowsColumns}
                />
            )}
        </StyledPage>
    );
}

const getColorSquare = color => {
    let hexColor = color ?? "#000";
    if (!hexColor?.includes("#")) hexColor = "#" + hexColor;

    return (
        <div
            alt="colors"
            style={{
                width: "16px",
                height: "16px",
                backgroundColor: hexColor,
                border: `1px solid ${hexColor}`,
                borderRadius: "2px",
                marginRight: "4px"
            }}
        />
    );
};

const getMultipleColors = colors => {
    const resultDiv = (
        <div style={{ display: "flex", alignItems: "start" }}>
            {colors.map((color, index) => {
                return getColorSquare(color);
            })}
        </div>
    );

    return resultDiv;
};

function setupHeaders(props, onRefreshAction, storeFilterName = undefined) {
    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "FFCenters", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/ffcenters", active: false, disabled: false, title: "FFCENTERS" },
        { to: "/ffcenters/stock", active: true, disabled: false, title: "STOCK" }
    ]);
    props.setControlBarRightBtns([{ to: "#", active: true, disabled: false, title: "View", icon: "eye" }]);

    props.setFilterHeaderProperties({
        visible: true,
        gridEnabled: false,
        listEnabled: true,
        onRefreshAction: onRefreshAction,
        filterOptions: storeFilterName
            ? [
                  {
                      key: "storeID",
                      label: "Store: " + storeFilterName,
                      selected: true
                  }
              ]
            : undefined
    });
}
