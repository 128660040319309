import React from "react";
import styled from "styled-components";
import checkEnabled from "../../../assets/icons/globalComponentChecked.png";

/* #region region styles */
const CheckBoxStyles = styled.div`
    .main-checkbox {
        width: 16px;
        height: 16px;
        margin-bottom: 1px;
    }
`;
/* #endregion */

// TODO: Check if the "uncheckedSrc" prop shouldn't be an import instead of a prop
/**
 *
 * @component
 * @param {boolean} [isChecked] - its the state of setState which will be in the parent component.
 * @param {Function} [onCheckboxClick] - this function will trigger when the checkbox is being clicked.
 * @param {Function} [uncheckedSrc] - this is the image src for unchecked
 * @returns {JSX.Element} The rendered Checkbox component.
 */
const Checkbox = ({ isChecked = false, onCheckboxClick = () => {}, uncheckedSrc = "" }) => {
    return (
        <CheckBoxStyles>
            <span className="checkbox-container" onClick={onCheckboxClick}>
                <img className="main-checkbox" src={isChecked ? checkEnabled : uncheckedSrc} alt="Checkbox" />
            </span>
        </CheckBoxStyles>
    );
};

export default Checkbox;
