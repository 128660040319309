import moment from "moment/moment";

/**
 * Formats the price with a specified coin symbol and default error message.
 * @param {number|string} price - The price to be formatted.
 * @param {string} [coinSymbol=""] - The symbol of the coin to be appended to the formatted price.
 * @param {string} [defaultError="--"] - The default error message.
 * @param {boolean} [alwaysShowDecimals=true] - If true, always show 2 decimal places
 * @returns {string} The formatted price with the coin symbol.
 * @example formatPrice(1000, "USD", "--") // "1,000.00 USD"
 */
export const formatPrice = (price, coinSymbol = "", defaultError = "--", alwaysShowDecimals = true) => {
    if (price === null || isNaN(price)) return defaultError;
    price = typeof price === "string" ? price.replace(",", ".") : price.toString().replace(",", ".");

    //split decimal and integer from price
    const priceNonDecimal = price.split(".")[0];
    let priceDecimal = price.split(".")[1];

    //fill the priceDecimal with 0s if it's less than 2 characters
    if (priceDecimal && priceDecimal.length < 2 && alwaysShowDecimals) {
        priceDecimal += "0";
    } else if (priceDecimal && priceDecimal.length > 2) {
        priceDecimal = priceDecimal.slice(0, 2);
    }

    //format the price to use a dot as a thousand separator
    let formattedPrice = priceNonDecimal.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    //if there is a decimal, add it to the formatted price
    formattedPrice = priceDecimal ? formattedPrice + "," + priceDecimal : formattedPrice;

    return coinSymbol ? formattedPrice + " " + coinSymbol : formattedPrice;
};

/**
 * Formats the integer with a default error message.
 * @param {number|string} value - The integer to be formatted.
 * @param {string} [defaultValue="--"] - The default error message.
 * @param {boolean} [alwaysShowDecimals=false] - If true, always show 2 decimal places
 * @returns {string} The formatted integer.
 * @example formatInteger(1000, "--") // "1,000"
 */
export const formatInteger = (value, defaultValue = "--", alwaysShowDecimals = false) => {
    if (value === null || isNaN(value)) return defaultValue;

    return formatPrice(value, "", defaultValue, alwaysShowDecimals);
};

export const formatPersonFullName = (firstName, lastName, defaultValue = "--") => {
    let result = defaultValue;
    if (firstName && lastName) result = firstName + " " + lastName;
    else if (firstName) result = firstName;
    else if (lastName) result = lastName;
    else result = defaultValue;

    return result;
};

export const formatDefaultDateTime = (date, defaultError = "--") => {
    try {
        if (!date || date === "-") return defaultError;
        const res = moment(date)?.format("DD/MM/YYYY HH:mm") ?? defaultError;
        if (res === "Invalid date") return defaultError;
        return res;
    } catch (error) {
        console.error("formatDefaultDateTime", date);
        return defaultError;
    }
};

export const formatDefaultDate = (date, defaultError = "--") => {
    try {
        if (!date || date === "-") return defaultError;
        const res = moment(date)?.format("DD/MM/YYYY") ?? defaultError;
        if (res === "Invalid date") return defaultError;
        return res;
    } catch (error) {
        console.error("formatDefaultDateTime", date);
        return defaultError;
    }
};

export const formatDefaultDateTimeISO = (date, defaultError = "--") => {
    try {
        if (!date || date === "-") return defaultError;
        const res = moment(date)?.toISOString() ?? defaultError;
        if (res === "Invalid date") return defaultError;
        return res;
    } catch (error) {
        console.error("formatDefaultDateTime", date);
        return defaultError;
    }
};

/**
 * Formats a color hex value with a default error message.
 * @param {string} color - The color hex value to be formatted.
 * @param {string} [defaultError="#000000"] - The default error message to be returned if the color is invalid or empty.
 * @returns {string} The formatted color hex value.
 * @example formatColorHex("FF0000", "#000000") // "#FF0000"
 */
export const formatColorHex = (color, defaultError = "") => {
    try {
        if (!color) return defaultError;
        if (color.startsWith("#")) return color;
        return "#" + color;
    } catch (error) {
        console.error(`formatColorHex error for color: ${color}`);
        return defaultError;
    }
};

/**
 * Formats and compares two colors.
 *
 * @param {string} color1 - The first color to format and compare.
 * @param {string} color2 - The second color to format and compare.
 * @returns {boolean} Returns true if the formatted colors are equal, false otherwise.
 */
export const formatAndCompareColors = (color1, color2) => {
    const formattedColor1 = formatColorHex(color1?.toUpperCase());
    const formattedColor2 = formatColorHex(color2?.toUpperCase());

    return formattedColor1 === formattedColor2;
};
