import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { v4 as uuidv4 } from "uuid";

/**
 * Renders a skeleton card grid component for BOImages cards.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.minColumnWidth="311px"] - The minimum column width of the card.
 * @param {string} [props.height="166px"] - The height of the card.
 * @returns {JSX.Element} - The rendered skeleton card grid component.
 */
export default function BOImageCardGridSkeleton(props) {
    return (
        <SkeletonTheme baseColor="#141922" highlightColor="#262E3E" borderRadius="4px">
            <div
                style={{
                    backgroundColor: "var(--backgrounds-lines-n-400)",
                    padding: "8px",
                    minWidth: props?.minColumnWidth || "311px",
                    height: props?.height || "166px",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    borderRadius: "4px",
                    border: props?.hasBorders ? "1px solid var(--backgrounds-lines-n-100)" : "none"
                }}>
                <div
                    className="Frame313312"
                    style={{
                        alignSelf: "stretch",
                        display: "inline-flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        gap: "8px"
                    }}>
                    <Skeleton
                        className="Rectangle302"
                        style={{
                            width: "82px",
                            height: "150px",
                            borderRadius: "4px"
                        }}></Skeleton>
                    <div
                        className="Frame313313"
                        style={{
                            display: "inline-flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            gap: "0px",
                            width: "100%",
                            flex: "1 1 0"
                        }}>
                        <div
                            className="Frame313326"
                            style={{
                                alignSelf: "stretch",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                gap: "1px",
                                display: "flex",
                                position: "relative",
                                alignItems: "stretch"
                            }}>
                            <Skeleton width="100%" height="14px" className="Cap" />
                            <div
                                className="Frame313323"
                                style={{
                                    height: "60px"
                                }}>
                                <Skeleton className="LinenSeries" width="100%" height="20px" />
                                <div
                                    className="Frame313316"
                                    style={{
                                        marginTop: "4px",
                                        alignSelf: "stretch",
                                        justifyContent: "flex-start",
                                        WebkitBoxAlign: "center",
                                        alignItems: "center",
                                        gap: "8px",
                                        display: "inline-flex"
                                    }}>
                                    {Array.from({ length: 6 }, (_, i) => (
                                        <Skeleton
                                            key={uuidv4()}
                                            className="Rectangle"
                                            style={{
                                                width: "27px",
                                                height: "27px",
                                                borderRadius: "2px"
                                            }}></Skeleton>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div
                            className="Frame313328"
                            style={{
                                alignSelf: "stretch",
                                flex: "1 1 0",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                alignItems: "flex-start",
                                display: "flex"
                            }}>
                            <div
                                className="Frame313325"
                                style={{
                                    width: "100%",
                                    display: "inline-flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "8px"
                                }}>
                                {Array.from({ length: 6 }, (_, i) => (
                                    <Skeleton
                                        key={uuidv4()}
                                        className="ItemPanelBtnSmallRounded"
                                        borderRadius="55px"
                                        width="24px"
                                        height="16px"
                                    />
                                ))}
                            </div>
                            <div
                                className="Frame313324"
                                style={{
                                    width: "100%",
                                    marginTop: "10px"
                                }}>
                                <Skeleton
                                    className="BtnLargeSolidInverted02"
                                    width="100%"
                                    style={{
                                        width: "100%",
                                        height: "35px"
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SkeletonTheme>
    );
}
