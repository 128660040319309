import React from "react";
import styled from "styled-components";

const StyledElems = styled.div`
    .category-tag-container {
        display: flex;
        gap: 5px;
        background-color: #013774;
        padding: 5px;
        border-radius: 5px;
        align-items: center;
        height: 25px;
        width: max-content;
    }
    .category-tag-main {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
    }
    .category-tag-input {
        background: transparent;
        border: none;
    }
    .category-tag-input {
        display: flex;
        align-items: flex-start;
        flex: 1;
    }
    .category-tag-input input {
        background-color: transparent;
        flex: 1;
        outline: none;
        border: 0;
    }
    .category-tag-input input:focus {
        border: none;
        background-color: transparent;
        border-width: 0px;
        border-color: transparent;
    }
    .tags-input {
        &:focus-within {
            box-shadow: rgba(0, 174, 239, 0.1) 0px 0px 2px 1px, rgb(54 74 103) 0px 0px 8px 2px;
        }
    }
    .remove-button {
        cursor: pointer;
    }
`;

/**
 * Renders a tag component.
 *
 * @param {Object} props - The component props.
 * @param {number} props.index - The index of the tag.
 * @param {string} props.bgColor - The background color of the tag.
 * @param {string} props.tagName - The name of the tag.
 * @param {function} props.onRemoveClick - The function to call when the tag is removed.
 * @param {boolean} [props.hideRemoveButton=false] - Whether to hide the remove button.
 * @returns {JSX.Element} The rendered tag component.
 */
const TagComponent = ({ index, bgColor, tagName, onRemoveClick, hideRemoveButton = false }) => {
    return (
        <StyledElems className="category-tag-main">
            <div
                className="category-tag-container"
                key={index}
                style={{
                    backgroundColor: bgColor
                }}>
                <div style={{ fontSize: "12px", flexShrink: 0 }}>{tagName}</div>
                {!hideRemoveButton && (
                    <div onClick={onRemoveClick}>
                        <i
                            className="remove-button fa-solid fa-xmark"
                            style={{
                                fontSize: "13px",
                                fontWeight: "500"
                            }}></i>
                    </div>
                )}
            </div>
        </StyledElems>
    );
};

export default TagComponent;
