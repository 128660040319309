import React, { useEffect, useState } from "react";
import styled from "styled-components";
import WeekCustomCard from "./WeekCustomCard";

const StyledStoreHours = styled.div`
    &.frame-276,
    &.frame-276 * {
        box-sizing: border-box;
    }
    &.frame-276 {
        display: flex;
        flex-direction: column;

        padding: 16px 0px 0px 0px;
        gap: 24px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-285 {
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
`;

export default function StoreHours({ setStoreHoursData }) {
    /* #region VARS */
    const [mondayData, setMondayData] = useState({
        hourStart: "",
        hourEnd: "",
        closedAllDay: false,
        openAllDay: false
    });
    const [tuesdayData, setTuesdayData] = useState({
        hourStart: "",
        hourEnd: "",
        closedAllDay: false,
        openAllDay: false
    });
    const [wednesdayData, setWednesdayData] = useState({
        hourStart: "",
        hourEnd: "",
        closedAllDay: false,
        openAllDay: false
    });
    const [thursdayData, setThursdayData] = useState({
        hourStart: "",
        hourEnd: "",
        closedAllDay: false,
        openAllDay: false
    });
    const [fridayData, setFridayData] = useState({
        hourStart: "",
        hourEnd: "",
        closedAllDay: false,
        openAllDay: false
    });
    const [saturdayData, setSaturdayData] = useState({
        hourStart: "",
        hourEnd: "",
        closedAllDay: false,
        openAllDay: false
    });
    const [sundayData, setSundayData] = useState({
        hourStart: "",
        hourEnd: "",
        closedAllDay: false,
        openAllDay: false
    });

    const [sameTimeAllWeekDays, setSameTimeAllWeekDays] = useState(false);
    const [sameAsWeekDays, setSameAsWeekDays] = useState(false);
    /* #endregion */

    /* #region METHODS */
    const generateStoreHoursData = () => {
        setStoreHoursData({
            monday: { hourStart: mondayData.hourStart, hourEnd: mondayData.hourEnd },
            tuesday: { hourStart: tuesdayData.hourStart, hourEnd: tuesdayData.hourEnd },
            wednesday: { hourStart: wednesdayData.hourStart, hourEnd: wednesdayData.hourEnd },
            thursday: { hourStart: thursdayData.hourStart, hourEnd: thursdayData.hourEnd },
            friday: { hourStart: fridayData.hourStart, hourEnd: fridayData.hourEnd },
            saturday: { hourStart: saturdayData.hourStart, hourEnd: saturdayData.hourEnd },
            sunday: { hourStart: sundayData.hourStart, hourEnd: sundayData.hourEnd }
        });
    };

    const onClickSameTimeAllWeekDays = () => {
        setSameTimeAllWeekDays(true);

        // Copy the time from Monday to all week days
        setTuesdayData(mondayData);
        setWednesdayData(mondayData);
        setThursdayData(mondayData);
        setFridayData(mondayData);
    };

    const onClickWeekendSameAsWeekDays = () => {
        setSameAsWeekDays(true);

        // Copy the time from Monday to all week days
        setSaturdayData(mondayData);
        setSundayData(mondayData);
    };

    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        generateStoreHoursData();

        setSameTimeAllWeekDays(false);
    }, [mondayData, tuesdayData, wednesdayData, thursdayData, fridayData, saturdayData, sundayData]);

    /* #endregion */

    return (
        <StyledStoreHours className="frame-276 ov-auto-x">
            <div className="frame-285 w-100">
                <WeekCustomCard
                    title="Monday"
                    calendarData={mondayData}
                    setCalendarData={setMondayData}
                    onSpecialClicked={onClickSameTimeAllWeekDays}
                    specialIsEnabled={sameTimeAllWeekDays}
                />
                <WeekCustomCard title="Tuesday" calendarData={tuesdayData} setCalendarData={setTuesdayData} />
                <WeekCustomCard title="Wednesday" calendarData={wednesdayData} setCalendarData={setWednesdayData} />
                <WeekCustomCard title="Thursday" calendarData={thursdayData} setCalendarData={setThursdayData} />
                <WeekCustomCard title="Friday" calendarData={fridayData} setCalendarData={setFridayData} />
            </div>
            <div className="frame-285 w-100" style={{ paddingBottom: "2px" }}>
                <WeekCustomCard
                    title="Saturday"
                    calendarData={saturdayData}
                    setCalendarData={setSaturdayData}
                    onSpecialClicked={onClickWeekendSameAsWeekDays}
                    specialIsEnabled={sameAsWeekDays}
                />
                <WeekCustomCard title="Sunday" calendarData={sundayData} setCalendarData={setSundayData} />
                <WeekCustomCard isDummyCard={true} />
                <WeekCustomCard isDummyCard={true} />
                <WeekCustomCard isDummyCard={true} />
            </div>
        </StyledStoreHours>
    );
}
