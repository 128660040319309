import React, { useState, useEffect } from "react";
import noImage from "../../assets/images/no-image.png";
import imageLoadingErr from "../../assets/images/image-error.png";

/**
 * A component that loads an image from a URL and displays it.
 * If the image fails to load, a fallback image is displayed.
 *
 * NOTE: ALL PROPS ARE THE SAME AS THE NATIVE IMG TAG.
 *
 * @component
 * @param {Object} props - NOTE: ALL PROPS ARE THE SAME AS THE NATIVE IMG TAG.
 * @param {string} props.src - The URL of the image to load.
 * @param {string} [props.alt="Image"] - The alt text for the image.
 * @param {string} [props.imageError] - The URL of the image to display when the image fails to load.
 * @param {string} [props.noImage] - The URL of the image to display when the image URL is empty.
 * @param {string} [props.imageLoading] - The URL of the image to display while the image is loading.
 * @param {boolean} [props.hideOnError] - Indicates if the image should be hidden when it fails to load.
 * @param {boolean} [props.hideOnNoImage] - Indicates if the image should be hidden when the image URL is empty.
 * @returns {JSX.Element} The rendered ImageLoadingUrl component.
 */
export default function ImageLoadingUrl(props) {
    /* #region VARS */
    const [loading, setLoading] = useState(true);
    const [imageOldSrc, setImageOldSrc] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [imageUrlFailed, setImageUrlFailed] = useState(false);
    const [imageIsHidden, setImageIsHidden] = useState(false);

    const imgLoading = props?.imageLoading ?? imageLoadingErr;
    const imgError = props?.imageError ?? imageLoadingErr;
    const imgNoImage = props?.noImage ?? noImage;
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        /**
         * Fetches the image from the specified URL and sets the image URL state.
         * If the image fails to load, sets the image URL to a fallback image.
         *
         * @async
         * @function fetchImageFromUrl
         */
        const fetchImageFromUrl = async url => {
            try {
                setLoading(true);

                if (!validateImageSrc(props.src)) {
                    setImageUrl(imgNoImage);
                    setImageUrlFailed(true);
                    setLoading(false);

                    if (props?.hideOnNoImage) {
                        setImageIsHidden(true);
                    }
                    return;
                }

                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error("Image loading error");
                }
                const blob = await response.blob();
                setImageUrlFailed(false);
                setImageUrl(URL.createObjectURL(blob));
                setLoading(false);
            } catch (error) {
                setImageUrlFailed(true);
                setImageUrl(imgError);
                setLoading(false);
                if (props?.hideOnError) {
                    setImageIsHidden(true);
                }
            }
        };

        if (props.src === imageOldSrc) return;
        setImageOldSrc(props.src);
        fetchImageFromUrl(props.src);
    }, [props.src]);
    /* #endregion */

    /* #region METHODS */
    const validateImageSrc = src => {
        if ([null, undefined, "", "undefined", "-", "--"].includes(src)) {
            return false;
        }
        return true;
    };

    /* #endregion */

    return (
        <img
            {...props}
            alt={props.alt || "Image"}
            src={loading ? imgLoading : validateImageSrc(imageUrl) ? imageUrl : imgNoImage}
            style={{
                ...props.style,
                objectFit: imageUrlFailed ? "contain" : props?.style?.objectFit,
                padding: imageUrlFailed ? "0px" : props?.style?.padding,
                display: imageIsHidden ? "none" : props.style?.display
            }}
        />
    );
}
