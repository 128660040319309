import AccountPageDetails from "../components/pages/account/AccountPageDetails";
import AccountUsersPage from "../components/pages/account/AccountUsersPage";
import BillingPage from "../components/pages/account/BillingPage";

const routes = [
    {
        path: "/account",
        element: <AccountPageDetails />
    },
    {
        path: "/account/users",
        element: <AccountUsersPage />
    },
    {
        path: "/billing",
        element: <BillingPage />
    },
    {
        path: "/billing/:id",
        element: <BillingPage />
    }
];

export default routes;
