import BOImageCreate from "../components/pages/boimages/BOImageCreatorPage";
import BOImagesPage from "../components/pages/boimages/BOImagesPage";

const routes = [
    {
        path: "/boimages",
        element: <BOImagesPage />
    },
    {
        path: "/boimages/create",
        element: <BOImageCreate />
    },
    {
        path: "/boimages/edit/:BOimageID",
        element: <BOImageCreate />
    }
];

export default routes;
