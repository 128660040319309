import React, { useEffect, useRef, useState } from "react";
import ReactSelect from "react-select";
import styled from "styled-components";
import { selectDarkBackgroundStyle } from "../../../utils/utilHelpers";
import DotsGrid from "@untitled-ui/icons-react/build/esm/DotsGrid";
import List from "@untitled-ui/icons-react/build/esm/List";
import RefreshCcw01 from "@untitled-ui/icons-react/build/esm/RefreshCcw01";
import Save02 from "@untitled-ui/icons-react/build/esm/Save02";
import RefreshCcw05 from "@untitled-ui/icons-react/build/esm/RefreshCcw05";
import { ReactComponent as IconSearch } from "../../../assets/icons/icon-search.svg";
import { useLocation } from "react-router";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    .filter-section .form-control {
        //width: 348px;
        border-color: var(--backgrounds-lines-n-400);
        background: var(--backgrounds-lines-n-400);
        border-width: 1px;
        height: 37px;
        border-radius: 4px;
        max-width: none;
        font-style: italic;
        display: inline-flex;
        color: var(--text-icons-gray-300);

        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .filter-section .form-control::placeholder {
        color: var(--text-icons-gray-300);
    }

    .filter-section .form-control:hover,
    .filter-section btn:hover {
        border-color: var(--App-Accent-Outline);
    }

    .filter-btn {
        width: 40px;
        height: 37px;
        font-size: 16px;
        background-color: var(--backgrounds-lines-n-400);
        border-color: var(--backgrounds-lines-n-400);
        color: var(--text-icons-gray-100);
    }

    .filter-btn-with-label {
        display: inline-grid;
        width: auto;
        text-align: center;
        font-size: 10px;
        margin-left: 0px;
        position: relative;
    }

    .filter-btn-with-label > span {
        text-align: center;
        font-size: 10px;
        color: var(--text-icons-gray-200);
    }

    .filter-btn-with-label > .btn,
    .filter-btn-with-label > .btn:hover,
    .filter-btn-with-label.disabled > .btn,
    .filter-btn-with-label.disabled > .btn:hover {
        opacity: 1;
        background-color: var(--backgrounds-lines-n-400) !important;
        color: var(--text-icons-gray-100);
        border-color: var(--backgrounds-lines-n-400);
    }

    .filter-btn-with-label.disabled > .btn,
    .filter-btn-with-label.disabled > .btn:hover {
        color: var(--text-icons-gray-300);
        cursor: not-allowed;
    }

    .filter-btn-with-label > .btn:hover,
    .filter-btn-with-label.disabled > .btn:hover {
        border-color: var(--App-Accent-Outline);
    }

    .filter-section {
        display: flex;
        flex-basis: unset !important;
    }

    .filter-section.left {
        float: left;
    }

    .filter-section.right {
        float: right;
    }

    .filter-section > .selects {
        width: 290px;
        margin-right: 5px !important;
        min-width: 290px;
    }

    .filter-section > .selects > div {
        height: 37px;
    }

    .not-allowed {
        color: var(--text-icons-gray-300);
        cursor: not-allowed;
    }

    .filter-section > p {
        margin-right: 10px;
        padding: 0px 0px;
        color: var(--text-icons-gray-100);
        font-family: "Roboto";
        font-size: 12px;
        line-height: 37px;
        margin-bottom: 0;
    }

    .filter-section span {
        color: var(--text-icons-gray-200);
    }

    .showItems .sc-hHLeRK,
    .showItems .sc-hHLeRK.fhIruZ {
        display: block !important;
    }

    .filter-container {
        display: contents;
    }

    .view-type-select .active {
        border-color: var(--App-Accent-Active);
        background-color: var(--App-Accent-Active);
    }

    .btn-group .filter-btn:hover,
    .btn-group .filter-btn:focus,
    .btn-group .filter-btn:active {
        border-color: var(--App-Accent-Active) !important;
        background-color: var(--App-Accent-Active) !important;
        outline: none !important;
    }

    .btn-group .filter-btn.disabled:hover {
        background-color: var(--backgrounds-lines-n-400) !important;
    }

    .filter-btn:focus {
        outline: none !important;
    }

    .filter-btn.disabled:focus {
        background-color: var(--backgrounds-lines-n-400) !important;
    }
`;
/* #endregion */

/**
 * FiltersHeader provides a header with search and layout options for filtering data.
 *
 * @param {Function} onSearch Function to handle search input changes.
 * @param {String} layoutType Current layout type EX: "Grid" or "List".
 * @param {Function} onLayoutTypeChange Function to update layout type.
 * @param {Function} onRefreshAction Function to refresh data.
 * @param {Array} filterOptions Array with filter options.
 * @param {Array} onFilterAction Function to filter data.
 * @param {Function} onResetAction Reset action click.
 * @param {string} className - The CSS class name for the component.
 * @param {boolean} visible - The visibility of the component.
 * @param {boolean} gridEnabled - The visibility of the grid button.
 * @param {boolean} listEnabled - The visibility of the list button.
 * @returns {JSX.Element} The rendered FiltersHeader component.
 */
export default function FiltersHeader(props) {
    //console.log("FiltersHeader", props?);
    const {
        onSearch,
        layoutType,
        onLayoutTypeChange,
        onRefreshAction,
        filterOptions,
        onResetAction,
        className,
        visible
    } = props;

    const gridEnabled = props?.gridEnabled || false;
    const listEnabled = props?.listEnabled || false;

    /* #region STATES */
    const location = useLocation();
    const [searchtext, setSearchtext] = useState("");
    /* #endregion */

    useEffect(() => {
        if (!location) return;

        if (props?.layoutType === "List" && !props?.listEnabled && props?.gridEnabled && props?.onLayoutTypeChange) {
            props?.onLayoutTypeChange("Grid");
        } else if (
            props?.layoutType === "Grid" &&
            !props?.gridEnabled &&
            props?.listEnabled &&
            props?.onLayoutTypeChange
        ) {
            props?.onLayoutTypeChange("List");
        }
    }, [location, props?.layoutType, props?.listEnabled, props?.gridEnabled, props?.onLayoutTypeChange]);

    return (
        <StyledPage className={`filterheader-container ${className} ${visible === false ? "hidden" : ""}`}>
            <div className="filters-header flex-row" style={{ gap: "150px", justifyContent: "space-between" }}>
                <div className="filter-section flex-col flex-1" style={{ padding: "15px 0px", maxWidth: "350px" }}>
                    <input
                        className="form-control"
                        style={{ marginRight: "8px" }}
                        type="text"
                        placeholder="Search list"
                        aria-label="Search"
                        disabled={onSearch === undefined}
                        onChange={e => setSearchtext(e.target.value)}
                    />

                    <span className={`filter-btn-with-label ${onSearch === undefined ? "disabled" : ""}`}>
                        <button
                            type="button"
                            className={`btn btn-success filter-btn ${onSearch === undefined ? "disabled" : ""}`}
                            onClick={() => {
                                if (onSearch) onSearch(searchtext);
                            }}>
                            <IconSearch width={"16px"} />
                        </button>
                    </span>
                </div>
                <div
                    className={`filter-section flex-col  justify-content-end ${!filterOptions ? "not-allowed" : ""}`}
                    style={{ padding: "15px 0px" }}>
                    <p>Filters:</p>

                    <ReactSelect
                        className={`selects ${!filterOptions ? "disabled" : ""}`}
                        isDisabled={!filterOptions}
                        options={filterOptions}
                        value={
                            filterOptions
                                ? filterOptions
                                      .filter(option => option.selected)
                                      .map(option => ({ value: option.key, label: option.label }))
                                : undefined
                        }
                        styles={selectDarkBackgroundStyle}
                        placeholder={<div className="italic-placeholder">Select...</div>}
                    />

                    <span className="filter-container">
                        <span className="filter-btn-with-label disabled" style={{ marginRight: "5px" }}>
                            <button type="button" className="btn btn-success filter-btn" onClick={onResetAction}>
                                <RefreshCcw01 width={"16px"} />
                            </button>
                        </span>
                        <span className="filter-btn-with-label disabled" style={{ marginRight: "5px" }}>
                            <button type="button" className="btn btn-success filter-btn">
                                <Save02 width={"16px"} />
                            </button>
                        </span>
                        <span className={`filter-btn-with-label ${onRefreshAction === undefined ? "disabled" : ""}`}>
                            <button type="button" className="btn btn-success filter-btn" onClick={onRefreshAction}>
                                <RefreshCcw05 width={"16px"} />
                            </button>
                        </span>
                    </span>

                    <span className="view-type-select" style={{ marginLeft: "56px" }}>
                        <div className="btn-group flex">
                            <button
                                type="button"
                                className={`btn filter-btn grid-btn ${
                                    layoutType === "Grid" && gridEnabled ? "active" : ""
                                } ${!gridEnabled ? "disabled" : ""}`}
                                onClick={() => {
                                    if (onLayoutTypeChange && gridEnabled) onLayoutTypeChange("Grid");
                                }}>
                                <DotsGrid width={"16px"} />
                            </button>
                            <button
                                type="button"
                                className={`btn filter-btn list-btn ${
                                    layoutType === "List" && listEnabled ? "active" : ""
                                } ${!listEnabled ? "disabled" : ""}`}
                                onClick={() => {
                                    if (onLayoutTypeChange && listEnabled) onLayoutTypeChange("List");
                                }}>
                                <List width={"16px"} />
                            </button>
                        </div>
                    </span>
                </div>
            </div>
        </StyledPage>
    );
}
