import DashboardPage from "../components/pages/dashboard/DashboardPage";

const routes = [
    {
        path: "/dashboard",
        element: <DashboardPage />
    }
];

export default routes;
