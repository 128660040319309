import "../../../assets/css/components/TotalBotagSales.css";
import TotalSalesGraph from "../../pages/dashboardGraphs/TotalSalesGraph";
import { totalSalesChartOptions } from "../../../utils/utilHelpers";

const TotalBotagSales = ({ totalSalesChartSeries }) => {
    return (
        <div className="total-botag-sales-container">
            <div className="total-botag-heading">
                <p style={{ color: "#D1D1D1" }}>
                    TOTAL SALES &nbsp; <i className="fa-light fa-circle-info"></i>
                </p>
            </div>
            <div className="total-botag-graph-container">
                <div>
                    <h2>
                        {(Math.random() * 9).toFixed(5)} <span style={{ fontSize: "18px" }}>NOK</span>
                    </h2>
                    <p style={{ color: "#D1D1D1" }}>--% compared to set period</p>
                </div>
                <div>
                    <TotalSalesGraph
                        type="line"
                        height={"28px"}
                        options={totalSalesChartOptions}
                        series={totalSalesChartSeries}
                    />
                </div>
            </div>

            <div>
                <div className="total-botag-sales-hr"></div>
            </div>
            <div className="total-botag-footer">
                <div>
                    <p style={{ color: "#D1D1D1", fontSize: "12px" }}>Total orders</p>
                    <p style={{ fontSize: "16px", color: "#ffffff" }}>{Math.ceil(Math.random() * 600)}</p>
                </div>
                <div>
                    <p style={{ color: "#D1D1D1", fontSize: "12px" }}>Avg. order</p>
                    <p style={{ fontSize: "16px", color: "#ffffff" }}>{(Math.random() * 20).toFixed(2)} kr</p>
                </div>
            </div>
        </div>
    );
};

export default TotalBotagSales;
