import React from "react";

const TermsAndConditions = ({ setShowTAC }) => {
    return (
        <>
            <div className="terms-condition-container">
                <div className="terms-main-condition">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "10px"
                        }}>
                        <div>
                            <div className="dropdown">
                                <button
                                    className="download-btn btn-outline-none
                            dropdown-toggle bg-black order-preview-drop-down"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "0.7rem",
                                        color: "white",
                                        backgroundColor: "#1E2631",
                                        border: "none",
                                        fontSize: "10px",
                                        fontWeight: 400,
                                        lineHeight: "11.72px"
                                    }}>
                                    <i className="fa-regular fa-arrow-down-to-line" style={{ fontSize: "10px" }}></i>
                                    <div style={{ fontSize: "10px" }}>DOWNLOAD</div>
                                </button>
                            </div>
                        </div>
                        <div
                            onClick={() => {
                                setShowTAC(false);
                            }}>
                            <i className="fa-regular fa-xmark"></i>
                        </div>
                    </div>
                    <div style={{ fontSize: "20px", fontWeight: 400, fontFamily: "Roboto", lineHeight: "23.44px" }}>
                        Botag Norge AS Privacy Policy
                    </div>
                    <div style={{ margin: "10px 0px" }}>
                        <span style={{ color: "#7c868b", fontSize: "14px", fontWeight: 400 }}>latest:01/08/2022</span>
                    </div>
                    <div className="">
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, nesciunt? Voluptate et
                            accusantium unde aspernatur quo enim vitae voluptates ipsa cupiditate modi pariatur earum
                            placeat, tempora optio esse distinctio eligendi maxime! Culpa, praesentium beatae.
                        </p>
                    </div>
                    <div className="">
                        <h3>1. Sed et ipsum sit amet eros</h3>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis distinctio eius sunt, modi
                            illo non tempore laborum reprehenderit, facere quaerat provident deleniti quam mollitia,
                            doloremque placeat! Quas quibusdam voluptatibus ipsum in iure officia blanditiis, odio
                            obcaecati, odit illo repellendus dicta nisi. Culpa mollitia expedita perspiciatis aliasillo
                            non tempore laborum reprehenderit, facere quaerat provident deleniti quam mollitia,
                            doloremque placeat! Quas quibusdam voluptatibus ipsum in iure officia blanditiis, odio
                            obcaecati, odit illo repellendus dicta nisi. Culpa mollitia expedita perspiciatis alias non
                            tempore laborum reprehenderit, facere quaerat provident deleniti quam mollitia, doloremque
                            placeat! Quas quibusdam voluptatibus ipsum in iure officia blanditiis, odio obcaecati, odit
                            illo repellendus dicta nisi. Culpa mollitia expedita perspiciatis alias sapiente debitis,
                            tenetur facere.
                        </p>
                    </div>{" "}
                    <div className="">
                        <h3>2. lorem ipsum</h3>
                        <p>
                            obcaecati, odit illo repellendus dicta nisi. Culpa mollitia expedita perspiciatis alias non
                            tempore laborum reprehenderit, facere quaerat provident deleniti quam mollitia, doloremque
                            placeat! Quas quibusdam voluptatibus ipsum in iure officia blanditiis, odio obcaecati, odit
                            illo repellendus dicta nisi. Culpa mollitia expedita perspiciatis alias sapiente debitis,
                        </p>
                    </div>{" "}
                    <div className="">
                        <h3>3. lorem ipsum</h3>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis distinctio eius sunt, modi
                            illo non tempore laborum reprehenderit, facere quaerat provident deleniti quam mollitia,
                            doloremque placeat! Quas quibusdam voluptatibus ipsum in iure officia blanditiis, odio
                            obcaecati, odit illo repellendus dicta nisi. Culpa mollitia expedita perspiciatis aliasillo
                            non tempore laborum reprehenderit, facere quaerat provident deleniti quam mollitia,
                            doloremque placeat! Quas quibusdam voluptatibus ipsum in iure officia blanditiis, odio
                            obcaecati, odit illo repellendus dicta nisi. Culpa mollitia expedita perspiciatis alias non
                            tempore laborum reprehenderit, facere quaerat provident deleniti quam mollitia, doloremque
                            placeat! Quas quibusdam voluptatibus ipsum in iure officia blanditiis, odio obcaecati, odit
                            illo repellendus dicta nisi. Culpa mollitia expedita perspiciatis alias sapiente debitis,
                            tenetur facere.
                        </p>
                    </div>{" "}
                    <div className="">
                        <h3>4. lorem ipsum</h3>
                        <p>
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Id nihil cum dolore neque ipsam
                            obcaecati, odit illo repellendus dicta nisi. Culpa mollitia expedita perspiciatis alias non
                            tempore laborum reprehenderit, facere quaerat provident deleniti quam mollitia, doloremque
                            placeat! Quas quibusdam voluptatibus ipsum in iure officia blanditiis, odio obcaecati, odit
                            illo repellendus dicta nisi. Culpa mollitia expedita perspiciatis alias sapiente debitis,
                            tenetur facere.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TermsAndConditions;
