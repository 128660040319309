import React from "react";
import styled from "styled-components";

const StyledToggleSwitch = styled.div`
    &.toggle-switch {
        flex-shrink: 0;
        width: 45px;
        height: 19px;
        position: relative;
    }
    .rectangle-1 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 54px;
        width: 97.83%;
        height: 86.36%;
        position: absolute;
        right: 0%;
        left: 2.17%;
        bottom: 4.55%;
        top: 9.09%;
    }
    .group-1 {
        position: absolute;
        inset: 0;
    }
    .ellipse-1 {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-radius: 50%;
        width: 42.22%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 57.78%;
        bottom: 0%;
        top: 0%;
    }
    .ellipse-2 {
        background: var(--text-and-icons-green-500, #00bb00);
        border-radius: 50%;
        width: 23.03%;
        height: 54.55%;
        position: absolute;
        right: 9.6%;
        left: 67.37%;
        bottom: 22.73%;
        top: 22.73%;
    }
`;

export default function ToggleSwitch({ isEnabled = false, setIsEnabled }) {
    return (
        <StyledToggleSwitch className="toggle-switch cursor-pointer" onClick={() => setIsEnabled(!isEnabled)}>
            <div className="rectangle-1"></div>
            <div className="ellipse-1" style={{ left: !isEnabled ? "0" : "" }}></div>
            <div
                className="ellipse-2"
                style={{ left: !isEnabled ? "4px" : "", backgroundColor: isEnabled ? "" : "#FF9900" }}></div>
        </StyledToggleSwitch>
    );
}
