import dayjs from "dayjs";
import { toast } from "react-toastify";
// import iconError from "../../../assets/images/icons-error-48.png";
// import errorSuccess from "./../../../assets/images/icons8-check-64.png";
import iconError from "../assets/images/icons-error-48.png";
import errorSuccess from "../assets/images/icons8-check-64.png";

export const colourStyles = {
    control: (styles, state) => ({
        ...styles,
        "backgroundColor": "var(--backgrounds-lines-n-600)",
        "borderColor": "transparent",
        "border": state.isFocused ? "1px solid var(--App-Accent-Outline)" : "1px solid #333F50",
        "boxShadow": state.isFocused ? "0px 0px 1px transparent" : "none",
        "&:hover": {
            border: "1px solid var(--App-Accent-Outline)"
        },
        "&:active": {
            border: "1px solid var(--App-Accent-Outline)"
        }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            "backgroundColor": "#333F50",
            "color": "var(--text-icons-gray-100)",
            "cursor": "pointer",
            "border": "1px solid var(--backgrounds-lines-n-600)",
            "borderLeft": "1px solid transparent",
            "borderRight": "1px solid transparent",
            "&:hover": {
                border: "1px solid var(--App-Accent-Outline)"
            },
            "&:active": {
                backgroundColor: "#333F50"
            }
        };
    },
    noOptionsMessage: styles => ({
        ...styles,
        backgroundColor: "#333F50",
        color: "var(--text-icons-gray-100)"
    }),
    indicatorSeparator: styles => ({ ...styles, backgroundColor: "var(--backgrounds-lines-n-600)" }),
    menu: styles => ({
        ...styles,
        backgroundColor: "var(--backgrounds-lines-n-600)",
        marginTop: 0,
        borderRadius: 0
    }),
    menuList: styles => ({
        ...styles,
        "padding": 0,
        "paddingRight": "2px",
        "::-webkit-scrollbar-thumb": {
            borderRadius: "10px"
        }
    }),
    singleValue: styles => ({ ...styles, color: "var(--text-icons-gray-100)" }),
    input: styles => ({ ...styles, color: "var(--text-icons-gray-100)" }),
    placeholder: styles => ({ ...styles, color: "var(--text-icons-gray-100)" }),
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: "var(--text-icons-gray-100)",
        backgroundColor: "#205380",
        borderRadius: "0px"
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        "color": "var(--text-icons-gray-100)",
        "backgroundColor": "#205380",
        "borderRadius": "0px",
        ":hover": {
            backgroundColor: "#205380",
            color: "white"
        }
    })
};

export const selectDarkBackgroundStyle = {
    control: (styles, state) => ({
        ...styles,
        "backgroundColor": "var(--backgrounds-lines-n-400)",
        "borderColor": "transparent",
        "boxShadow": state.isFocused ? "0px 0px 1px transparent" : "none",
        "&:hover": {
            border: "1px solid var(--App-Accent-Outline)"
        },
        "&:active": {
            border: "1px solid var(--App-Accent-Outline)"
        }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            "backgroundColor": "#333F50",
            "color": "var(--text-icons-gray-100)",
            "cursor": "pointer",
            "border": "1px solid var(--backgrounds-lines-n-600)",
            "borderLeft": "1px solid transparent",
            "borderRight": "1px solid transparent",
            "&:hover": {
                border: "1px solid var(--App-Accent-Outline)"
            },
            "&:active": {
                backgroundColor: "#333F50"
            }
        };
    },
    noOptionsMessage: styles => ({
        ...styles,
        backgroundColor: "#333F50",
        color: "var(--text-icons-gray-100)"
    }),
    dropdownIndicator: styles => ({ ...styles, color: "#D1D1D1" }),
    indicatorSeparator: styles => ({ ...styles, backgroundColor: "transparent" }),
    menu: styles => ({
        ...styles,
        backgroundColor: "var(--backgrounds-lines-n-600)",
        marginTop: 0,
        borderRadius: 0,
        textAlign: "left"
    }),
    menuList: styles => ({
        ...styles,
        "padding": 0,
        "paddingRight": "2px",
        "::-webkit-scrollbar-thumb": {
            borderRadius: "10px"
        }
    }),
    singleValue: styles => ({ ...styles, color: "var(--text-icons-green-500)", textAlign: "left" }),
    input: styles => ({ ...styles, color: "var(--text-icons-gray-100)" }),
    placeholder: styles => ({
        ...styles,
        color: "var(--Buttons-All-Platforms-Gray-200)",
        fontSize: "12px",
        fontStyle: "italic",
        fontWeight: "400",
        textAlign: "left"
    }),
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: "var(--text-icons-gray-100)",
        backgroundColor: "#205380",
        borderRadius: "0px"
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        "color": "var(--text-icons-gray-100)",
        "backgroundColor": "#205380",
        "borderRadius": "0px",
        ":hover": {
            backgroundColor: "#205380",
            color: "white"
        }
    })
};

export const selectDarkInputBackgroundStyle = {
    ...selectDarkBackgroundStyle,
    control: (styles, state) => ({
        ...styles,
        "backgroundColor": "#10141b",
        "borderColor": "transparent",
        "boxShadow": state.isFocused ? "0px 0px 1px transparent" : "none",
        "&:hover": {
            border: "1px solid var(--App-Accent-Outline)"
        },
        "&:active": {
            border: "1px solid var(--App-Accent-Outline)"
        }
    })
};

export const colorPicker = type => {
    if (type.toLowerCase() === "manual") {
        return "#7233DA";
    } else if (type.toLowerCase() === "boai") {
        return "#2F5279";
    } else if (type.toLowerCase() === "chatgpt") {
        return "#0060CC";
    }
};
export const filterOptions = (data = [], storeName = "", cb = () => {}) => {
    return data
        ?.filter(item => item?.name?.toLowerCase()?.startsWith(storeName))
        ?.map(item => ({
            value: item.name,
            label: (
                <div
                    onClick={() => {
                        cb(item._id);
                    }}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexDirection: "row"
                    }}>
                    <div>{item.name}</div>
                </div>
            ),
            isDisabled: item?.affiliatedToGroup === true ? true : false
        }));
};

// ______________

const handleGetDate = minusDate => {
    let d = new Date();
    d = d.setDate(d.getDate() - minusDate);
    return d;
};

export const visitorChartSeriesA = [
    {
        name: "BOtag sales",
        data: [
            [handleGetDate(77), 13],
            [handleGetDate(76), 13],
            [handleGetDate(75), 6],
            [handleGetDate(73), 6],
            [handleGetDate(72), 6],
            [handleGetDate(71), 5],
            [handleGetDate(70), 5],
            [handleGetDate(69), 5],
            [handleGetDate(68), 6],
            [handleGetDate(67), 7],
            [handleGetDate(66), 6],
            [handleGetDate(65), 9],
            [handleGetDate(64), 9],
            [handleGetDate(63), 8],
            [handleGetDate(62), 10],
            [handleGetDate(61), 10],
            [handleGetDate(60), 10],
            [handleGetDate(59), 10],
            [handleGetDate(58), 9],
            [handleGetDate(57), 9],
            [handleGetDate(56), 10],
            [handleGetDate(55), 9],
            [handleGetDate(54), 9],
            [handleGetDate(53), 8],
            [handleGetDate(52), 8],
            [handleGetDate(51), 8],
            [handleGetDate(50), 8],
            [handleGetDate(49), 8],
            [handleGetDate(48), 7],
            [handleGetDate(47), 7],
            [handleGetDate(46), 6],
            [handleGetDate(45), 6],
            [handleGetDate(44), 6],
            [handleGetDate(43), 6],
            [handleGetDate(42), 5],
            [handleGetDate(41), 5],
            [handleGetDate(40), 4],
            [handleGetDate(39), 4],
            [handleGetDate(38), 5],
            [handleGetDate(37), 5],
            [handleGetDate(36), 5],
            [handleGetDate(35), 7],
            [handleGetDate(34), 7],
            [handleGetDate(33), 7],
            [handleGetDate(32), 10],
            [handleGetDate(31), 9],
            [handleGetDate(30), 9],
            [handleGetDate(29), 10],
            [handleGetDate(28), 11],
            [handleGetDate(27), 11],
            [handleGetDate(26), 8],
            [handleGetDate(25), 8],
            [handleGetDate(24), 7],
            [handleGetDate(23), 8],
            [handleGetDate(22), 9],
            [handleGetDate(21), 8],
            [handleGetDate(20), 9],
            [handleGetDate(19), 10],
            [handleGetDate(18), 9],
            [handleGetDate(17), 10],
            [handleGetDate(16), 16],
            [handleGetDate(15), 17],
            [handleGetDate(14), 16],
            [handleGetDate(13), 17],
            [handleGetDate(12), 16],
            [handleGetDate(11), 15],
            [handleGetDate(10), 14],
            [handleGetDate(9), 24],
            [handleGetDate(8), 18],
            [handleGetDate(7), 15],
            [handleGetDate(6), 14],
            [handleGetDate(5), 16],
            [handleGetDate(4), 16],
            [handleGetDate(3), 17],
            [handleGetDate(2), 7],
            [handleGetDate(1), 7],
            [handleGetDate(0), 7]
        ]
    },
    {
        name: "Overall sales",
        data: [
            [handleGetDate(77), 14],
            [handleGetDate(76), 13],
            [handleGetDate(75), 15],
            [handleGetDate(73), 14],
            [handleGetDate(72), 13],
            [handleGetDate(71), 15],
            [handleGetDate(70), 16],
            [handleGetDate(69), 16],
            [handleGetDate(68), 14],
            [handleGetDate(67), 14],
            [handleGetDate(66), 13],
            [handleGetDate(65), 12],
            [handleGetDate(64), 13],
            [handleGetDate(63), 13],
            [handleGetDate(62), 15],
            [handleGetDate(61), 16],
            [handleGetDate(60), 16],
            [handleGetDate(59), 17],
            [handleGetDate(58), 17],
            [handleGetDate(57), 18],
            [handleGetDate(56), 15],
            [handleGetDate(55), 15],
            [handleGetDate(54), 15],
            [handleGetDate(53), 19],
            [handleGetDate(52), 19],
            [handleGetDate(51), 18],
            [handleGetDate(50), 18],
            [handleGetDate(49), 17],
            [handleGetDate(48), 16],
            [handleGetDate(47), 18],
            [handleGetDate(46), 18],
            [handleGetDate(45), 18],
            [handleGetDate(44), 16],
            [handleGetDate(43), 14],
            [handleGetDate(42), 14],
            [handleGetDate(41), 13],
            [handleGetDate(40), 14],
            [handleGetDate(39), 13],
            [handleGetDate(38), 10],
            [handleGetDate(37), 9],
            [handleGetDate(36), 10],
            [handleGetDate(35), 11],
            [handleGetDate(34), 11],
            [handleGetDate(33), 11],
            [handleGetDate(32), 10],
            [handleGetDate(31), 9],
            [handleGetDate(30), 10],
            [handleGetDate(29), 13],
            [handleGetDate(28), 14],
            [handleGetDate(27), 14],
            [handleGetDate(26), 13],
            [handleGetDate(25), 12],
            [handleGetDate(24), 11],
            [handleGetDate(23), 13],
            [handleGetDate(22), 13],
            [handleGetDate(21), 13],
            [handleGetDate(20), 13],
            [handleGetDate(19), 14],
            [handleGetDate(18), 13],
            [handleGetDate(17), 13],
            [handleGetDate(16), 19],
            [handleGetDate(15), 21],
            [handleGetDate(14), 22],
            [handleGetDate(13), 25],
            [handleGetDate(12), 24],
            [handleGetDate(11), 24],
            [handleGetDate(10), 22],
            [handleGetDate(9), 16],
            [handleGetDate(8), 15],
            [handleGetDate(7), 12],
            [handleGetDate(6), 12],
            [handleGetDate(5), 15],
            [handleGetDate(4), 15],
            [handleGetDate(3), 15],
            [handleGetDate(2), 18],
            [handleGetDate(2), 18],
            [handleGetDate(0), 17]
        ]
    }
];
export const visitorChartSeriesB = [
    {
        name: "BOtag sales",
        data: [
            [handleGetDate(77), 15],
            [handleGetDate(76), 14],
            [handleGetDate(75), 8],
            [handleGetDate(73), 7],
            [handleGetDate(72), 8],
            [handleGetDate(71), 6],
            [handleGetDate(70), 7],
            [handleGetDate(69), 6],
            [handleGetDate(68), 7],
            [handleGetDate(67), 8],
            [handleGetDate(66), 7],
            [handleGetDate(65), 10],
            [handleGetDate(64), 11],
            [handleGetDate(63), 10],
            [handleGetDate(62), 11],
            [handleGetDate(61), 12],
            [handleGetDate(60), 11],
            [handleGetDate(59), 12],
            [handleGetDate(58), 11],
            [handleGetDate(57), 12],
            [handleGetDate(56), 11],
            [handleGetDate(55), 10],
            [handleGetDate(54), 10],
            [handleGetDate(53), 9],
            [handleGetDate(52), 9],
            [handleGetDate(51), 9],
            [handleGetDate(50), 8],
            [handleGetDate(49), 8],
            [handleGetDate(48), 8],
            [handleGetDate(47), 7],
            [handleGetDate(46), 7],
            [handleGetDate(45), 7],
            [handleGetDate(44), 6],
            [handleGetDate(43), 6],
            [handleGetDate(42), 6],
            [handleGetDate(41), 6],
            [handleGetDate(40), 5],
            [handleGetDate(39), 5],
            [handleGetDate(38), 5],
            [handleGetDate(37), 5],
            [handleGetDate(36), 4],
            [handleGetDate(35), 4],
            [handleGetDate(34), 4],
            [handleGetDate(33), 5],
            [handleGetDate(32), 5],
            [handleGetDate(31), 6],
            [handleGetDate(30), 7],
            [handleGetDate(29), 8],
            [handleGetDate(28), 8],
            [handleGetDate(27), 9],
            [handleGetDate(26), 9],
            [handleGetDate(25), 9],
            [handleGetDate(24), 10],
            [handleGetDate(23), 11],
            [handleGetDate(22), 11],
            [handleGetDate(21), 12],
            [handleGetDate(20), 12],
            [handleGetDate(19), 12],
            [handleGetDate(18), 13],
            [handleGetDate(17), 13],
            [handleGetDate(16), 14],
            [handleGetDate(15), 15],
            [handleGetDate(14), 15],
            [handleGetDate(13), 16],
            [handleGetDate(12), 16],
            [handleGetDate(11), 17],
            [handleGetDate(10), 18],
            [handleGetDate(9), 18],
            [handleGetDate(8), 17],
            [handleGetDate(7), 17],
            [handleGetDate(6), 16],
            [handleGetDate(5), 15],
            [handleGetDate(4), 14],
            [handleGetDate(3), 14],
            [handleGetDate(2), 13],
            [handleGetDate(1), 12],
            [handleGetDate(0), 11]
        ]
    },
    {
        name: "Overall sales",
        data: [
            [handleGetDate(77), 13],
            [handleGetDate(76), 13],
            [handleGetDate(75), 14],
            [handleGetDate(73), 14],
            [handleGetDate(72), 13],
            [handleGetDate(71), 12],
            [handleGetDate(70), 12],
            [handleGetDate(69), 11],
            [handleGetDate(68), 11],
            [handleGetDate(67), 12],
            [handleGetDate(66), 11],
            [handleGetDate(65), 10],
            [handleGetDate(64), 10],
            [handleGetDate(63), 9],
            [handleGetDate(62), 9],
            [handleGetDate(61), 8],
            [handleGetDate(60), 8],
            [handleGetDate(59), 9],
            [handleGetDate(58), 9],
            [handleGetDate(57), 8],
            [handleGetDate(56), 7],
            [handleGetDate(55), 7],
            [handleGetDate(54), 7],
            [handleGetDate(53), 7],
            [handleGetDate(52), 7],
            [handleGetDate(51), 6],
            [handleGetDate(50), 6],
            [handleGetDate(49), 6],
            [handleGetDate(48), 5],
            [handleGetDate(47), 5],
            [handleGetDate(46), 5],
            [handleGetDate(45), 5],
            [handleGetDate(44), 4],
            [handleGetDate(43), 4],
            [handleGetDate(42), 4],
            [handleGetDate(41), 4],
            [handleGetDate(40), 5],
            [handleGetDate(39), 5],
            [handleGetDate(38), 6],
            [handleGetDate(37), 6],
            [handleGetDate(36), 6],
            [handleGetDate(35), 7],
            [handleGetDate(34), 7],
            [handleGetDate(33), 7],
            [handleGetDate(32), 8],
            [handleGetDate(31), 8],
            [handleGetDate(30), 8],
            [handleGetDate(29), 9],
            [handleGetDate(28), 9],
            [handleGetDate(27), 10],
            [handleGetDate(26), 10],
            [handleGetDate(25), 11],
            [handleGetDate(24), 11],
            [handleGetDate(23), 11],
            [handleGetDate(22), 12],
            [handleGetDate(21), 12],
            [handleGetDate(20), 13],
            [handleGetDate(19), 13],
            [handleGetDate(18), 14],
            [handleGetDate(17), 14],
            [handleGetDate(16), 14],
            [handleGetDate(15), 15],
            [handleGetDate(14), 15],
            [handleGetDate(13), 15],
            [handleGetDate(12), 16],
            [handleGetDate(11), 16],
            [handleGetDate(10), 16],
            [handleGetDate(9), 17],
            [handleGetDate(8), 17],
            [handleGetDate(7), 18],
            [handleGetDate(6), 18],
            [handleGetDate(5), 17],
            [handleGetDate(4), 17],
            [handleGetDate(3), 16],
            [handleGetDate(2), 16],
            [handleGetDate(1), 15],
            [handleGetDate(0), 15]
        ]
    }
];

export const visitorChartSeriesC = [
    {
        name: "BOtag sales",
        data: [
            [handleGetDate(77), 10],
            [handleGetDate(76), 12],
            [handleGetDate(75), 8],
            [handleGetDate(73), 7],
            [handleGetDate(72), 6],
            [handleGetDate(71), 5],
            [handleGetDate(70), 5],
            [handleGetDate(69), 5],
            [handleGetDate(68), 7],
            [handleGetDate(67), 8],
            [handleGetDate(66), 7],
            [handleGetDate(65), 9],
            [handleGetDate(64), 9],
            [handleGetDate(63), 10],
            [handleGetDate(62), 11],
            [handleGetDate(61), 11],
            [handleGetDate(60), 12],
            [handleGetDate(59), 13],
            [handleGetDate(58), 14],
            [handleGetDate(57), 14],
            [handleGetDate(56), 14],
            [handleGetDate(55), 14],
            [handleGetDate(54), 14],
            [handleGetDate(53), 15],
            [handleGetDate(52), 15],
            [handleGetDate(51), 15],
            [handleGetDate(50), 15],
            [handleGetDate(49), 16],
            [handleGetDate(48), 16],
            [handleGetDate(47), 17],
            [handleGetDate(46), 17],
            [handleGetDate(45), 17],
            [handleGetDate(44), 18],
            [handleGetDate(43), 18],
            [handleGetDate(42), 19],
            [handleGetDate(41), 19],
            [handleGetDate(40), 20],
            [handleGetDate(39), 20],
            [handleGetDate(38), 21],
            [handleGetDate(37), 21],
            [handleGetDate(36), 22],
            [handleGetDate(35), 22],
            [handleGetDate(34), 23],
            [handleGetDate(33), 23],
            [handleGetDate(32), 24],
            [handleGetDate(31), 24],
            [handleGetDate(30), 25],
            [handleGetDate(29), 25],
            [handleGetDate(28), 25],
            [handleGetDate(27), 26],
            [handleGetDate(26), 26],
            [handleGetDate(25), 27],
            [handleGetDate(24), 27],
            [handleGetDate(23), 28],
            [handleGetDate(22), 28],
            [handleGetDate(21), 29],
            [handleGetDate(20), 29],
            [handleGetDate(19), 30],
            [handleGetDate(18), 30],
            [handleGetDate(17), 30],
            [handleGetDate(16), 31],
            [handleGetDate(15), 31],
            [handleGetDate(14), 32],
            [handleGetDate(13), 32],
            [handleGetDate(12), 33],
            [handleGetDate(11), 33],
            [handleGetDate(10), 34],
            [handleGetDate(9), 34],
            [handleGetDate(8), 35],
            [handleGetDate(7), 35],
            [handleGetDate(6), 36],
            [handleGetDate(5), 36],
            [handleGetDate(4), 37],
            [handleGetDate(3), 37],
            [handleGetDate(2), 38],
            [handleGetDate(1), 38],
            [handleGetDate(0), 39]
        ]
    },
    {
        name: "Overall sales",
        data: [
            [handleGetDate(77), 20],
            [handleGetDate(76), 22],
            [handleGetDate(75), 18],
            [handleGetDate(73), 17],
            [handleGetDate(72), 16],
            [handleGetDate(71), 15],
            [handleGetDate(70), 15],
            [handleGetDate(69), 15],
            [handleGetDate(68), 17],
            [handleGetDate(67), 18],
            [handleGetDate(66), 17],
            [handleGetDate(65), 19],
            [handleGetDate(64), 19],
            [handleGetDate(63), 20],
            [handleGetDate(62), 21],
            [handleGetDate(61), 21],
            [handleGetDate(60), 22],
            [handleGetDate(59), 23],
            [handleGetDate(58), 24],
            [handleGetDate(57), 24],
            [handleGetDate(56), 24],
            [handleGetDate(55), 24],
            [handleGetDate(54), 24],
            [handleGetDate(53), 25],
            [handleGetDate(52), 25],
            [handleGetDate(51), 25],
            [handleGetDate(50), 25],
            [handleGetDate(49), 26],
            [handleGetDate(48), 26],
            [handleGetDate(47), 27],
            [handleGetDate(46), 27],
            [handleGetDate(45), 27],
            [handleGetDate(44), 28],
            [handleGetDate(43), 28],
            [handleGetDate(42), 29],
            [handleGetDate(41), 29],
            [handleGetDate(40), 30],
            [handleGetDate(39), 30],
            [handleGetDate(38), 31],
            [handleGetDate(37), 31],
            [handleGetDate(36), 32],
            [handleGetDate(35), 32],
            [handleGetDate(34), 33],
            [handleGetDate(33), 33],
            [handleGetDate(32), 34],
            [handleGetDate(31), 34],
            [handleGetDate(30), 35],
            [handleGetDate(29), 35],
            [handleGetDate(28), 35],
            [handleGetDate(27), 36],
            [handleGetDate(26), 36],
            [handleGetDate(25), 37],
            [handleGetDate(24), 37],
            [handleGetDate(23), 38],
            [handleGetDate(22), 38],
            [handleGetDate(21), 39],
            [handleGetDate(20), 39],
            [handleGetDate(19), 40],
            [handleGetDate(18), 40],
            [handleGetDate(17), 40],
            [handleGetDate(16), 41],
            [handleGetDate(15), 41],
            [handleGetDate(14), 42],
            [handleGetDate(13), 42],
            [handleGetDate(12), 43],
            [handleGetDate(11), 43],
            [handleGetDate(10), 44],
            [handleGetDate(9), 44],
            [handleGetDate(8), 45],
            [handleGetDate(7), 45],
            [handleGetDate(6), 46],
            [handleGetDate(5), 46],
            [handleGetDate(4), 47],
            [handleGetDate(3), 47],
            [handleGetDate(2), 48],
            [handleGetDate(1), 48],
            [handleGetDate(0), 49]
        ]
    }
];

export const visitorChartSerie = [
    {
        name: "botag sales",
        data: [
            {
                x: `2018-1-${13 + 1}`,
                y: 5
            },
            {
                x: `2018-2-${5 + 1}`,
                y: 10
            },
            {
                x: `2018-3-${7 + 1}`,
                y: 11
            },
            {
                x: `2018-4-${23 + 1}`,
                y: 6
            },
            {
                x: `2018-5-${7 + 1}`,
                y: 8
            },
            {
                x: `2018-6-${30 + 1}`,
                y: 7
            },
            {
                x: `2018-7-${4 + 1}`,
                y: 4
            },
            {
                x: `2018-8-${17 + 1}`,
                y: 6
            },
            {
                x: `2018-9-${13 + 1}`,
                y: 8
            }
        ]
    },
    {
        name: "overAll sales",
        data: [
            {
                x: `2018-1-${13 + 1}`,
                y: 7
            },
            {
                x: `2018-2-${5 + 1}`,
                y: 11
            },
            {
                x: `2018-3-${7 + 1}`,
                y: 13
            },
            {
                x: `2018-4-${23 + 1}`,
                y: 9
            },
            {
                x: `2018-5-${7 + 1}`,
                y: 10
            },
            {
                x: `2018-6-${30 + 1}`,
                y: 13
            },
            {
                x: `2018-7-${4 + 1}`,
                y: 7
            },
            {
                x: `2018-8-${17 + 1}`,
                y: 6
            },
            {
                x: `2018-9-${13 + 1}`,
                y: 8
            }
        ]
    }
];

export const GrossMarginChartSeriesA = [
    {
        data: [1912, 11693, 7411, 6734, 962, 1200, 13777, 32, 58, 48, 70, 900]
    }
];
export const GrossMarginChartSeriesB = [
    {
        data: [190812, 1133, 70311, 684, 962, 1100, 1557, 120, 58, 101, 80, 90]
    }
];
export const GrossMarginChartSeriesC = [
    {
        data: [10812, 11393, 7311, 6834, 9612, 11200, 13557, 12, 78, 98, 90, 100]
    }
];
export const totalSalesChartSeriesA = [
    {
        data: [10812, 11393, 7311, 6834, 9612, 11200, 13557]
    }
];
export const totalSalesChartSeriesB = [
    {
        data: [10802, 14393, 7211, 6534, 9312, 1120, 1357]
    }
];
export const totalSalesChartSeriesC = [
    {
        data: [10212, 15393, 7341, 1834, 8612, 11290, 137]
    }
];
export const visitorChartOptions = {
    colors: ["#00acac", "#348fe2"],
    fill: { opacity: 0.75, type: "solid" },
    legend: {
        // position: "top",
        // horizontalAlign: "right",
        // offsetY: 15,
        // offsetX: 500,
        // labels: { colors: "#ffffff" }
        show: false
    },
    xaxis: {
        type: "datetime",
        tickAmount: 6,
        labels: { style: { colors: "#ffffff" } }
    },
    yaxis: { labels: { style: { colors: "#ffffff" } } },
    tooltip: {
        enabled: false
    },
    chart: { height: "100%", type: "area", toolbar: { show: false }, stacked: true },
    plotOptions: { bar: { horizontal: false, columnWidth: "55%", endingShape: "rounded" } },
    dataLabels: { enabled: false },
    grid: {
        show: true,
        borderColor: "rgba(255,255,255, .15)",
        xaxis: { lines: { show: true } },
        yaxis: { lines: { show: true } },
        padding: { top: -40, right: 20, bottom: 0, left: 10 }
    },
    stroke: { show: false, curve: "smooth" } //straight
};

// _________________

function convertNumberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const storeSessionChartOptions = {
    chart: {
        type: "line",
        width: 160,
        height: 28,
        sparkline: { enabled: true },
        stacked: false
    },
    stroke: { curve: "smooth", width: 3 },
    fill: {
        type: "gradient",
        gradient: {
            opacityFrom: 1,
            opacityTo: 1,
            colorStops: [
                { offset: 0, color: "#00ACAC", opacity: 1 },
                { offset: 50, color: "#348FE2", opacity: 1 },
                { offset: 100, color: "#49B6D6", opacity: 1 }
            ]
        }
    },
    // labels: ["Jun 23", "Jun 24", "Jun 25", "Jun 26", "Jun 27", "Jun 28", "Jun 29"],
    xaxis: { crosshairs: { width: 1 } },
    tooltip: {
        theme: "dark",
        fixed: { enabled: false },
        x: { show: false },
        y: {
            title: {
                formatter: function (seriesName) {
                    return "";
                }
            },
            formatter: value => {
                return convertNumberWithCommas(value);
            }
        },
        marker: { show: false }
    },
    responsive: [
        { breakpoint: 3000, options: { chart: { width: 120 } } },
        { breakpoint: 1300, options: { chart: { width: 100 } } },
        { breakpoint: 1200, options: { chart: { width: 160 } } },
        { breakpoint: 900, options: { chart: { width: 120 } } },
        { breakpoint: 576, options: { chart: { width: 180 } } },
        { breakpoint: 400, options: { chart: { width: 120 } } }
    ]
};
export const storeSessionChartSeries = [
    {
        data: [10812, 11393, 7311, 6834, 9612, 11200, 13557]
    }
];

// ____________________

// const convertNumberWithCommas = x => {
//     return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// };

export const GrossMarginChartOptions = {
    chart: {
        type: "line",
        width: 160,
        height: 28,
        sparkline: { enabled: true },
        stacked: false
    },
    stroke: { curve: "smooth", width: 3 },
    fill: {
        type: "gradient",
        gradient: {
            opacityFrom: 1,
            opacityTo: 1,
            colorStops: [
                { offset: 0, color: "#00ACAC", opacity: 1 },
                { offset: 50, color: "#348FE2", opacity: 1 },
                { offset: 100, color: "#49B6D6", opacity: 1 }
            ]
        }
    },
    labels: ["Jun 23", "Jun 24", "Jun 25", "Jun 26", "Jun 27", "Jun 28", "Jun 29"],
    xaxis: { crosshairs: { width: 1 } },
    tooltip: {
        theme: "dark",
        fixed: { enabled: false },
        x: { show: false },
        y: {
            title: {
                formatter: function (seriesName) {
                    return "";
                }
            },
            formatter: value => {
                return convertNumberWithCommas(value);
            }
        },
        marker: { show: false }
    },
    responsive: [
        { breakpoint: 3000, options: { chart: { width: 120 } } },
        { breakpoint: 1300, options: { chart: { width: 100 } } },
        { breakpoint: 1200, options: { chart: { width: 160 } } },
        { breakpoint: 900, options: { chart: { width: 120 } } },
        { breakpoint: 576, options: { chart: { width: 180 } } },
        { breakpoint: 400, options: { chart: { width: 120 } } }
    ]
};
export const GrossMarginChartSeries = [
    {
        data: [10812, 11393, 7311, 6834, 9612, 11200, 13557, 12, 78, 98, 90, 100]
    }
];

// _____________________

export const conversionRateChartOptions = {
    chart: {
        type: "line",
        width: 160,
        height: 28,
        sparkline: { enabled: true }
    },
    stroke: { curve: "smooth", width: 3 },
    fill: {
        type: "gradient",
        gradient: {
            opacityFrom: 1,
            opacityTo: 1,
            colorStops: [
                { offset: 0, color: "red", opacity: 1 },
                { offset: 50, color: "orange", opacity: 1 },
                { offset: 100, color: "lime", opacity: 1 }
            ]
        }
    },
    labels: ["Jun 23", "Jun 24", "Jun 25", "Jun 26", "Jun 27", "Jun 28", "Jun 29"],
    xaxis: { crosshairs: { width: 1 } },
    tooltip: {
        theme: "dark",
        fixed: { enabled: false },
        x: { show: false },
        y: {
            title: {
                formatter: function (seriesName) {
                    return "";
                }
            },
            formatter: value => {
                return value + "%";
            }
        },
        marker: { show: false }
    },
    responsive: [
        { breakpoint: 3000, options: { chart: { width: 120 } } },
        { breakpoint: 1300, options: { chart: { width: 100 } } },
        { breakpoint: 1200, options: { chart: { width: 160 } } },
        { breakpoint: 900, options: { chart: { width: 120 } } },
        { breakpoint: 576, options: { chart: { width: 180 } } },
        { breakpoint: 400, options: { chart: { width: 120 } } }
    ]
};
export const conversionRateChartSeries = [
    {
        data: [2.68, 2.93, 2.04, 1.61, 1.88, 1.62, 2.8]
    }
];

// _______________

export const totalSalesChartOptions = {
    chart: {
        type: "line",
        width: 160,
        height: 28,
        sparkline: { enabled: true }
    },
    stroke: { curve: "smooth", width: 3 },
    fill: {
        type: "gradient",
        gradient: {
            opacityFrom: 1,
            opacityTo: 1,
            colorStops: [
                { offset: 0, color: "lime", opacity: 0 },
                { offset: 50, color: "lime", opacity: 0.5 },
                { offset: 100, color: "lime", opacity: 1 }
            ]
        }
    },
    labels: ["Jun 23", "Jun 24", "Jun 25", "Jun 26", "Jun 27", "Jun 28", "Jun 29"],
    xaxis: { crosshairs: { width: 1 } },
    tooltip: {
        theme: "dark",
        fixed: { enabled: false },
        x: { show: false },
        y: {
            title: {
                formatter: function (seriesName) {
                    return "";
                }
            },
            formatter: value => {
                return value + "dollars";
            }
        },
        marker: { show: false }
    },
    responsive: [
        { breakpoint: 3000, options: { chart: { width: 120 } } },
        { breakpoint: 1300, options: { chart: { width: 100 } } },
        { breakpoint: 1200, options: { chart: { width: 160 } } },
        { breakpoint: 900, options: { chart: { width: 120 } } },
        { breakpoint: 576, options: { chart: { width: 180 } } },
        { breakpoint: 400, options: { chart: { width: 120 } } }
    ]
};
export const totalSalesChartSeries = [
    {
        data: [10812, 11393, 7311, 6834, 9612, 11200, 13557]
    }
];
// _______________

export const onRangeChange = (dates, dateStrings) => {
    if (dates) {
        console.log("From: ", dates[0], ", to: ", dates[1]);
        console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
    } else {
        console.log("Clear");
    }
};
export const rangePresets = [
    {
        label: "Last 7 Days",
        value: [dayjs().add(-7, "d"), dayjs()]
    },
    {
        label: "Last 14 Days",
        value: [dayjs().add(-14, "d"), dayjs()]
    },
    {
        label: "Last 30 Days",
        value: [dayjs().add(-30, "d"), dayjs()]
    },
    {
        label: "Last 90 Days",
        value: [dayjs().add(-90, "d"), dayjs()]
    }
];

// _______________

// const handleSuccess = (responseData, setSelectedLabelBrand) => {
//     if (responseData.status === 400) {
//         toast.error(
//             <>
//                 <div style={{ fontSize: "12px", fontWeight: 700, marginBottom: "8px" }}>Please Select a Catogory</div>
//             </>,
//             {
//                 theme: "dark",
//                 icon: ({ theme, type }) => <img src={iconError} alt="Success" />,
//                 autoClose: 4000
//             }
//         );
//     } else {
//         toast.success(
//             <>
//                 <div style={{ fontSize: "12px", fontWeight: 700, marginBottom: "8px" }}>Product Updated</div>
//                 <div style={{ fontSize: "10px", fontWeight: "400" }}>New category assigned to product.</div>
//             </>,
//             {
//                 theme: "dark",
//                 icon: ({ theme, type }) => <img src={errorSuccess} alt="Success" />,
//                 autoClose: 4000
//             }
//         );
//         setSelectedLabelBrand("");
//     }
// };
