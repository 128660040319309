import React, { useEffect } from "react";
import AppContext from "../../../AppContext";
import ProductCardSidePanelUI from "./ProductCardSidePanelUI";
import BOImageCardGridSkeleton from "../gridCards/animations/BOImageCardGridSkeleton";
import { v4 as uuidv4 } from "uuid";

/**
 * Renders a side panel for a BOTag card that is already linked to a product.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.BOTagNumber - The BO tag number.
 * @returns {JSX.Element} The rendered side panel component.
 */
export default function SidePanelBOTagCard(props) {
    /* #region USE STATES */
    const [BOTag, setBOTag] = React.useState(undefined);
    const { BOTagsList, setBOTagsList, sidePanelProducts } = React.useContext(AppContext);
    const [productInfo, setProductInfo] = React.useState(undefined);
    /* #endregion */

    /* #region USE EFFECTS */
    useEffect(() => {
        if (!BOTagsList || !props?.BOTagNumber) return;
        const tmpBOTag = BOTagsList.find(tag => tag.number === props?.BOTagNumber);
        setBOTag(tmpBOTag);
    }, [props?.BOTagNumber, BOTagsList]);

    /*useEffect(() => {
        if (productInfo !== undefined || !BOTag || !sidePanelProducts) return;

        const tmpProductInfo = sidePanelProducts.find(product => product.productID === BOTag?.data?.productObjID);
        setProductInfo(tmpProductInfo);
    }, [BOTag, sidePanelProducts]);*/
    /* #endregion */

    /* #region METHODS */
    const handleColorChange = color => {
        const newBOTagsList = BOTagsList.map(tag => {
            if (tag.number === props?.BOTagNumber) {
                tag.data.color = color;
                return tag;
            } else return tag;
        });
        setBOTagsList(newBOTagsList);
    };

    const handleSizeChange = size => {
        const newBOTagsList = BOTagsList.map(tag => {
            if (tag.number === props?.BOTagNumber) {
                tag.data.size = size;
                return tag;
            } else return tag;
        });
        setBOTagsList(newBOTagsList);
    };

    const handleTagUnlink = () => {
        const number = props?.BOTagNumber;
        if (!number) return;

        // Set the tag as not completed
        const newTags = BOTagsList.map(tag => {
            if (tag.number === number) {
                tag.data = { color: "", size: "", productObjID: "" };
                tag.completed = false;
                tag.selected = false;
                return tag;
            } else return tag;
        });

        setBOTagsList(newTags);
    };
    /* #endregion */

    const key = BOTag?.data?.productObjID + BOTag?.number;
    return (
        <div key={key} className="w-100">
            {/*!productInfo ? (
                <BOImageCardGridSkeleton key={props?.BOTagNumber} />
            ) : ( */}
            <ProductCardSidePanelUI
                image={BOTag?.image}
                category={BOTag?.category}
                number={BOTag?.number}
                productName={BOTag?.productName ?? "Product Info loading..."}
                colors={BOTag?.colors}
                selectedColor={BOTag?.data?.color}
                onColorChange={handleColorChange}
                sizes={BOTag?.sizes}
                selectedSize={BOTag?.data?.size}
                onSizeChange={handleSizeChange}
                buttonText="Unlink"
                onButtonClick={handleTagUnlink}
                buttonEnabled={true}
                isCompletedCard={true}
            />
        </div>
    );
}
