import React, { useState } from "react";
import styled from "styled-components";
import ToggleSwitch from "./ToggleSwitch";
import ChevronDown from "../../../../assets/icons/chevron-down0.svg";
import { ReactComponent as Plus } from "../../../../assets/icons/plus0.svg";
import CheckNotSelected from "../../../../assets/icons/formsCheck.svg";
import CheckSelected from "../../../../assets/icons/formsCheckEnabled.svg";
import CheckInactive from "../../../../assets/icons/formsCheckInactive.svg";
import InputTimePicker from "./InputTimePicker";

const StyledWeekCustomCard = styled.div`
    &.week-custom-card,
    &.week-custom-card * {
        box-sizing: border-box;
    }
    &.week-custom-card {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-radius: 4px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        row-gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        height: 194px;
        position: relative;
        width: 100%;
        flex-basis: 100%;
        flex-shrink: 1;
        min-width: 305px;
    }
    .frame-160 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-158 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-161 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .monday {
        color: #ffffff;
        text-align: left;
        font-family: var(--overline-font-family, "Roboto-Medium", sans-serif);
        font-size: var(--overline-font-size, 14px);
        letter-spacing: var(--overline-letter-spacing, 0.015em);
        font-weight: var(--overline-font-weight, 500);
        text-transform: uppercase;
        position: relative;
        flex: 1;
    }
    .frame-144 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .customize {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--label-xs-uppercase-font-family, "Roboto-Medium", sans-serif);
        font-size: var(--label-xs-uppercase-font-size, 8px);
        letter-spacing: var(--label-xs-uppercase-letter-spacing, 0.0125em);
        font-weight: var(--label-xs-uppercase-font-weight, 500);
        text-transform: uppercase;
        position: relative;
    }
    .line-1 {
        margin-top: -1px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
    }
    .frame-279 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-277 {
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-272 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-273 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-275 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-48 {
        background: var(--buttons-all-platforms-gray-500, #10141b);
        border-radius: 4px;
        padding: 6px 8px 6px 8px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 37px;
        position: relative;
    }
    .div {
        color: var(--text-and-icons-green-500, #00bb00);
        text-align: left;
        font-family: var(--label-small-uppercase-font-family, "Roboto-Medium", sans-serif);
        font-size: var(--label-small-uppercase-font-size, 10px);
        line-height: var(--label-small-uppercase-line-height, 5px);
        letter-spacing: var(--label-small-uppercase-letter-spacing, 0.0125em);
        font-weight: var(--label-small-uppercase-font-weight, 500);
        text-transform: uppercase;
        position: relative;
        flex: 1;
    }
    .chevron-right {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .to {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
    }
    .frame-270 {
        background: var(--buttons-all-platforms-gray-500, #10141b);
        border-radius: 4px 0px 0px 4px;
        padding: 6px 8px 6px 8px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 37px;
        position: relative;
    }
    .chevron-right2 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .frame-268 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 0px 4px 4px 0px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 0px 0px 0px 1px;
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 37px;
        position: relative;
    }
    .plus {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .frame-274 {
        opacity: 0;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    ._9-00-am {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--label-small-uppercase-font-family, "Roboto-Medium", sans-serif);
        font-size: var(--label-small-uppercase-font-size, 10px);
        line-height: var(--label-small-uppercase-line-height, 5px);
        letter-spacing: var(--label-small-uppercase-letter-spacing, 0.0125em);
        font-weight: var(--label-small-uppercase-font-weight, 500);
        text-transform: uppercase;
        position: relative;
        flex: 1;
    }
    .chevron-right3 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    ._7-50-pm {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--label-small-uppercase-font-family, "Roboto-Medium", sans-serif);
        font-size: var(--label-small-uppercase-font-size, 10px);
        line-height: var(--label-small-uppercase-line-height, 5px);
        letter-spacing: var(--label-small-uppercase-letter-spacing, 0.0125em);
        font-weight: var(--label-small-uppercase-font-weight, 500);
        text-transform: uppercase;
        position: relative;
        flex: 1;
    }
    .chevron-right4 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .trash-01 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .frame-313315 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        row-gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .checkbox-label {
        display: flex;
        flex-direction: row;
        gap: 8px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        flex: 1 1 100%;
        position: relative;
    }
    .checkbox {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
    }
    .check {
        opacity: 1;
        width: 16px;
        height: 16px;
        position: relative;
        top: -2px;

        overflow: visible;
    }
    .text {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;

        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .checkbox-label2 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        flex: 1 1 100%;
        position: relative;
    }
    .text2 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: right;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;

        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;

export default function WeekCustomCard({
    title,
    isDummyCard = false,
    calendarData,
    setCalendarData,
    onSpecialClicked,
    specialIsEnabled
}) {
    /* #region VARS */
    const isOpenAllDay = calendarData?.openAllDay;
    const isClosedAllDay = calendarData?.closedAllDay;

    const hourStartLabel =
        isOpenAllDay || isClosedAllDay || !calendarData?.hourStart ? "--:--" : calendarData?.hourStart;
    const hourEndLabel = isOpenAllDay || isClosedAllDay || !calendarData?.hourEnd ? "--:--" : calendarData?.hourEnd;

    const [showHourStartPicker, setShowHourStartPicker] = useState(false);
    const [showHourEndPicker, setShowHourEndPicker] = useState(false);

    //const [specialCheckClicked, setSpecialCheckClicked] = useState(false);
    /* #endregion */

    /* #region METHODS */
    /* const handleSameTimeAllWeekdaysClicked = () => {
        setSpecialCheckClicked(!specialCheckClicked);
    };*/

    const handleToogleEnabled = () => {
        setCalendarData({
            ...calendarData,
            closedAllDay: !isClosedAllDay,
            openAllDay: false,
            hourStart: "",
            hourEnd: ""
        });
    };

    const handleHourChange = (type, value) => {
        if (type === "start") {
            setCalendarData({
                ...calendarData,
                hourStart: value
            });
        } else {
            setCalendarData({
                ...calendarData,
                hourEnd: value
            });
        }
    };
    /* #endregion */

    return (
        <StyledWeekCustomCard className={`week-custom-card ${isDummyCard ? "invisible" : ""}`}>
            <div className="frame-160">
                <div className="frame-158">
                    <div className="frame-161">
                        <div className="monday">{title}</div>
                        <ToggleSwitch isEnabled={!isClosedAllDay} setIsEnabled={handleToogleEnabled} />
                    </div>
                </div>
                <div className="frame-144">
                    <div className="customize">CUSTOMIZE</div>
                    <div className="line-1"></div>
                </div>
                <div className="frame-279">
                    <div className="frame-277">
                        <div className="frame-272">
                            <div className="frame-273">
                                <div className="frame-275">
                                    <div
                                        className="frame-48 cursor-pointer"
                                        style={{ position: "relative" }}
                                        onClick={() => setShowHourStartPicker(!showHourStartPicker)}>
                                        <div className="div">{hourStartLabel}</div>
                                        <img className="chevron-right" src={ChevronDown} alt="" />

                                        <InputTimePicker
                                            show={showHourStartPicker}
                                            setShow={setShowHourStartPicker}
                                            style={{ position: "absolute", top: "45px", right: "0", width: "100%" }}
                                            onTimeChange={value => handleHourChange("start", value)}
                                        />
                                    </div>
                                    <div className="to">to</div>
                                    <div
                                        className="frame-270 cursor-pointer"
                                        style={{ position: "relative" }}
                                        onClick={() => setShowHourEndPicker(!showHourEndPicker)}>
                                        <div className="div">{hourEndLabel}</div>
                                        <img className="chevron-right2" src={ChevronDown} alt="" />

                                        <InputTimePicker
                                            show={showHourEndPicker}
                                            setShow={setShowHourEndPicker}
                                            style={{ position: "absolute", top: "45px", right: "0", width: "100%" }}
                                            onTimeChange={value => handleHourChange("end", value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="frame-268 hover-not-allowed">
                                <Plus className="plus text-inactive" />
                            </div>
                        </div>
                        <div className="frame-274" style={{ height: "37px" }}></div>
                    </div>
                </div>
            </div>
            <div className="frame-313315">
                <div className="checkbox-label hover-not-allowed">
                    <div className="checkbox">
                        <img className="check" src={CheckInactive} alt="" />
                    </div>
                    <div className="text text-inactive">All Day</div>
                </div>
                {title === "Monday" && (
                    <div className="checkbox-label2">
                        <div className="text2">Same time all weekdays</div>
                        <div className="checkbox cursor-pointer" onClick={onSpecialClicked}>
                            <img className="check" src={specialIsEnabled ? CheckSelected : CheckNotSelected} alt="" />
                        </div>
                    </div>
                )}
                {title === "Saturday" && (
                    <div className="checkbox-label2">
                        <div className="text2">Same as weekdays</div>
                        <div className="checkbox cursor-pointer" onClick={onSpecialClicked}>
                            <img className="check" src={specialIsEnabled ? CheckSelected : CheckNotSelected} alt="" />
                        </div>
                    </div>
                )}
            </div>
        </StyledWeekCustomCard>
    );
}
