import DataTable, { createTheme } from "react-data-table-component";

createTheme(
    "solarized",
    {
        text: {
            primary: "#D1D1D1",
            secondary: "#D1D1D1"
        },
        background: {
            default: "rgba(0, 0, 0, 0)"
        },
        context: {
            background: "#cb4b16",
            text: "#D1D1D1"
        },
        divider: {
            default: "#333F50"
        },
        action: {
            button: "rgba(0,0,0,.54)",
            hover: "rgba(0,0,0,.08)",
            disabled: "rgba(0,0,0,.12)"
        }
    },
    "dark"
);

export const TableComponent = ({
    columns,
    data,
    dataLoading,
    selectableRowsComponent,
    defaultSortFieldId = 1,
    defaultSortAsc = true,
    expandableRows = false,
    expandableRowsComponent,
    progressComponent,
    paginationRowsPerPageOptions = [30, 50, 75],
    customStyles,
    conditionalRowStyles,
    title,
    onSort,
    paginationServer = false,
    onChangePage,
    paginationDefaultPage,
    paginationTotalRows,
    progressPending,
    onChangeRowsPerPage,
    paginationPerPage,
    pagination
}) => {
    return (
        <div className="">
            <div className="col-md-12">
                <h2
                    style={{
                        fontSize: "14px",
                        color: "var(--text-icons-gray-100)",
                        fontWeight: "500",
                        paddingLeft: "32px",
                        borderBottom: "1px solid var(--backgrounds-lines-n-200)",
                        paddingBottom: "12px"
                    }}>
                    {title}
                </h2>

                <DataTable
                    columns={columns}
                    // title={title}
                    data={data}
                    theme="solarized"
                    sortIcon={<i className="fa-regular fa-chevron-down" style={{ height: "12px" }}></i>}
                    onSort={onSort}
                    progressPending={dataLoading}
                    selectableRows
                    selectableRowsComponent={selectableRowsComponent}
                    defaultSortFieldId={defaultSortFieldId}
                    defaultSortAsc={defaultSortAsc}
                    responsive={true}
                    expandableRows={expandableRows}
                    expandableRowsComponent={expandableRowsComponent}
                    progressComponent={progressComponent}
                    //actions={actionsMemo}
                    paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                    paginationPerPage={paginationPerPage}
                    pagination={pagination}
                    customStyles={customStyles}
                    conditionalRowStyles={conditionalRowStyles}
                    paginationServer={paginationServer}
                    onChangePage={onChangePage}
                    paginationDefaultPage={paginationDefaultPage}
                    paginationTotalRows={paginationTotalRows}
                    progressPendin={progressPending}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                />
            </div>
        </div>
    );
};
