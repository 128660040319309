import React, { useState } from "react";
import styled from "styled-components";
import InfoCircle from "../../../../assets/icons/info-circle-white.svg";
import ChevronDown from "../../../../assets/icons/chevron-down0.svg";
import CheckNotSelected from "../../../../assets/icons/formsCheck.svg";
import CheckSelected from "../../../../assets/icons/formsCheckEnabled.svg";
import CheckInactive from "../../../../assets/icons/formsCheckInactive.svg";
import ToggleSwitch from "../createStoreComponents/ToggleSwitch";

const StyledDeliveryOptionsAvailable = styled.div`
    .store-delivery-option-card,
    .store-delivery-option-card * {
        box-sizing: border-box;
    }
    .store-delivery-option-card {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-radius: 4px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        row-gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 100%;
        position: relative;
        flex: 1 1 100%;
        min-width: 305px;
    }
    .frame-295 {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        padding: 16px 0px 0px;
        align-self: stretch;
    }
    .frame-160 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-158 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-270 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-161 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-296 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .pick-up {
        color: #ffffff;
        text-align: left;
        font-family: var(--overline-font-family, "Roboto-Medium", sans-serif);
        font-size: var(--overline-font-size, 14px);
        letter-spacing: var(--overline-letter-spacing, 0.015em);
        font-weight: var(--overline-font-weight, 500);
        text-transform: uppercase;
        position: relative;
    }
    .info-circle {
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        position: relative;
        overflow: visible;
    }
    .toggle-switch {
        flex-shrink: 0;
        width: 45px;
        height: 19px;
        position: relative;
    }
    .rectangle-1 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 54px;
        width: 97.83%;
        height: 86.36%;
        position: absolute;
        right: 0%;
        left: 2.17%;
        bottom: 4.55%;
        top: 9.09%;
    }
    .group-1 {
        position: absolute;
        inset: 0;
    }
    .ellipse-1 {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-radius: 50%;
        width: 42.22%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 57.78%;
        bottom: 0%;
        top: 0%;
    }
    .ellipse-2 {
        background: var(--text-and-icons-green-500, #00bb00);
        border-radius: 50%;
        width: 23.03%;
        height: 54.55%;
        position: absolute;
        right: 9.6%;
        left: 67.37%;
        bottom: 22.73%;
        top: 22.73%;
    }
    .frame-313347 {
        display: flex;
        flex-direction: column;
        gap: 12px;

        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .you-are-allowing-pick-up-in-this-store {
        color: var(--text-and-icons-green-500, #00bb00);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .dropdown-selector {
        display: flex;
        flex-direction: column;
        gap: 4px;

        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .input-selector-inverted {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 38px;
        position: relative;
    }
    .frame-49 {
        background: var(--backgrounds-and-lines-n-500, #141922);
        border-radius: 4px 0px 0px 4px;
        padding: 6px 0px 6px 0px;
        display: flex;
        flex-direction: row;
        gap: 8px;

        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 5px;
        height: 37px;
        position: relative;
        overflow: hidden;
    }
    .search {
        display: flex;
        flex-direction: row;
        gap: 12px;

        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        height: 38px;
        position: relative;
    }
    .frame-48 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 0px 4px 4px 0px;
        padding: 6px 12px 6px 12px;
        display: flex;
        flex-direction: row;
        gap: 10px;

        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 37px;
        position: relative;
    }
    .default-selection {
        color: var(--text-and-icons-green-500, #00bb00);
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        flex: 1;
    }
    .chevron-down {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .frame-292 {
        display: flex;
        flex-direction: column;
        gap: 16px;

        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-171 {
        display: flex;
        flex-direction: column;
        gap: 16px;

        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-144 {
        display: flex;
        flex-direction: row;
        gap: 10px;

        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .details {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--label-xs-uppercase-font-family, "Roboto-Medium", sans-serif);
        font-size: var(--label-xs-uppercase-font-size, 8px);
        letter-spacing: var(--label-xs-uppercase-letter-spacing, 0.0125em);
        font-weight: var(--label-xs-uppercase-font-weight, 500);
        text-transform: uppercase;
        position: relative;
    }
    .line-1 {
        margin-top: -1px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
    }
    .frame-170 {
        display: flex;
        flex-direction: column;
        gap: 8px;

        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-272 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-273 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-275 {
        display: flex;
        flex-direction: row;
        gap: 8px;

        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-482 {
        background: var(--buttons-all-platforms-gray-500, #10141b);
        border-radius: 4px;
        padding: 6px 8px 6px 8px;
        display: flex;
        flex-direction: row;
        gap: 8px;

        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 37px;
        position: relative;
    }
    .div {
        color: var(--text-and-icons-green-500, #00bb00);
        text-align: left;
        font-family: var(--label-small-uppercase-font-family, "Roboto-Medium", sans-serif);
        font-size: var(--label-small-uppercase-font-size, 10px);
        line-height: var(--label-small-uppercase-line-height, 5px);
        letter-spacing: var(--label-small-uppercase-letter-spacing, 0.0125em);
        font-weight: var(--label-small-uppercase-font-weight, 500);
        text-transform: uppercase;
        position: relative;
        flex: 1;
    }
    .chevron-right {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .to {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
    }
    .frame-271 {
        background: var(--buttons-all-platforms-gray-500, #10141b);
        border-radius: 4px;
        padding: 6px 8px 6px 8px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 37px;
        position: relative;
    }
    .chevron-right2 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .frame-289 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .checkbox-label {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
    .checkbox {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
    }
    .rectangle-12 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 4px;
        border-style: solid;
        border-color: var(--text-and-icons-green-500, #00bb00);
        border-width: 1px;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
    }
    .check {
        width: 16px;
        height: 16px;
        position: relative;
        top: -2px;
        overflow: visible;
    }
    .text {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;

        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .frame-290 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
    .frame-2712 {
        background: var(--buttons-all-platforms-gray-500, #10141b);
        border-radius: 4px;
        padding: 6px 26px 6px 26px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 73px;
        height: 37px;
        position: relative;
    }
    .div2 {
        color: var(--text-and-icons-green-500, #00bb00);
        text-align: center;
        font-family: var(--label-small-uppercase-font-family, "Roboto-Medium", sans-serif);
        font-size: var(--label-small-uppercase-font-size, 10px);
        line-height: var(--label-small-uppercase-line-height, 5px);
        letter-spacing: var(--label-small-uppercase-letter-spacing, 0.0125em);
        font-weight: var(--label-small-uppercase-font-weight, 500);
        text-transform: uppercase;
        position: relative;
        flex: 1;
    }
    .nok {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
    }
`;

export default function DeliveryOptionsAvailable({
    pickupEnabled,
    setPickupEnabled,
    courierEnabled,
    setCourierEnabled,
    shippingEnabled,
    setShippingEnabled
}) {
    return (
        <StyledDeliveryOptionsAvailable className="ov-hidden-x">
            <div className="frame-295 w-100">
                <DeliveryOptionsCard title="Pick-up" isEnabled={pickupEnabled} setIsEnabled={setPickupEnabled} />
                <DeliveryOptionsCard title="Courier" isEnabled={courierEnabled} setIsEnabled={setCourierEnabled} />
                <DeliveryOptionsCard title="Shipping" isEnabled={shippingEnabled} setIsEnabled={setShippingEnabled} />
                <DeliveryOptionsCard isDummyCard={true} />
                <DeliveryOptionsCard isDummyCard={true} />
            </div>
        </StyledDeliveryOptionsAvailable>
    );
}

const DeliveryOptionsCard = ({ title, isDummyCard = false, isEnabled, setIsEnabled }) => {
    const deliveryTitle = title?.toLowerCase();

    const [deliveryDuringEnabled, setDeliveryDuringEnabled] = useState(false);
    const [deliveryDetailsEnabled, setDeliveryDetailsEnabled] = useState(false);
    const [deliveryFreeFromEnabled, setDeliveryFreeFromEnabled] = useState(false);

    return (
        <div className={`store-delivery-option-card ${isDummyCard ? "invisible" : ""}`}>
            <div className="frame-160">
                <div className="frame-158">
                    <div className="frame-270">
                        <div className="frame-161">
                            <div className="frame-296">
                                <div className="pick-up">{title}</div>
                                <img className="info-circle" src={InfoCircle} alt="" />
                            </div>
                            <ToggleSwitch isEnabled={isEnabled} setIsEnabled={setIsEnabled} />
                        </div>
                    </div>
                    <div className="frame-313347">
                        <div
                            className={`you-are-allowing-pick-up-in-this-store ${isEnabled ? "" : "text-inactive"}`}
                            style={{ height: "16px" }}>
                            {deliveryTitle && isEnabled && <>You are allowing {deliveryTitle} in this store</>}
                        </div>
                        <div className="dropdown-selector">
                            <div className="input-selector-inverted">
                                <div className="frame-49"></div>
                                <div className="search">
                                    <div className="frame-48">
                                        <div
                                            className={`default-selection ${
                                                deliveryDuringEnabled ? "" : "hover-not-allowed text-inactive"
                                            }`}>
                                            During open hours
                                        </div>
                                        <img className="chevron-down" src={ChevronDown} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="frame-292">
                    <div className="frame-171">
                        <div className="frame-144">
                            <div className="details">DETAILS</div>
                            <div className="line-1"></div>
                        </div>
                        <div className="frame-170">
                            <div className="frame-272">
                                <div className="frame-273">
                                    <div className="frame-275">
                                        <div
                                            className={`frame-482 ${deliveryDuringEnabled ? "" : "hover-not-allowed"}`}>
                                            <div className={`div ${deliveryDuringEnabled ? "" : "text-inactive"}`}>
                                                --:--
                                            </div>
                                            <img className="chevron-right" src={ChevronDown} alt="" />
                                        </div>
                                        <div className="to">to</div>
                                        <div
                                            className={`frame-271 ${deliveryDuringEnabled ? "" : "hover-not-allowed"}`}>
                                            <div className={`div ${deliveryDuringEnabled ? "" : "text-inactive"}`}>
                                                --:--
                                            </div>
                                            <img className="chevron-right2" src={ChevronDown} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="frame-289">
                        <div
                            className={`checkbox-label ${
                                deliveryDetailsEnabled ? "" : "hover-not-allowed text-inactive"
                            }`}>
                            <div className="checkbox">
                                <img
                                    className="check"
                                    src={deliveryDetailsEnabled ? CheckNotSelected : CheckInactive}
                                    alt=""
                                />
                            </div>
                            <div className="text">Free from:</div>
                        </div>
                        <div className="frame-290">
                            <div className={`frame-2712 ${deliveryFreeFromEnabled ? "" : "hover-not-allowed"}`}>
                                <div className={`div2 ${deliveryFreeFromEnabled ? "" : "text-inactive"}`}>--</div>
                            </div>
                            <div className="nok">NOK</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
