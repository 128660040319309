import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InfoCircle from "../../../../assets/icons/info-circle-white.svg";
import Upload from "../../../../assets/icons/upload-010.svg";
import XClose0 from "../../../../assets/icons/x-close0.svg";
import ReactSelect from "react-select";
import { selectDarkInputBackgroundStyle } from "../../../../utils/utilHelpers";
import ImageUploading from "../../../common/forms/ImageUploading";

const StyledStoreIDCard = styled.div`
    &.input-field-card-layout-1,
    &.input-field-card-layout-1 * {
        box-sizing: border-box;
    }
    &.input-field-card-layout-1 {
        background: var(--backgrounds-and-lines-n-400, #1e2631);
        border-radius: 4px;
        padding: 16px 0px 32px 0px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
        width: 100%;
    }
    .frame-191 {
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-194 {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-189 {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .dropdown-selector {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-49 {
        padding: 0px 5px 0px 5px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .info-circle {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .title {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .input-selector-inverted {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 38px;
        position: relative;
    }
    .frame-492 {
        background: var(--backgrounds-and-lines-n-500, #141922);
        border-radius: 4px 0px 0px 4px;
        padding: 6px 0px 6px 0px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 5px;
        height: 38px;
        position: relative;
        overflow: hidden;
    }
    .search {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        height: 38px;
        position: relative;
    }
    .frame-48 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 0px 4px 4px 0px;
        padding: 6px 12px 6px 12px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 38px;
        position: relative;
    }
    .default-selection {
        color: var(--text-and-icons-green-500, #00bb00);
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        flex: 1;
        height: 38px;
    }
    .chevron-down {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .frame-50 {
        padding: 0px 5px 0px 5px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .simple {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-51 {
        padding: 0px 5px 0px 5px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .info-circle2 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .title2 {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: var(--subtitle-3-labels-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-labels-font-size, 10px);
        font-weight: var(--subtitle-3-labels-font-weight, 400);
        position: relative;
    }
    .search2 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        height: 38px;
        position: relative;
    }
    .frame-482 {
        background: var(--buttons-all-platforms-gray-500, #10141b);
        border-radius: 0px 4px 4px 0px;
        padding: 6px 12px 6px 12px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 38px;
        position: relative;
    }
    .placeholder {
        color: var(--text-and-icons-green-500, #00bb00);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .frame-193 {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .upload-card {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: center;
        flex-shrink: 0;
        width: 100%;
        flex: 1;
        position: relative;
    }
    .frame-512 {
        padding: 0px 5px 0px 5px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 12px;
        position: relative;
    }
    .info-circle3 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .main-logo {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .frame-197 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 4px;
        border-style: solid;
        border-color: var(--text-and-icons-orange-500, #ff9900);
        border-width: 0px 0px 0px 5px;
        //padding: 32px 156px 32px 156px;
        display: flex;
        flex-direction: column;
        gap: 9px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 259px;
        position: relative;
    }
    .frame-198 {
        display: flex;
        flex-direction: column;
        gap: 9px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        position: relative;
    }
    .frame-288 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
        width: 100%;
        height: 100%;
    }
    .upload-01 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .frame-199 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .browse-files-from-device {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
    }
    .logo-size-should-be-16-9-in-aspect-ratio-max-file-size-accepted-is-300-kb {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: center;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .info-circle4 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .upload-012 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
`;

const StyledImagePreview = styled.div`
    .upload-card,
    .upload-card * {
        box-sizing: border-box;
    }
    .upload-card {
        display: flex;
        flex-direction: column;
        gap: 4px;
        row-gap: 0px;
        align-items: flex-start;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-51 {
        padding: 0px 5px 0px 5px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .info-circle {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .store-facade {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .frame-197 {
        border-radius: 4px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-500, #141922);
        border-width: 0px 0px 0px 5px;
        display: flex;
        flex-direction: column;
        gap: 9px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 259px;
        position: relative;
    }
    .x-close-container {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: static;
        z-index: 8888;
    }
    .ellipse-5 {
        background: var(--text-and-icons-red-500, #ff3636);
        border-radius: 50%;
        width: 24px;
        height: 24px;
        position: absolute;
        //left: 766px;
        right: 12px;
        top: 12px;
    }
    .x-close {
        width: 16px;
        height: 16px;
        position: absolute;
        //left: 770px;
        right: 16px;
        top: 16px;
        overflow: visible;
        z-index: 8888;
        cursor: pointer;
    }
`;

export default function StoreIDCard({
    formFieldRetailBrandID,
    formFieldStoreName,
    setFormFieldRetailBrandID,
    setFormFieldRetailBrandName,
    setFormFieldStoreName,
    imageStoreFacadeData,
    setImageStoreFacadeData,
    imageStoreInteriorData,
    setImageStoreInteriorData
}) {
    /* #region VARS */
    const [brandOptions, setBrandOptions] = useState([]);
    const [brandNotSelectedShowError, setBrandNotSelectedShowError] = useState(false);

    /* #endregion */

    /* #region METHODS */
    const handleOnImageUpload = (imageType, data) => {
        if (imageType === "storeFacade") {
            setImageStoreFacadeData(data);
        } else if (imageType === "storeInterior") {
            setImageStoreInteriorData(data);
        }
    };
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        const retailBrands = JSON.parse(localStorage.getItem("retailBrands")) ?? [];

        const brandOptions = retailBrands.map(brand => {
            return {
                key: brand._id,
                label: brand.name
            };
        });

        setBrandOptions(brandOptions);

        if (brandOptions?.length === 1) {
            setFormFieldRetailBrandID(brandOptions[0].key);
        }
    }, []);

    /* #endregion */

    return (
        <StyledStoreIDCard className="input-field-card-layout-1">
            <div className="frame-191">
                <div className="frame-194">
                    <div className="frame-189">
                        <div className="dropdown-selector">
                            <div className="frame-49">
                                <img className="info-circle" src={InfoCircle} alt="" />
                                <div className="title">To Brand:</div>
                            </div>
                            <div className="input-selector-inverted w-100">
                                <ReactSelect
                                    className={`default-selection w-100 selects ${!brandOptions ? "disabled" : ""} ${
                                        brandNotSelectedShowError ? "error" : ""
                                    } ${!formFieldRetailBrandID ? "mandatory" : ""}`}
                                    isDisabled={!brandOptions}
                                    options={brandOptions}
                                    value={brandOptions.find(option => option.key === formFieldRetailBrandID)}
                                    onChange={selectedOption => {
                                        setFormFieldRetailBrandID(selectedOption.key);
                                        setFormFieldRetailBrandName(selectedOption.label);
                                        setBrandNotSelectedShowError(false);
                                    }}
                                    styles={selectDarkInputBackgroundStyle}
                                    //placeholder={<div className="italic-placeholder">Select retail brand</div>}
                                    placeholder=""
                                />
                            </div>
                            <div className="frame-50"></div>
                        </div>
                        <div className="simple">
                            <div className="frame-51">
                                <img className="info-circle2" src={InfoCircle} alt="" />
                                <div className="title2">Store Name</div>
                            </div>
                            <div className="input-selector-inverted">
                                <div className="frame-492"></div>
                                <div className="search2">
                                    <div className="frame-482">
                                        <input
                                            type="text"
                                            className="placeholder input-remove-default"
                                            onChange={e => setFormFieldStoreName(e.target.value)}
                                            value={formFieldStoreName}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="frame-50"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="frame-193">
                <div className="frame-194">
                    <div className="frame-189">
                        <div className="upload-card">
                            <div className="frame-512">
                                <img className="info-circle3" src={InfoCircle} alt="" />
                                <div className="main-logo">Store Facade</div>
                            </div>
                            {imageStoreFacadeData ? (
                                <ImagePreview
                                    //imageURL={imageStoreFacadeBase64}
                                    imageBase64={imageStoreFacadeData?.base64}
                                    onCloseClicked={() => setImageStoreFacadeData(null)}
                                />
                            ) : (
                                <div className="frame-197">
                                    <div className="frame-198">
                                        <ImageUploading
                                            onImageUpload={data => handleOnImageUpload("storeFacade", data)}
                                            //uploadToContainer={BLOB_STORE_CONTAINER}
                                        >
                                            <div className="frame-288">
                                                <img className="upload-01" src={Upload} alt="" />
                                                <div className="frame-199">
                                                    <div className="browse-files-from-device">
                                                        Browse files from device
                                                    </div>
                                                    <div className="logo-size-should-be-16-9-in-aspect-ratio-max-file-size-accepted-is-300-kb">
                                                        Logo size should be 16:9 in aspect ratio
                                                        <br />
                                                        Max file size accepted is 300kb
                                                    </div>
                                                </div>
                                            </div>
                                        </ImageUploading>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="upload-card">
                            <div className="frame-512">
                                <img className="info-circle4" src={InfoCircle} alt="" />
                                <div className="main-logo">Store Interior</div>
                            </div>
                            {imageStoreInteriorData ? (
                                <ImagePreview
                                    //imageURL={imageStoreInteriorBase64}
                                    imageBase64={imageStoreInteriorData?.base64}
                                    onCloseClicked={() => setImageStoreInteriorData(null)}
                                />
                            ) : (
                                <div className="frame-197">
                                    <div className="frame-198">
                                        <ImageUploading
                                            onImageUpload={data => handleOnImageUpload("storeInterior", data)}
                                            //</div>uploadToContainer={BLOB_STORE_CONTAINER}
                                        >
                                            <div className="frame-288">
                                                <img className="upload-012" src={Upload} alt="" />
                                                <div className="frame-199">
                                                    <div className="browse-files-from-device">
                                                        Browse files from device
                                                    </div>
                                                    <div className="logo-size-should-be-16-9-in-aspect-ratio-max-file-size-accepted-is-300-kb">
                                                        Logo size should be 16:9 in aspect ratio
                                                        <br />
                                                        Max file size accepted is 300kb
                                                    </div>
                                                </div>
                                            </div>
                                        </ImageUploading>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </StyledStoreIDCard>
    );
}

const ImagePreview = ({ imageURL, onCloseClicked, imageBase64 }) => {
    let imageSource = imageURL;
    if (!imageURL && imageBase64)
        imageSource = imageBase64.startsWith("data:") ? imageBase64 : `data:image;base64,${imageBase64}`;

    return (
        <StyledImagePreview className="upload-card">
            <div
                className="frame-197"
                style={{
                    background: `url(${imageSource}) center / cover no-repeat`
                }}>
                {onCloseClicked && (
                    <div className="x-close-container">
                        <div className="ellipse-5"></div>
                        <img className="x-close" src={XClose0} alt="" onClick={onCloseClicked} />
                    </div>
                )}
            </div>
        </StyledImagePreview>
    );
};
