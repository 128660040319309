import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useNavigationType } from "react-router-dom";
import Swal from "sweetalert2";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import ChevronLeft from "@untitled-ui/icons-react/build/esm/ChevronLeft";
import ChevronRight from "@untitled-ui/icons-react/build/esm/ChevronRight";
import ChevronDown from "@untitled-ui/icons-react/build/esm/ChevronDown";
import { ReactComponent as NotificationBell } from "../../../assets/icons/notification-alert.svg";
import Bell02 from "@untitled-ui/icons-react/build/esm/Bell02";
import Search from "@untitled-ui/icons-react/build/esm/SearchSm";
import Expand06 from "@untitled-ui/icons-react/build/esm/Expand06";
import Power01 from "@untitled-ui/icons-react/build/esm/Power01";
import UserCircle from "@untitled-ui/icons-react/build/esm/UserCircle";
import NotificationsDropdown from "./NotificationsDropdown";
import * as Library from "../../../utils/Library";
import moment from "moment/moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import iconSuccess from "../../../assets/images/icons8-check-64.png";
import DialogBoxComponent from "../dialogs/DialogBoxComponent";
import FullScreenOverlay from "../overlay/FullScreenOverlay";
//toast.configure();

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    /* #region  MEDIA RESPONSIVENESS */
    @media (min-width: 768px) {
        .main-header {
        }
    }

    @media only screen and (max-width: 1200px) {
        .main-header .center-btns {
            display: none;
            flex: 0;
        }

        .main-header .left-btns {
            flex-basis: initial !important;
            min-width: 120px;
        }
    }

    @media only screen and (max-width: 1200px) {
        .content-wrapper,
        .controlbar,
        .main-header.navbar.navbar-expand {
            //margin-left: 0;
        }
    }
    /* #endregion */

    .left-btns,
    .center-btns,
    .right-btns {
        display: flex;
        flex-grow: 1;
        flex-basis: 100%;
        //overflow: hidden;
    }

    /* #region  MAIN-HEADER */
    .main-header.navbar.navbar-expand {
        align-items: center;
        background: var(--backgrounds-lines-n-600);
        border: 0;
        border-radius: 0;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin: 0;
        z-index: 2;
        overflow: hidden;
        height: 70px;
    }

    .main-header .btn.btn-success:hover,
    .left-btns ul li a:hover {
        border-color: var(--App-Accent-Outline);
    }
    /* #endregion */

    /* #region  GROUP SELLECTION AND PREVIOUS NEXT BUTTONS */
    .left-btns {
        padding-left: 24px;
        align-items: center;
        height: 70px;
        justify-content: flex-start;
    }

    .left-btns ul {
        margin: 0;
        padding: 0;
    }

    .left-btns ul li {
        display: inline-block;
        list-style-type: none;
    }

    .left-btns ul li a {
        align-items: center;
        background: var(--Buttons-All-Platforms-Gray-300);
        border: 1px solid transparent;
        border-radius: 4px;
        box-sizing: border-box;
        color: var(--text-icons-gray-100);
        display: flex;
        font-size: 15px;
        height: 37px;
        justify-content: center;
        line-height: 37px;
        padding: 0;
        text-align: center;
        text-decoration: none;
        width: 40px;
    }

    .left-btns ul li a.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
    /* #endregion */

    /* #region  HEADER BAR CENTER BUTTONS */
    .main-header .center-btns {
        height: 70px;
    }
    .center-btns {
        align-items: center;
        height: 62px;
        justify-content: center;
    }

    .main-header .center-btns .btn.btn-success {
        height: 35px;
        min-width: 120px;
        padding: 0;
    }
    .center-btns .btn.btn-success {
        height: 35px;
        line-height: 35px;
        padding-left: 0;
        padding-right: 0;
        width: 120px;
    }

    .main-header .btn.btn-success {
        align-items: center;
        color: var(--text-icons-gray-100);
        display: flex;
        font-family: "Roboto";
        font-size: 12px;
        justify-content: center;
        margin: 0 5px;
        text-transform: uppercase;
    }

    .main-header .btn.btn-success {
        background-color: var(--backgrounds-lines-n-400);
        border-color: var(--backgrounds-lines-n-400);
    }
    /* #endregion */

    /* #region  RIGHT PROFILE NAVBAR  */
    .main-header .navbar-nav {
        align-items: center;
        float: right;
        height: 70px;
        justify-content: flex-end;
        list-style: none;
        padding-right: 24px;
        padding-left: 0px;
    }

    .right-btns .nav-item {
        margin: 0 0 0 8px;
    }

    .right-btns .icon {
        width: 40px;
        height: 37px;
    }

    .main-header .nav-item.dropdown a {
        text-decoration: none;
    }

    .nav-item.dropdown.profile-container a {
        align-items: center;
    }

    .nav-item .nav-link {
        color: var(--text-icons-gray-100);
        display: block;
        font-size: 16px;
    }

    .nav-item.notification-bell {
        flex-shrink: 0;
    }

    .nav-item.notification-bell > a {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .nav-item.dropdown.profile-container a {
        display: flex;
        text-decoration: none;
    }

    .profile-text {
        display: grid;
        margin-right: 10px;
        height: 33px;
    }

    .profile-text > span {
        color: var(--text-icons-gray-100);
        display: block;
        font-family: "Roboto";
        line-height: 18px;
        text-align: right;
        text-transform: capitalize;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
    }

    .profile-text .username {
        font-size: 18px;
        font-weight: 400;
        line-height: 18px;
    }

    .profile-picture-container {
        display: flex;
    }

    .profile-picture-container > svg > path {
        stroke-width: 0.5;
    }

    .profile-picture {
        border-radius: 50%;
        height: 35px;
        width: 35px;
    }

    .profile-container {
        margin: 0px 8px !important;
    }

    .profile-container .dropdown-icon {
        margin-left: 10px;
        width: 16px;
        height: 16px;
        flex-shrink: 0;
    }

    .user-designation {
        font-size: 10px;
        font-weight: 300;
    }

    .nav-link i {
        font-size: 17px;
    }

    .nav-link.btn.btn-success {
        align-items: center;
        color: var(--text-icons-gray-100);
        display: flex;
        font-size: 16px;
        height: 37px;
        justify-content: center;
        margin: 0;
        padding: 6px 12px;
        width: 40px;
    }

    .right-btns .nav-item.logout-btn {
        margin-right: 0;
    }

    .nav-item.logout-btn .nav-link {
        color: var(--text-icons-green-500);
        margin-right: 0;
    }

    /* #region  NOTIFICATIONS DROPDOWN */
    .notifications-dropdown {
        position: relative;
        top: 0%;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.4s ease, visibility 0.4s ease;
        z-index: 99999;

        position: absolute;
        top: 58px;
        right: 178px;
        z-index: 9999999;
    }

    .notifications-dropdown.show {
        opacity: 1;
        visibility: visible;
    }
    /* #endregion */
`;
/* #endregion */

/**
 * Header component for the main application.
 * @component
 * @param {Object} props - The props object.
 * @param {string} props.className - The CSS class name for the component.
 * @returns {JSX.Element} The JSX element representing the header.
 */
function Header(props) {
    useRef();
    const navigate = useNavigate();

    const [showNotificationDropdown, setShowNotificationDropdown] = useState(false);
    const [isLoadingNotifications, setIsLoadingNotifications] = useState(false);
    const [notificationItems, setNotificationItems] = useState([]);
    const [logoutPopUp, setLogoutPopUp] = useState(false);

    const hasSubscriptionPlan = ![null, undefined, "", "undefined", "null"].includes(
        localStorage.getItem("subscriptionPlanID")
    );

    const hasOrganizationName = ![null, undefined, "", "undefined", "null"].includes(
        localStorage.getItem("currentOrganizationName")
    );

    /* #region USE EFFECT */

    useEffect(() => {
        const handleClickOutside = event => {
            if (showNotificationDropdown && event.target.closest(".notification-bell")) {
            } else if (showNotificationDropdown && !event.target.closest(".notifications-dropdown")) {
                setShowNotificationDropdown(false);
                if (hasNewNotifications()) {
                    setAllSentNotificationsAsSeen();
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showNotificationDropdown]);

    /* TODO: DISABLED GET NOTIFICATIONS FOR NOW
    useEffect(() => {
        const interval = setInterval(() => {
            getNewNotifications();
        }, 60000);

        getHeaderNotifications();

        return () => {
            clearInterval(interval);
        };
    }, []);*/

    /* #endregion */

    /* #region METHODS */
    const getHeaderNotifications = (setSentAsSent = false) => {
        const BOstagesAccountID = localStorage.getItem("token");

        Library.makePostRequest("getNotificationsData", { BOstagesAccountID }, false, setIsLoadingNotifications).then(
            res => {
                if (res.data.status === 200) {
                    const data = res.data.data;
                    const result = data?.result ?? [];

                    const notifications = result?.map(item => {
                        let title = "New notification " + item?.entityName ?? "";
                        let titleShort = "New notification " + item?.entityName ?? "";

                        if (item?.type === "LabelBrandAccept") {
                            title = `${item?.entityName ?? "A brand"} accepted!`;
                            titleShort = title;
                        } else if (item?.type === "LabelBrandOrder") {
                            title = `Order #${item?.entityName ?? "An order"} has just arrived`;
                            titleShort = `Order #${item?.entityName ?? "An order"} arrived`;
                        } else if (item?.type === "StoreAccept") {
                            title = `${item?.entityName ?? "A store"} accepted!`;
                            titleShort = title;
                        }
                        return {
                            id: item?._id,
                            title: title,
                            titleShort: titleShort,
                            description: moment(item?.createdAt).format("DD-MM-YY HH:mm"),
                            status: item?.status ?? "unknown",
                            type: item?.type ?? "unknown",
                            data: item
                        };
                    });

                    setNotificationItems(notifications);

                    /* if (setSentAsSent) {
                        const newNotifications = notifications.filter(item => item.status === "New");
                        setAllNewNotificationsAsSent(newNotifications);
                    } */
                }
            }
        );
    };

    const getNewNotifications = () => {
        const BOstagesAccountID = localStorage.getItem("token");

        Library.makePostRequest("getNewNotifications", { BOstagesAccountID }, false, setIsLoadingNotifications).then(
            res => {
                if (res.data.status === 200) {
                    const data = res.data.data;
                    const result = data?.result ?? [];
                    let updateNavigationBar = false;

                    const newNotifications = result?.map(item => {
                        let title = "New notification " + item?.entityName ?? "";
                        let description = "New notification " + item?.entityName ?? "";

                        if (item?.type === "LabelBrandAccept") {
                            title = "A new brand has accepted";
                            description = `${item?.entityName ?? "A brand"} accepted!`;
                            updateNavigationBar = true;
                        } else if (item?.type === "LabelBrandOrder") {
                            title = "A new order has arrived!";
                            description = `Order #${item?.entityName ?? "An order"} has just arrived`;
                        } else if (item?.type === "StoreAccept") {
                            title = "A new store has accepted!";
                            description = `${item?.entityName ?? "A store"} accepted!`;
                            updateNavigationBar = true;
                        }
                        return {
                            id: item?._id,
                            title: title,
                            description: description,
                            status: "New"
                        };
                    });

                    if (updateNavigationBar) {
                        props?.getUserDataFunction(localStorage.getItem("token"));
                    }

                    for (let i = 0; i < newNotifications.length; i++) {
                        const item = newNotifications[i];

                        toast.success(
                            <>
                                <div
                                    style={{
                                        fontSize: "0.75rem",
                                        fontWeight: 400,
                                        marginBottom: "8px",
                                        fontFamily: "Roboto",
                                        fontStyle: "normal"
                                    }}>
                                    {item?.title}
                                </div>
                                <div
                                    style={{
                                        fontSize: "0.625rem",
                                        fontWeight: 300,
                                        marginBottom: "4px",
                                        fontFamily: "Roboto",
                                        fontStyle: "normal"
                                    }}>
                                    {item?.description}
                                </div>
                            </>,
                            {
                                theme: "dark",
                                icon: ({ theme, type }) => <img src={iconSuccess} alt="Success" />,
                                autoClose: 5000
                            }
                        );
                    }

                    if (newNotifications.length > 0) {
                        setAllNewNotificationsAsSent(newNotifications);
                    }
                }
            }
        );
    };

    const setAllNewNotificationsAsSent = newNotifications => {
        const notifications = newNotifications.map(item => ({
            id: item.id,
            status: "Sent"
        }));

        Library.makePostRequest("updateNotificationsStatus", { notifications }).then(res => {
            if (res.data.status === 200) {
                //console.log("All notifications marked as Sent!");
                getHeaderNotifications();
            }
        });
    };

    const setAllSentNotificationsAsSeen = () => {
        const sentNotifications = notificationItems.filter(item => item.status === "Sent");

        const notifications = sentNotifications.map(item => ({
            id: item.id,
            status: "Seen"
        }));

        Library.makePostRequest("updateNotificationsStatus", { notifications }).then(res => {
            if (res.data.status === 200) {
                //console.log("All notifications marked as Seen!");

                const notificationItemsReaded = notificationItems.map(item => {
                    item.status = item.status === "Sent" ? "Seen" : item.status;
                    return item;
                });
                setNotificationItems(notificationItemsReaded);
            }
        });
    };

    const hasNewNotifications = () => {
        return notificationItems.filter(item => item.status === "New" || item.status === "Sent").length > 0;
    };

    const toogleFullscreen = e => {
        e.preventDefault();

        if (appIsFullscreen()) disableFullscreen();
        else enableFullscreen();
    };

    const appIsFullscreen = () => {
        if (
            (document.fullScreenElement !== undefined && document.fullScreenElement === null) ||
            (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) ||
            (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
            (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)
        ) {
            localStorage.setItem("fullscreen", "full");
            return false;
        } else {
            localStorage.setItem("fullscreen", "exit");
            return true;
        }
    };

    const disableFullscreen = () => {
        document.getElementById("expand-button").classList.remove("fa-compress");
        document.getElementById("expand-button").classList.add("fa-expand");

        if (document.exitFullscreen) document.exitFullscreen();
        else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
        else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
        else if (document.msExitFullscreen) document.msExitFullscreen();
    };

    const enableFullscreen = () => {
        const elem = document.body;

        document.getElementById("expand-button").classList.remove("fa-expand");
        document.getElementById("expand-button").classList.add("fa-compress");
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    };

    const logOut = e => {
        e.preventDefault();

        // TODO: USE BOLIBRARY
        Swal.fire({
            text: "Are you sure want to logout?",
            icon: "success",
            showDenyButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#205380",
            denyButtonColor: "#205380",
            confirmButtonText: "Yes",
            denyButtonText: "No",
            background: "#1d2530",
            color: "var(--text-icons-gray-100)",
            iconColor: "#0B0",
            width: "500px",
            padding: "10px"
        }).then(result => {
            if (result.isConfirmed) {
                clearCacheData();
                //props.hideHeader();
                localStorage.clear();
                navigate("/");
            } else if (result.isDenied) {
                Swal.close();
            }
        });
    };

    /*const handleBrandData = (brandName, brandID) => {
        //THIS METHOD HANDLES THE CHANGE OF THE CURRENT ACTIVE GROUP
        if (typeof props.dataReloadfunction != "undefined") {
            props.dataReloadfunction(true);
        }
        localStorage.setItem("brandID", brandID);
        localStorage.setItem("brandName", brandName);
    };*/

    //TODO: CHECK HOW TO CLEAR REDUX VARIABLE
    const clearCacheData = () => {
        caches.keys().then(names => {
            names.forEach(name => {
                caches.delete(name);
            });
        });
    };
    /* #endregion */

    /* #region HANDLE BACK AND FORWARD BUTTONS */
    const [historyStack, setHistoryStack] = useState([]);
    const { pathname } = useLocation();
    const type = useNavigationType();
    //console.log(historyStack);

    useEffect(() => {
        if (type === "POP") {
            setHistoryStack(prev => prev.slice(0, prev.length - 1));
        } else if (type === "PUSH") {
            setHistoryStack(prev => [...prev, pathname]);
        } else {
            setHistoryStack(prev => [...prev.slice(0, prev.length - 1), pathname]);
        }
    }, [pathname, type]);

    // VERIFIES IF THE PREVIOUS PATH IS WHITIN APP AND IS NOT "/": THAT IS THE ROUTE PATH FOR THE LOGIN PAGE
    const canGoBack =
        window.history.state?.idx !== 0 &&
        (historyStack.length === 1 || (historyStack.length > 1 && historyStack[historyStack.length - 2] !== "/"));
    const canGoForward = window.history.state?.idx < window.history.length - 2;

    function goBack() {
        if (canGoBack) window.history.back();
    }

    function goForward() {
        if (canGoForward) window.history.forward();
    }
    /* #endregion */

    let timer = 10 * 60 * 1000;
    let logoutTimer;

    const resetLogoutTimer = () => {
        clearTimeout(logoutTimer);
        timer = 10 * 60 * 1000;
        logoutTimer = setTimeout(logout, timer);
    };

    const logout = () => {
        setLogoutPopUp(false);
        clearCacheData();
        //props.hideHeader();
        localStorage.clear();
        navigate("/");
    };

    document.addEventListener("mousemove", resetLogoutTimer);
    document.addEventListener("keypress", resetLogoutTimer);
    document.addEventListener("touchstart", resetLogoutTimer);

    // Initial timer start
    resetLogoutTimer();

    return (
        <StyledPage className={`${props?.className || ""}`}>
            {logoutPopUp && (
                <div style={{ position: "absolute", zIndex: "9999999", top: "10px", left: "38%" }}>
                    <FullScreenOverlay
                        children={
                            <DialogBoxComponent
                                headingColor="#00AEEF"
                                title="Ready To Logout"
                                message={"Are you sure want to logout?"}
                                onClickYes={() => {
                                    setLogoutPopUp(false);
                                    clearCacheData();
                                    //props.hideHeader();
                                    localStorage.clear();
                                    navigate("/");
                                }}
                                onClickNo={() => {
                                    setLogoutPopUp(false);
                                }}
                            />
                        }
                    />
                </div>
            )}

            <nav className="main-header navbar navbar-expand ">
                <div className="left-btns">
                    <ul>
                        <li style={{ marginRight: "6px" }}>
                            <Link
                                to="#"
                                className={!canGoBack ? "disabled" : "enabled"}
                                onClick={e => (canGoBack ? goBack() : e.preventDefault())}>
                                <ChevronLeft className="text-default" height="16px" />
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="#"
                                className={!canGoForward ? "disabled" : "enabled"}
                                onClick={e => (canGoForward ? goForward() : e.preventDefault())}>
                                <ChevronRight className="text-default" height="16px" />
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="center-btns">
                    {props?.centerBtns?.map(btn => {
                        const key = uuidv4();
                        return (
                            <Link
                                key={key}
                                to={
                                    hasSubscriptionPlan && hasOrganizationName && !btn?.disabled && btn?.to
                                        ? btn.to
                                        : ""
                                }
                                className={
                                    btn?.disabled || !hasSubscriptionPlan || !hasOrganizationName
                                        ? "btn btn-success button-not-active"
                                        : "btn btn-success button-active"
                                }
                                onClick={
                                    btn?.disabled || !hasSubscriptionPlan || !hasOrganizationName
                                        ? e => {
                                              e.preventDefault();
                                          }
                                        : undefined
                                }>
                                {btn?.title}
                            </Link>
                        );
                    })}
                </div>

                <div className="right-btns navbar-nav ml-auto">
                    <div className="nav-item dropdown profile-container">
                        <Link
                            className="nav-link"
                            data-toggle="dropdown"
                            to="#"
                            onClick={e => e.preventDefault()}
                            aria-expanded="true">
                            <div className="profile-text hover-not-allowed">
                                <span className="username">{localStorage.getItem("userName")}</span>
                                <span className="user-designation">{localStorage.getItem("currentProfileRole")}</span>
                            </div>
                            <div
                                className="profile-picture-container hover-not-allowed"
                                onClick={e => e.preventDefault()}>
                                {localStorage.getItem("currentProfilePhoto") ? (
                                    <img
                                        className="profile-picture"
                                        src={localStorage.getItem("currentProfilePhoto")}
                                        alt="Profile"
                                    />
                                ) : (
                                    <UserCircle className="text-inactive" height="35px" width="35px" />
                                )}
                            </div>
                            <div className="dropdown-icon text-gray-300 hover-not-allowed">
                                <ChevronDown height="16px" width="16px" />
                            </div>
                        </Link>
                    </div>
                    <div
                        className="nav-item icon notification-bell"
                        onClick={() => setShowNotificationDropdown(!showNotificationDropdown)}>
                        <Link className="nav-link icon" to="#" onClick={e => e.preventDefault()} aria-expanded="true">
                            {notificationItems.filter(item => item.status === "New" || item.status === "Sent").length >
                            0 ? (
                                <NotificationBell className="text-default" height="20px" />
                            ) : (
                                <Bell02 className="text-gray-100" height="16px" />
                            )}
                        </Link>
                    </div>
                    <div className="nav-item icon">
                        <Link
                            className="nav-link btn btn-success button-not-active"
                            data-widget="search"
                            to="#"
                            onClick={e => e.preventDefault()}
                            role="button">
                            <Search className="text-inactive" height="16px" />
                        </Link>
                    </div>
                    <div className="nav-item icon">
                        <Link
                            id=""
                            className="nav-link btn btn-success"
                            to="#"
                            onClick={toogleFullscreen}
                            role="button">
                            <Expand06 className="text-default" height="16px" />
                        </Link>
                    </div>
                    <div className="nav-item icon logout-btn">
                        <Link
                            className="nav-link btn btn-success"
                            data-widget="logout"
                            to="#"
                            onClick={() => {
                                setLogoutPopUp(true);
                                // logOut
                            }}
                            role="button">
                            <Power01 className="text-green-500" height="16px" />
                        </Link>
                    </div>
                </div>
            </nav>

            <div className={`notifications-dropdown ${showNotificationDropdown ? "show" : ""}`}>
                <NotificationsDropdown
                    className=""
                    NotificationItems={notificationItems}
                    isLoadingNotifications={isLoadingNotifications}
                />
            </div>
        </StyledPage>
    );
}

export default Header;
