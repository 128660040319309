import styled from "styled-components";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    .fullscreen-loader {
        text-align: center;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        background: rgb(16 20 27 / 80%);
        z-index: 9999;
    }
`;
/* #endregion */

/**
 * FullScreenOverlay component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.className - Additional CSS class name for the component.
 * @param {boolean} props.show - The flag to enable or disable the overlay.
 * @param {JSX.Element} props.children - The component children. Should not be used as a prop, but to wrap children components. See example.
 * @returns {JSX.Element} The FullScreenOverlay component.
 * @example <FullScreenLoader show={true} >
 *   <div className="children-container"> (...) </div>
 * </FullScreenLoader>
 */
export default function FullScreenOverlay(props) {
    const showOverlay = props?.isLoading ?? props?.show ?? true;

    return (
        <StyledPage className={`${props?.className || ""}`}>
            {showOverlay && <div className="fullscreen-loader pre-enrollment pre-loader">{props?.children}</div>}
        </StyledPage>
    );
}
