/**
 * A simple form component that renders an input field with optional tags and tooltip.
 *
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the input field.
 * @param {string} props.type - The type of the input field.
 * @param {string} props.placeholder - The placeholder text for the input field.
 * @param {string} props.value - The value of the input field.
 * @param {function} props.textChange - The callback function to handle text changes in the input field.
 * @param {boolean} props.disabled - Whether the input field is disabled or not.
 * @param {boolean} props.required - Whether the input field is required or not.
 * @param {string} props.tooltip - The tooltip text for the input field.
 * @param {string} [props.requiredType="normal"] - The type of required field indicator. Possible values are "normal", "recommended" and "mandatory".
 * @param {ReactNode} [props.leftIcon] - The icon to display on the left side of the input field.
 * @param {string[]} [props.tags] - The array of tags to display below the input field.
 * @param {function} [props.tagsChange] - The callback function to handle tag changes.
 * @param {string} [props.tagAddedColor="#00AEEF"] - The color of the tag added to the input field.
 * @returns {JSX.Element} The rendered FormSimpleComponent2 component.
 *
 */
import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "antd";
import styled from "styled-components";
import TagComponent from "./TagComponent";
import { v4 as uuidv4 } from "uuid";

const StyledElems = styled.div`
    & {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
    }

    .Simple {
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 4px;
        display: inline-flex;
    }

    .Frame51 {
        align-self: stretch;
        padding-left: 5px;
        padding-right: 5px;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        display: inline-flex;
    }

    .Icon {
        width: 10px;
        height: 10px;
        font-size: 9px;
        font-weight: 500;
        color: var(--text-icons-gray-200);
        position: relative;
        top: -1px;
    }

    .Title {
        color: var(--text-icons-gray-200);
        font-size: 0.75rem;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .InputSelectorInverted {
        align-self: stretch;
        justify-content: flex-start;
        align-items: flex-start;
        display: inline-flex;
    }

    .Frame49 {
        width: 5px;
        height: 37px;
        padding-top: 6px;
        padding-bottom: 6px;
        background: var(--text-icons-orange-500);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .Search {
        flex: 1 1 0;
        height: 38px;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        display: flex;
    }

    .Frame48 {
        flex: 1 1 0;
        height: 37px;
        background: #10141b;
        border-radius: 4px;
        background: var(--backgrounds-lines-n-600);
        border: 0;
        border-left: 5px solid var(--backgrounds-lines-n-500);
        outline: none;
        color: var(--text-icons-gray-100);
        gap: 0;
    }

    .Frame48.disabled {
        color: var(--text-icons-gray-300);
        cursor: not-allowed;
    }

    .Frame48.mandatory {
        border-left: 5px solid var(--text-icons-orange-500);
    }

    .Frame48.recommended {
        border-left: 5px solid var(--text-icons-blue-500);
    }

    .Frame48.locked {
        border-left: 5px solid var(--text-icons-gray-300);
    }

    .Frame48LeftIcon {
        display: flex;
        width: 50px;
        height: 37px;
        padding: 6px 0px;
        justify-content: center;
        align-items: center;
        gap: 8px;

        border-right: 1px solid var(--backgrounds-lines-n-100);
        flex-shrink: 0;
    }

    .Frame48Input {
        flex: 1 1 0;
        width: 100%;
        height: 100%;
        padding-left: 12px;
        padding-right: 12px;
        background: var(--backgrounds-lines-n-600);
        border: 0;
        outline: none;
        color: var(--text-icons-gray-100);

        /* Subtitle 2 */
        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .Frame48Input::placeholder {
        color: var(--text-icons-gray-200);
        font-style: italic;

        color: var(--text-icons-gray-200, #7c868b);
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
    }

    .Frame48TagsContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin: 6px 0px 6px 8px;
    }

    .Frame48.disabled .Frame48Input {
        color: var(--text-icons-gray-300);
        cursor: not-allowed;
    }

    .Frame50 {
        align-self: stretch;
        padding-left: 5px;
        padding-right: 5px;
    }

    input.datepicker {
        display: inline-block;
        position: relative;
        color-scheme: dark;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
        color-scheme: dark;
    }

    div.no-date {
        background: none;
    }

    div.no-date .placeholder {
        display: block !important;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--backgrounds-lines-n-600);
        height: 37px;
        width: 100%;
        line-height: 37px;
        padding-left: 12px;
        padding-right: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    div.has-date .placeholder {
        display: none;
    }
`;

const FormSimpleComponent2 = props => {
    const {
        title,
        type,
        placeholder,
        value,
        textChange,
        disabled,
        required,
        tooltip,
        requiredType = "normal",
        leftIcon,
        tags,
        tagsChange,
        tagAddedColor = "#00AEEF",
        textColor = "var(--text-icons-green-500)",
        tagsTextColor = "var(--text-icons-green-500)",
        className = "",
        minDate = "",
        maxDate = ""
    } = props;

    /* #region STATES */

    const inputRef = useRef();
    const [currentValue, setCurrentValue] = useState(value);
    const [inputID, setInputID] = useState(uuidv4());
    /* #endregion */

    /* #region METHODS */
    const onChangeMethod = e => {
        setCurrentValue(e.target.value);
    };
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    /* useEffect(() => {
        if (type === "tags" && tagsChange) {
            tagsChange(tags);
        }
    }, [tags]);*/
    /* #endregion */

    return (
        <StyledElems className={className}>
            <div className="Simple">
                <div className="Frame51">
                    {tooltip && (
                        <Tooltip title={tooltip} color="#10141b" overlayStyle={{ fontSize: "0.75rem" }}>
                            <i className="Icon fas fa-info-circle" aria-hidden="true"></i>
                        </Tooltip>
                    )}
                    {!tooltip && <i className="Icon fas fa-info-circle hidden" aria-hidden="true"></i>}
                    <div className="Title">{title}</div>
                </div>
                <div className={"InputSelectorInverted"}>
                    <div
                        className={`Search Frame48 ${
                            (requiredType !== "normal" && props.type === "tags" && tags?.length === 0) ||
                            (props.type !== "tags" && !currentValue)
                                ? requiredType
                                : "normal"
                        } ${disabled ? "disabled" : ""}`}
                        style={{ height: "unset", minHeight: "37px" }}>
                        {leftIcon !== undefined && (
                            <div
                                className="Frame48LeftIcon"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                    type === "date" || type === "datetime-local" ? inputRef.current.showPicker() : null
                                }>
                                {leftIcon}
                            </div>
                        )}
                        {tags && tags.length > 0 && (
                            <div className="Frame48TagsContainer">
                                {tags.map((tag, index) => (
                                    <TagComponent
                                        key={uuidv4()}
                                        index={index}
                                        bgColor={tag?.bgColor ?? "#00AEEF"}
                                        tagName={tag?.tagName ?? "--"}
                                        hideRemoveButton={tag?.hideRemoveButton}
                                        onRemoveClick={() => {
                                            const newTags = tags.filter((item, idx) => idx !== index);
                                            tagsChange(newTags);
                                        }}
                                    />
                                ))}
                            </div>
                        )}
                        <div
                            className={`${type === "date" && !currentValue ? "no-date" : "has-date"}`}
                            style={{ position: "relative", width: "100%" }}>
                            <input
                                id={`myDateInput-${inputID}`}
                                ref={inputRef}
                                type={type}
                                min={minDate}
                                max={maxDate}
                                placeholder={disabled ? "" : placeholder}
                                onBlur={e => textChange && textChange(e.target.value)}
                                disabled={disabled}
                                required={required}
                                value={currentValue}
                                onChange={onChangeMethod}
                                onKeyUp={e => {
                                    if (type === "tags" && tagsChange && e.key === "Enter") {
                                        const newTag = { bgColor: tagAddedColor, tagName: e.target.value };
                                        const newTags = [...tags, newTag];
                                        tagsChange(newTags);
                                        e.target.value = "";
                                        //if (textChange) textChange("");
                                    }
                                }}
                                className={`Frame48Input ${
                                    type === "date" || type === "datetime-local"
                                        ? !currentValue
                                            ? "datepicker no-date"
                                            : "datepicker has-date"
                                        : ""
                                }`}
                                style={{
                                    minWidth: "100px",
                                    height: "37px",
                                    color: type === "tags" ? tagsTextColor : textColor
                                }}
                            />
                            {(type === "date" || type === "datetime-local") && props?.datePickerPlaceholder && (
                                <label
                                    htmlFor={`myDateInput-${inputID}`}
                                    className="placeholder"
                                    style={{ display: "none", color: textColor, cursor: "pointer" }}
                                    onClick={() => inputRef.current.showPicker()}>
                                    {props?.datePickerPlaceholder}
                                </label>
                            )}
                        </div>
                    </div>
                </div>
                <div className="Frame50"></div>
            </div>
        </StyledElems>
    );
};

export default FormSimpleComponent2;
