import noImage from "../../../assets/images/common/no-image-products-placeholder.png";
import React from "react";
import styled from "styled-components";
import BtnLargeSolidInverted02 from "../../common/buttons/BtnLargeSolidInverted02";
import { Link } from "react-router-dom";
import Tooltips from "../../common/tooltip/Tooltips";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    .UiCardProduct {
        position: relative;
        display: flex;
        /* width: 311px; */
        padding: 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        flex-shrink: 0;

        border-radius: 4px;
        background: var(--backgrounds-lines-n-400, #1e2631);
    }

    .Image5 {
        background: var(--backgrounds-lines-n-500, #141922);
        border-radius: 4px;
        height: 465px;
        align-self: stretch;
    }

    .Frame264 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;

        //position: relative;
    }

    .Frame158 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 6px;
        align-self: stretch;
    }

    .Frame161 {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;

        width: 100%;
        overflow: hidden;
    }

    .StyleNameLeft {
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Label/Small - Uppercase */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 14px; /* 140% */
        letter-spacing: 0.125px;
        text-transform: uppercase;

        flex-shrink: 0;
    }

    .StyleNameRight {
        color: var(--text-icons-blue-500, #00aeef);

        /* Label/Small - Uppercase */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0.125px;
        text-transform: uppercase;

        //text in on line
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .Frame163 {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
    }

    .Pencil01 {
        width: 16px;
        height: 16px;
    }

    .BotagId {
        display: flex;
        align-self: stretch;
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Label/Small - Uppercase */
        font-family: Roboto;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 14px; /* 140% */
        letter-spacing: 0.125px;
        text-transform: uppercase;
    }

    .BotagId .StyleNameLeft,
    .BotagId .StyleNameRight {
        font-size: 0.625rem;
    }

    .Frame171 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
    }

    .Frame144 {
        display: flex;
        align-items: center;
        gap: 10px;
        align-self: stretch;
    }

    .Details {
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Label/XS - Uppercase */
        font-family: Roboto;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.1px;
        text-transform: uppercase;
    }

    .Line1 {
        flex-grow: 1;

        height: 1px;
        background: var(--backgrounds-lines-n-100);
    }

    .Frame169 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
    }

    .FrameDetailLines {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        height: 12px;
    }

    .FrameDetailLines.Boxes {
        height: 16px;
    }

    .Brand {
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Subtitle 3 */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .TheProduct {
        flex: 1 0 0;

        color: var(--text-icons-blue-500, #00aeef);
        text-align: right;

        /* Subtitle 3 */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        //text in on line
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .Frame172 {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }

    .Reference {
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Subtitle 3 */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .ReferenceValue {
        flex: 1 0 0;

        color: var(--text-icons-blue-500, #00aeef);
        text-align: right;

        /* Subtitle 3 */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        //text in on line
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .Frame166 {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }

    .Colors {
        flex: 1 0 0;

        color: var(--text-icons-gray-100, #d1d1d1);

        /* Subtitle 3 */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .Frame264-2 {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    .RectangleBoxes {
        width: 16px;
        height: 16px;
        border-radius: 2px;
    }

    .Frame383 {
        display: flex;
        width: 16px;
        height: 16px;
        padding: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        border-radius: 2px;
        background: var(--backgrounds-lines-n-500, #141922);
    }

    .Counter5 {
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Label/XS-Lowercase */
        font-family: Roboto;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .Frame167 {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }

    .Sizes {
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Subtitle 3 */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .Frame385 {
        flex: 1 0 0;
        justify-content: flex-end;
        align-items: center;
        gap: 4px;
        display: flex;
    }

    .size {
        color: var(--text-icons-blue-500, #00aeef);

        /* Label/XS-Lowercase */
        font-family: Roboto;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .SizeBoxes {
        border-radius: 2px;
        background: var(--backgrounds-lines-n-500, #141922);

        display: flex;
        height: 16px;
        padding: 4px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .Counter3 {
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Label/XS-Lowercase */
        font-family: Roboto;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .Frame168 {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }

    .Category {
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Subtitle 3 */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .Tops {
        flex: 1 0 0;
        text-align: right;
        color: var(--text-icons-blue-500, #00aeef);

        /* Subtitle 3 */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        //text in on line
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .Frame164 {
        display: flex;
        align-items: center;
        gap: 4px;
        align-self: stretch;
    }

    .Frame263 {
        display: flex;
        padding: 8px;
        align-items: flex-start;
        gap: 10px;

        position: absolute;
        right: 24px;
        top: 24px;

        border-radius: 500px;
        background: var(--backgrounds-lines-n-600, #10141b);

        /* drop-shadow-100 */
        box-shadow: 0px 0px 10px 0px #111620;
    }

    .Tag03 {
        width: 16px;
        height: 16px;
    }
`;
/* #endregion */

/**
 * Renders a product card component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.className - The class name for the component.
 * @param {string} props.image - The image URL for the product.
 * @param {string} props.title - The title of the product.
 * @param {Object} props.values - The values object containing product details.
 * @param {Object} props.values.key - The key of the value.
 * @param {Object} props.values.value - The value of the key.
 * @param {string} props.id - The product ID.
 * @param {Function} props.tagOnclick - The tag click event.
 * @param {Object} props.editIcon - The edit icon object.
 * @param {Function} props.editIcon.onClick - The edit icon click event.
 * @param {Object} props.primaryButton - The primary button object.
 * @param {string} props.primaryButton.title - The primary button title.
 * @param {Function} props.primaryButton.onClick - The primary button click event.
 * @param {Object} props.secondaryButton - The secondary button object.
 * @param {string} props.secondaryButton.title - The secondary button title.
 * @param {Function} props.secondaryButton.onClick - The secondary button click event.
 *
 * @returns {JSX.Element} The rendered product card component.
 */
export default function ProductCardComponent(props) {
    const brand = getValues(props?.values, "Brand") || "--";
    const reference = getValues(props?.values, "Reference") || "--";
    const category = getValues(props?.values, "Category") || "--";
    const colors = getValues(props?.values, "Colors");
    const sizes = getValues(props?.values, "Sizes");

    return (
        <StyledPage className={`${props?.className || ""}`}>
            <div className="UiCardProduct">
                <img
                    className="Image5"
                    src={props?.image || noImage}
                    alt={props?.title || "Grid main image"}
                    style={{ objectFit: props?.image ? "cover" : "contain" }}
                />
                <div className="Frame264">
                    <div className="Frame158">
                        <div className="Frame161">
                            <div style={{ display: "inline-flex", flexShrink: 1, overflow: "hidden" }}>
                                <span
                                    className={`StyleNameLeft ${
                                        props?.isCardEnabled ? "text-gray-100" : "text-gray-300"
                                    }`}>
                                    STYLE NAME:&nbsp;
                                </span>
                                <span
                                    className={`StyleNameRight ${
                                        props?.isCardEnabled ? "text-blue-500" : "text-gray-300"
                                    }`}>
                                    {props?.title || "--"}
                                </span>
                            </div>
                            <div className="Frame163" style={{ flexShrink: 0 }}>
                                <Tooltips
                                    title="Edit"
                                    onClick={props?.editIcon?.onClick}
                                    children={
                                        <div
                                            className={`Pencil01 ${
                                                props?.editIcon?.onClick ? "" : "hover-not-allowed"
                                            }`}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none">
                                                <path
                                                    d="M1.66663 14.3334L5.36614 12.9105C5.60277 12.8195 5.72108 12.774 5.83177 12.7146C5.93009 12.6618 6.02383 12.6009 6.11199 12.5324C6.21124 12.4554 6.30088 12.3658 6.48015 12.1865L14 4.66671C14.7364 3.93033 14.7364 2.73642 14 2.00004C13.2636 1.26366 12.0697 1.26366 11.3333 2.00004L3.81348 9.51985C3.63421 9.69912 3.54457 9.78876 3.46755 9.88801C3.39914 9.97617 3.33823 10.0699 3.28545 10.1682C3.22603 10.2789 3.18053 10.3972 3.08951 10.6339L1.66663 14.3334ZM1.66663 14.3334L3.03871 10.766C3.13689 10.5107 3.18598 10.3831 3.27019 10.3246C3.34377 10.2735 3.43483 10.2542 3.52282 10.271C3.62351 10.2902 3.72021 10.3869 3.91361 10.5803L5.41967 12.0864C5.61307 12.2798 5.70977 12.3765 5.729 12.4772C5.74581 12.5652 5.72648 12.6562 5.67539 12.7298C5.61692 12.814 5.48928 12.8631 5.23401 12.9613L1.66663 14.3334Z"
                                                    stroke={
                                                        props?.editIcon?.onClick
                                                            ? "var(--text-icons-green-500)"
                                                            : "var(--text-icons-gray-300)"
                                                    }
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                        <div className="BotagId">
                            <span
                                className={`StyleNameLeft ${props?.isCardEnabled ? "text-gray-100" : "text-gray-300"}`}>
                                BOTAG ID:&nbsp;
                            </span>
                            <span
                                className={`StyleNameRight ${
                                    props?.isCardEnabled ? "text-blue-500" : "text-gray-300"
                                }`}>
                                {props?.id || "--"}
                            </span>
                        </div>
                    </div>
                    <div className="Frame171">
                        <div className="Frame144">
                            <div
                                className={`Details ${props?.isCardEnabled ? "text-gray-100" : "text-gray-300"}`}
                                style={{ height: "9px" }}>
                                DETAILS
                            </div>
                            <div className="Line1"></div>
                        </div>
                        <div className="Frame169">
                            <div className="FrameDetailLines">
                                <div className={`Brand ${props?.isCardEnabled ? "text-gray-100" : "text-gray-300"}`}>
                                    Brand:&nbsp;
                                </div>
                                <div
                                    className={`TheProduct ${
                                        props?.isCardEnabled ? "text-blue-500" : "text-gray-300"
                                    }`}>
                                    {brand}
                                </div>
                            </div>
                            <div className="FrameDetailLines">
                                <div
                                    className={`Reference ${props?.isCardEnabled ? "text-gray-100" : "text-gray-300"}`}>
                                    Reference:&nbsp;
                                </div>
                                <div
                                    className={`ReferenceValue ${
                                        props?.isCardEnabled ? "text-blue-500" : "text-gray-300"
                                    }`}>
                                    {reference}
                                </div>
                            </div>
                            <div className="FrameDetailLines Boxes">
                                <div className={`Colors ${props?.isCardEnabled ? "text-gray-100" : "text-gray-300"}`}>
                                    Colors:&nbsp;
                                </div>
                                <div className="Frame264-2">
                                    {colors?.slice(0, 4).map((color, index) => {
                                        return (
                                            <div
                                                className="RectangleBoxes"
                                                style={{ background: getHexColor(color) }}
                                                key={index}
                                            />
                                        );
                                    }) ?? (
                                        <div className={`${props?.isCardEnabled ? "text-gray-100" : "text-gray-300"}`}>
                                            --
                                        </div>
                                    )}
                                    {colors?.length > 4 && (
                                        <div className="Frame383">
                                            <div className="Counter5">+{colors?.length}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="FrameDetailLines Boxes">
                                <div className={`Sizes ${props?.isCardEnabled ? "text-gray-100" : "text-gray-300"}`}>
                                    Sizes:&nbsp;
                                </div>
                                <div className="Frame385">
                                    {sizes?.slice(0, 4).map((size, index) => {
                                        return (
                                            <div className="SizeBoxes" key={index}>
                                                <div className="size">{size}</div>
                                            </div>
                                        );
                                    }) ?? (
                                        <div className={`${props?.isCardEnabled ? "text-gray-100" : "text-gray-300"}`}>
                                            --
                                        </div>
                                    )}
                                    {sizes?.length > 4 && (
                                        <div className="SizeBoxes">
                                            <div className="Counter3">+{sizes?.length}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="FrameDetailLines">
                                <div className={`Category ${props?.isCardEnabled ? "text-gray-100" : "text-gray-300"}`}>
                                    Category:&nbsp;
                                </div>
                                <div className={`Tops ${props?.isCardEnabled ? "text-blue-500" : "text-gray-300"}`}>
                                    {category}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Frame164">
                        {
                            <BtnLargeSolidInverted02
                                disabled={props?.primaryButton?.onClick === undefined}
                                text={props?.primaryButton?.title ?? "DETAILS"}
                                onClick={props?.primaryButton?.onClick}
                                style={{ flexGrow: "1" }}
                            />
                        }
                        {
                            <BtnLargeSolidInverted02
                                disabled={props?.secondaryButton?.onClick === undefined}
                                text={props?.secondaryButton?.title ?? "BOIMAGE"}
                                onClick={props?.secondaryButton?.onClick}
                                style={{ flexGrow: "1" }}
                            />
                        }
                    </div>
                </div>
                <div className={`Frame263 ${props?.tagOnclick ? "cursor-pointer" : ""}`} onClick={props?.tagOnclick}>
                    <svg
                        className={`Tag03 ${props?.tagOnclick ? "" : "hover-not-allowed"}`}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none">
                        <path
                            d="M14 7.3335L8.93726 2.27075C8.59135 1.92485 8.4184 1.7519 8.21657 1.62822C8.03762 1.51856 7.84254 1.43775 7.63846 1.38876C7.40829 1.3335 7.16369 1.3335 6.67452 1.3335L4 1.3335M2 5.80016L2 7.11651C2 7.44263 2 7.60569 2.03684 7.75914C2.0695 7.89519 2.12337 8.02525 2.19648 8.14454C2.27894 8.2791 2.39424 8.3944 2.62484 8.625L7.82484 13.825C8.35286 14.353 8.61687 14.617 8.92131 14.716C9.1891 14.803 9.47757 14.803 9.74536 14.716C10.0498 14.617 10.3138 14.353 10.8418 13.825L12.4915 12.1753C13.0195 11.6473 13.2835 11.3833 13.3825 11.0789C13.4695 10.8111 13.4695 10.5226 13.3825 10.2548C13.2835 9.95037 13.0195 9.68636 12.4915 9.15834L7.62484 4.29167C7.39424 4.06107 7.27894 3.94577 7.14438 3.86331C7.02508 3.7902 6.89502 3.73633 6.75898 3.70367C6.60553 3.66683 6.44247 3.66683 6.11634 3.66683H4.13333C3.3866 3.66683 3.01323 3.66683 2.72801 3.81215C2.47713 3.93999 2.27316 4.14396 2.14532 4.39484C2 4.68006 2 5.05343 2 5.80016Z"
                            stroke={props?.tagOnclick ? "var(--text-icons-gray-100)" : "var(--text-icons-gray-300)"}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            </div>
        </StyledPage>
    );
}

const getValues = (arrayValues, key) => {
    return arrayValues?.find(it => it?.key === key)?.value;
};

const getHexColor = color => {
    return "#" + color.replace("#", "");
};
