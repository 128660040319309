import React from "react";
import styled from "styled-components";
import BtnLargeSolidInverted02 from "../buttons/BtnLargeSolidInverted02";
import AppContext from "../../../AppContext";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { formatColorHex } from "../../../utils/Formatter";

/**
 * Renders a side panel for a product card.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.image] - The image URL.
 * @param {string} [props.category] - The category.
 * @param {string} [props.number] - The number.
 * @param {string} [props.productName] - The product name.
 * @param {string[]} [props.colors] - The colors.
 * @param {string} [props.selectedColor] - The selected color.
 * @param {Function} [props.onColorChange] - The callback function for the color change.
 * @param {string[]} [props.sizes] - The sizes.
 * @param {string} [props.selectedSize] - The selected size.
 * @param {Function} [props.onSizeChange] - The callback function for the size change.
 * @param {string} [props.buttonText] - The button text.
 * @param {boolean} [props.buttonEnabled] - The button enabled state.
 * @param {Function} [props.onButtonClick] - The callback function for the button click.
 * @param {boolean} [props.isCompletedCard] - The completed card state.
 * @returns {JSX.Element} The rendered component.
 *
 */
export default function ProductCardSidePanelUI(props) {
    //console.log("rendered");
    /* #region SCOPED STYLES */
    const StyledElement = styled.div`
        .ItemsPanelProductCard {
            width: 100%;
            height: 166px;
            padding: 8px;
            background: #1e2631;
            border-radius: 4px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 16px;
            display: inline-flex;
        }

        .Frame160 {
            align-self: stretch;
            height: 150px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 16px;
            display: flex;
        }

        .Frame173 {
            align-self: stretch;
            height: 150px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 8px;
            display: flex;
        }

        .Frame313312 {
            align-self: stretch;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 8px;
            display: inline-flex;
        }

        .Rectangle302 {
            width: 82px;
            height: 150px;
            border-radius: 4px;
            object-fit: cover;
        }

        .Frame313313 {
            flex: 1 1 0;
            align-self: stretch;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 8px;
            display: inline-flex;

            width: 100%;
            overflow: hidden;
        }

        .Frame313326 {
            align-self: stretch;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 8px;
            display: flex;
            position: relative;
        }

        .Cap {
            color: #00aeef;
            font-size: 10px;
            font-family: Roboto;
            font-weight: 400;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .BOTagNumber {
            display: flex;
            width: 24px;
            height: 24px;
            padding: 10px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;

            position: absolute;
            right: 0px;
            top: 0px;

            border-radius: 58px;
        }

        .ItemsPanelProductCard:hover .BOTagNumber {
            display: flex !important;
        }

        .Frame313323 {
            align-self: stretch;
            height: 53px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 8px;
            display: flex;
        }

        .LinenSeries {
            align-self: stretch;
            color: #d1d1d1;
            font-size: 18px;
            font-family: Roboto;
            font-weight: 400;
            word-wrap: break-word;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .Frame313316 {
            align-self: stretch;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            display: inline-flex;
        }

        .Frame313317 {
            flex: 1 1 0;
            height: 24px;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            display: flex;
        }

        .Rectangle {
            width: 24px;
            height: 24px;
            border-radius: 2px;
            border: 1px solid transparent;
        }

        .Rectangle:hover,
        .Rectangle.selected {
            border: 1px solid var(--App-Accent-Outline);
        }

        .Frame313318 {
            width: 24px;
            height: 24px;
            background: linear-gradient(270deg, #1e2631 0%, rgba(30, 38, 49, 0) 100%);
            justify-content: flex-end;
            align-items: center;
            gap: 10px;
            display: flex;
        }

        .Frame313315 {
            justify-content: flex-end;
            align-items: center;
            gap: 10px;
            display: flex;
        }

        .ChevronRight {
            width: 10px;
            height: 10px;
            position: relative;
        }

        .Solid {
            left: 0px;
            top: 0px;
            position: absolute;
        }

        .Frame313328 {
            align-self: stretch;
            flex: 1 1 0;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            display: flex;
        }

        .Frame313325 {
            width: 222px;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 8px;
            display: inline-flex;
        }

        .ItemPanelBtnSmallRounded {
            width: 24px;
            height: 16px;
            border-radius: 55px;
            border: 1px #7c868b solid;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            display: inline-flex;
            overflow: hidden;

            font-family: Roboto;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .ItemPanelBtnSmallRounded:hover,
        .ItemPanelBtnSmallRounded.selected {
            border: 1px solid var(--App-Accent-Outline);
        }

        .Frame313324 {
            align-self: stretch;
            justify-content: flex-end;
            align-items: center;
            gap: 8px;
            display: inline-flex;
        }

        .owl-theme .owl-nav {
            margin: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        .owl-theme .owl-nav > .disabled {
            display: none;
        }

        .Frame313325,
        .Frame313317 {
            //padding-left: 15px;
            //padding-right: 15px;

            position: relative;
            width: 100%;
        }

        .owl-prev {
            left: -4px;
            position: absolute;
            top: -13px;
            background: transparent !important;
        }

        .owl-next {
            left: auto;
            position: absolute;
            right: -4px;
            top: -13px;
            background: transparent !important;
        }

        .owl-nav span {
            font-size: 24px !important;
            color: var(--text-icons-blue-500) !important;
        }

        .owl-nav.disabled {
            display: block !important;
        }

        .owl-nav.disabled span {
            color: var(--Buttons-All-Platforms-Gray-200) !important;
        }
    `;
    /* #endregion */

    const { sidePanelSelectedProductNumber } = React.useContext(AppContext);

    const getSelectedIndex = (array, value) => {
        if (!array || !value) return 0;
        const res = array.findIndex(c => c === value);
        return res === -1 ? 0 : res;
    };

    return (
        <StyledElement>
            <div className="ItemsPanelProductCard">
                <div className="Frame160">
                    <div className="Frame173">
                        <div className="Frame313312">
                            <img className="Rectangle302" alt="Canvas" src={props?.image} />
                            <div className="Frame313313">
                                <div className="Frame313326">
                                    <div className="Cap" style={{ paddingRight: "30px" }}>
                                        {props?.category ?? "--"}
                                    </div>
                                    {props?.number && (
                                        <div
                                            className={`BOTagNumber ${!props?.isCompletedCard ? "hidden" : ""}`}
                                            style={{
                                                background: props?.isCompletedCard
                                                    ? "var(--Buttons-All-Platforms-btn-action-blue)"
                                                    : "var(--text-icons-green-500)"
                                            }}>
                                            <label style={{ display: "table", position: "relative", top: "1px" }}>
                                                {props?.number}
                                            </label>
                                        </div>
                                    )}
                                    <div className="Frame313323">
                                        <div className="LinenSeries">{props?.productName ?? "--"}</div>
                                        <div className="Frame313316">
                                            <div className="Frame313317">
                                                <OwlCarousel
                                                    className="owl-theme"
                                                    margin={0}
                                                    nav
                                                    items={7}
                                                    dots={false}
                                                    startPosition={getSelectedIndex(
                                                        props?.colors,
                                                        props?.selectedColor
                                                    )}>
                                                    {props?.colors?.map((color, index) => (
                                                        <div
                                                            key={index}
                                                            onClick={() => props?.onColorChange(color)}
                                                            className={`Rectangle ${
                                                                !sidePanelSelectedProductNumber &&
                                                                !props?.isCompletedCard
                                                                    ? "hover-not-allowed"
                                                                    : "cursor-pointer"
                                                            } ${color === props?.selectedColor ? "selected" : ""}`}
                                                            style={{ background: formatColorHex(color) }}></div>
                                                    ))}
                                                </OwlCarousel>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="Frame313328">
                                    <div className="Frame313325">
                                        <OwlCarousel
                                            className="owl-theme"
                                            margin={0}
                                            nav
                                            items={7}
                                            dots={false}
                                            startPosition={getSelectedIndex(props?.sizes, props?.selectedSize)}>
                                            {props?.sizes?.map((size, index) => (
                                                <div
                                                    key={index}
                                                    onClick={() => props?.onSizeChange(size)}
                                                    className={`ItemPanelBtnSmallRounded ${
                                                        !sidePanelSelectedProductNumber && !props?.isCompletedCard
                                                            ? "hover-not-allowed"
                                                            : "cursor-pointer"
                                                    } ${size === props?.selectedSize ? "selected" : ""}`}>
                                                    {size}
                                                </div>
                                            ))}
                                        </OwlCarousel>
                                    </div>

                                    <div className="Frame313324">
                                        <BtnLargeSolidInverted02
                                            text={props?.buttonText ?? "--"}
                                            disabled={
                                                (!sidePanelSelectedProductNumber && !props?.isCompletedCard) ||
                                                !props?.selectedColor ||
                                                !props?.selectedSize
                                            }
                                            onClick={props?.onButtonClick}
                                            style={{ width: "100%" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledElement>
    );
}
