import styled from "styled-components";
import CardGridSkeleton from "../gridCards/animations/CardGridSkeleton";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useRef, useState } from "react";
import BOImagesCard from "../gridCards/BOImagesCard";
import * as Library from "../../../utils/Library";
import { formatDefaultDate, formatDefaultDateTime } from "../../../utils/Formatter";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: scroll;
        height: 100%;
        margin-right: -24px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 17px;
    }
`;

/* #endregion */

export default function InfinityGridBOImagesCards(props) {
    const gridLayoutStyle = {
        display: "grid",
        gridTemplateColumns: `repeat(auto-fill, minmax(${props?.minColumnWidth || "395px"}, 1fr))`,
        gap: "24px"
    };

    /* #region STATES */
    const scrollContainerRef = useRef(null);
    const [scroll, setScroll] = useState(true);
    const pageLimit = props?.limit || 12;
    const [queryKey, setQueryKey] = useState(props?.queryKey ?? uuidv4());
    const [minimumCardsFill, setminimumCardsFill] = useState(props?.minimumCardsFill || pageLimit);

    const {
        data,
        isFetching: isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
    } = Library.usePostInfiniteQuery(
        queryKey,
        "getBOimagesData",
        {
            companyID: localStorage.getItem("currentCompanyID"),
            limit: pageLimit
        },
        () => {
            setScroll(true);
        }
    );

    const pageList = data?.pages;
    //const totalCount = data?.pages[0]?.data?.totalCount;
    const nextDataCount = data?.pages[data?.pages.length - 1].data?.nextDataCount;
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        handleScroll();
    }, [data?.pages]);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer && !isLoading && scroll && !isFetchingNextPage) {
            scrollContainer.addEventListener("scroll", handleScroll);
        }

        return () => {
            scrollContainer?.removeEventListener("scroll", handleScroll);
        };
    }, [scrollContainerRef, isLoading, pageList, scroll, isFetchingNextPage, data?.pages]);
    /* #endregion */

    /* #region METHODS */
    const handleScroll = () => {
        const scrollContainer = scrollContainerRef.current;

        const isAtBottom =
            Math.floor(scrollContainer.scrollHeight - scrollContainer.scrollTop) <= scrollContainer.clientHeight;

        if (isAtBottom && !isLoading && hasNextPage && !isFetchingNextPage) {
            setScroll(false);
            fetchNextPage();
        }
    };

    const getTotalCards = () => {
        return pageList.reduce((acc, it) => {
            return acc + it?.data?.result?.length;
        }, 0);
    };

    /* #endregion */
    return (
        <StyledPage className={`${props?.className || ""}`} ref={scrollContainerRef}>
            {isLoading && !isFetchingNextPage ? (
                <div style={gridLayoutStyle}>
                    {Array.from({ length: pageLimit }, () => (
                        <CardGridSkeleton
                            key={uuidv4()}
                            height="420.28px"
                            minColumnWidth={props?.minColumnWidth || "395px"}
                        />
                    ))}
                </div>
            ) : (
                <div className="mb-24px" style={gridLayoutStyle}>
                    {pageList?.map(it => {
                        return it?.data.result?.map((item, i) => {
                            return (
                                <BOImagesCard
                                    key={item?.botagID}
                                    image={item?.pictureURL}
                                    imageOnClick={props?.onEditAction ? () => props?.onEditAction(item) : null}
                                    hideImage={false}
                                    title={item?.name}
                                    id={item?.botagID}
                                    isCardEnabled={item?.status?.toLowerCase() !== "suspended"}
                                    isCardSuspended={item?.status?.toLowerCase() === "suspended"}
                                    data={{
                                        activePeriod:
                                            item?.activePeriodStart && item?.activePeriodEnd
                                                ? `${formatDefaultDate(item?.activePeriodStart)} to ${formatDefaultDate(
                                                      item?.activePeriodEnd
                                                  )}`
                                                : "--",
                                        createdBy: item?.createdBy,
                                        createdOn: formatDefaultDateTime(item?.createdOn),
                                        status: item?.status
                                    }}
                                    edit={props?.onEditAction ? { onClick: () => props?.onEditAction(item) } : null}
                                    suspend={{
                                        isReenable: item?.status?.toLowerCase() === "suspended",
                                        onClick: props?.onSuspendAction ? () => props?.onSuspendAction(item) : null
                                    }}
                                    delete={
                                        props?.onDeleteAction ? { onClick: () => props?.onDeleteAction(item) } : null
                                    }
                                    primaryButton={{
                                        title: props?.primaryButton?.title ?? "Details",
                                        onClick: props?.primaryButton?.onClick
                                            ? () => props?.primaryButton?.onClick(item)
                                            : undefined
                                    }}
                                    secondaryButton={{
                                        title: props?.secondaryButton?.title ?? "Publish",
                                        onClick: props?.secondaryButton?.onClick
                                            ? () => props?.secondaryButton?.onClick(item)
                                            : undefined
                                    }}
                                    cardData={item}
                                />
                            );
                        });
                    })}

                    {!isFetchingNextPage &&
                        minimumCardsFill - getTotalCards() > 0 &&
                        Array.from({ length: minimumCardsFill - getTotalCards() }, (_, index) => (
                            <BOImagesCard isCardEnabled={false} key={index} />
                        ))}

                    {isFetchingNextPage &&
                        Array.from({ length: nextDataCount }, (_, index) => (
                            <CardGridSkeleton
                                height="420.28px"
                                minColumnWidth={props?.minColumnWidth || "395px"}
                                key={index}
                            />
                        ))}
                </div>
            )}
        </StyledPage>
    );
}
