import React, { useEffect, useState } from "react";
import { useGetNdaAcceptedMutation } from "../../../api/nda/nda.query";

const NDAPopup = ({ BOstagesAccountID, setShowNda }) => {
    const [isChecked, setIsChecked] = useState(false);
    const { mutate: getNdaData, data: ndaData, isLoading } = useGetNdaAcceptedMutation();

    const handleClick = () => {
        getNdaData({
            BOstagesAccountID: BOstagesAccountID
        });
    };

    const handleChange = () => {
        setIsChecked(!isChecked);
    };
    return (
        <div className="nda-popup-container">
            <div className="main-popup-container">
                <div className="heading-popup">
                    <h4 className="main-heading">NON-DISCLOSURE AGREEMENT</h4>
                    <div style={{ marginRight: "16px", cursor: "pointer" }}>
                        <i className=""></i>
                    </div>
                </div>
                <div className="main-date-popup">
                    <span className="date-popup">Last updated: 01/08/2022</span>
                </div>
                <div className="body-popup">
                    <p>I agree to: </p>
                    <p className="body-p2-popup">
                        a. Maintain the Confidential Information in strict confidence;
                        <p className="body-p3-popup">
                            b. Not disclose the Confidential Information to any third parties;
                        </p>
                        <p className="body-p4-popup">
                            Confidential Information includes all data, design, user interface, technology, user
                            experience, specifications, software, and other information disclosed by BOtag Norge AS to
                            myself.
                        </p>
                    </p>
                </div>
                <div className="footer-popup">
                    <div className="checkbox-container">
                        <input
                            type="checkbox"
                            id="cb3"
                            className="checkbox"
                            checked={isChecked}
                            onChange={handleChange}
                            style={{ marginTop: "0px" }}
                        />
                        <label for="cb3"></label>
                    </div>
                    {/* <input
                        type="checkbox"
                        id="myCheckbox"
                        className="checkbox"
                        checked={isChecked}
                        onChange={handleChange}
                        style={{ marginTop: "0px" }}
                    /> */}

                    <p style={{ fontSize: "10px", fontWeight: 400, marginTop: "0px", color: "#ffffff" }}>
                        I agree with the Terms of the NDA
                    </p>
                </div>
                <div className="btn-popup">
                    <button
                        onClick={() => {
                            handleClick();
                            setShowNda(false);
                        }}
                        className="button-main-popup "
                        style={{ cursor: isChecked ? "pointer" : "not-allowed", color: isChecked ? "#D1D1D1" : "" }}
                        disabled={isChecked ? false : true}>
                        CONTINUE
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NDAPopup;
