import Chart from "react-apexcharts";

const GrossMarginGraphComponent = ({ type, height, options, series }) => {
    return (
        <>
            <Chart type={type} height={height} options={options} series={series} />
        </>
    );
};

export default GrossMarginGraphComponent;
