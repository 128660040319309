import FullfillmentCentersPage from "../components/pages/fullfillmentCenters/FullfillmentCentersPage";
import FFCStock from "../components/pages/fullfillmentCenters/FFCStock";

const routes = [
    {
        path: "/ffcenters",
        element: <FullfillmentCentersPage />
    },
    {
        path: "/ffcenters/stock",
        element: <FFCStock />
    },
    {
        path: "/ffcenters/stock/:storeID/:storeName",
        element: <FFCStock />
    }
];

export default routes;
