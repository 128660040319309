import React from "react";
import styled from "styled-components";
import GridCardComponent from "./generic/GridCardComponent";
import { defaultStatusColors, getSpanWithColor } from "../../../utils/CardUtils";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    #UIcard-style-03-m {
        padding-bottom: 0px !important; //card padding override
    }

    .AccountDetailsCard .Frame-204 > .details-row-container:not(:last-child) {
        border-right: 1px solid var(--backgrounds-lines-n-100);
        padding-right: 24px;
    }
`;
/* #endregion */

export default function AccountDetailsCard(props) {
    const details = props?.details || [];

    details.status = getSpanWithColor(details?.status ?? "--", defaultStatusColors);

    return (
        <StyledPage>
            <GridCardComponent
                className="AccountDetailsCard"
                hasBorders={true}
                isProfileCard={true}
                profileImage={props?.profileImage}
                edit={{ active: false }}
                suspend={{ active: false }}
                delete={{ active: false }}
                isCardEnabled={true}
                titlePrefix="ACCOUNT"
                detailsType="account-details"
                title={props?.title}
                id={props?.id}
                image={props?.image}
                imageHasPadding={true}
                details={details}
            />
        </StyledPage>
    );
}
