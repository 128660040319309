import React, { useEffect, useState } from "react";
import InfinityGridBrandsUsersCards from "../../common/cardContainers/InfinityGridBrandsUsersCards";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import ListComponent from "../../common/lists/ListComponent";
import { useOutletContext } from "react-router";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: hidden;
        height: 100%;
        margin-right: -23px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }
`;
/* #endregion */

export default function BrandsUsersPage() {
    document.title = `${APP_TITLE} - Brand Users`;

    /* #region STATES */
    const { MainLayoutProps, layoutType } = useOutletContext();
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };

    const [viewLayoutStyle, setViewLayoutStyle] = useState("Grid");
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(props);
    }, [props?.layoutType]);
    /* #endregion */

    /* #region LIST COMPONENT PROPS */
    const displayName = (firstName, lastName, defaultValue = "--") => {
        let result = defaultValue;
        if (firstName) result = firstName + " " + (lastName || "");

        return result;
    };

    const listColumns = [
        {
            name: "First Name",
            selector: row => row?.firstName ?? "--"
        },
        {
            name: "Last Name",
            selector: row => row?.lastName ?? "--"
        },
        {
            name: "Role",
            selector: row => row?.role ?? "--"
        },
        {
            name: "Stage Level",
            selector: row => row?.stageLevel ?? "Account" //TODO: Implement stage level
        },
        {
            name: "Mobile",
            selector: row => row?.mobile ?? "--"
        },
        {
            name: "Email",
            selector: row => row?.email ?? "--"
        }
    ];
    /* #endregion */

    return (
        <StyledPage>
            {viewLayoutStyle === "Grid" && (
                <InfinityGridBrandsUsersCards minColumnWidth="238px" limit={12} defaultSecondTitle="Brand:" />
            )}
            {viewLayoutStyle === "List" && (
                <ListComponent
                    title="Brands Users"
                    columns={listColumns}
                    api={{
                        endpoint: "getUsersData",
                        useCommonPostRequest: true,
                        commonContext: "Users",
                        formData: {
                            companyID: localStorage.getItem("currentCompanyID"),
                            organizationID: localStorage.getItem("currentOrganizationID"),
                            labelBrandsIDS: JSON.parse(localStorage.getItem("labelBrands"))?.map(it => it._id)
                        }
                        //onSuccess: mapListData
                    }}
                    pageLimit={20}
                    currentPage={1}
                    queryKey={"getUsersData brands " + localStorage.getItem("activeBrands")}
                    actions={{ includeActions: true, extraActions: [{ title: "Details" }] }}
                    includeStatus={{
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: [
                            { colorClass: "text-green-500", forValues: ["Invited"] },
                            { colorClass: "text-blue-600", forValues: ["Active", "Fulfilling"] },
                            { colorClass: "text-red-500", forValues: ["Inactive", "Suspended", "Not connected"] }
                        ]
                    }}
                />
            )}
        </StyledPage>
    );
}

function setupHeaders(props) {
    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Brands", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/brands", active: false, disabled: false, title: "Brands" },
        { to: "/brands/users", active: true, disabled: false, title: "Users" }
    ]);
    props.setControlBarRightBtns([
        { to: "#", active: true, disabled: false, title: "View", icon: "eye" },
        { to: "#", active: false, disabled: true, title: "Invite", icon: "mail" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        visible: true,
        gridEnabled: true,
        listEnabled: true,
        onRefreshAction: undefined
    });
}
