import React, { useEffect, useState } from "react";
import packageJson from "../../../../package.json";
import styled from "styled-components";
import footerlogo from "../../../assets/images/footer-logologin.png";
import preenrollmentLeftImage from "../../../assets/images/preenrollment-left-image.png";
//import loginLeftBg from "../../../assets/images/login-left-bg.png";
import loginTopImg from "../../../assets/images/login-top-img.png";
import BtnLargeSolid from "../../common/buttons/BtnLargeSolid";
import * as Library from "../../../utils/Library";
import ContainerLoader from "../../common/loaders/ContainerLoader";
import { APP_TITLE } from "../../../config/constant";
import moment from "moment/moment";
import TermsAndPrivacyPanel from "../../common/termsandConditions/TermsAndPrivacyPanel";
import { useNavigate, useParams } from "react-router";
import DropdownOverlay, { OverlayType } from "../../common/dropdownOverlay/DropdownOverlay";
import { getDropdownData } from "../../../utils/dropdownData";

/* #region SCOPED CSS */
const StyledPage = styled.div`
    .loading-filters {
        opacity: 0.5;
        filter: blur(4px);
    }

    & {
        height: 100dvh;
        overflow: auto;

        background: var(--backgrounds-lines-n-600);
        display: flex;
    }

    .required-asterisk {
        color: var(--text-icons-orange-500);
    }

    .FrameLeft {
        display: flex;
        flex: 1 1 0;
        place-content: center;

        /*
        background-image: $ url({loginLeftBg});
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom left; // Adjusted the background position to bottom left.
        */
    }

    .FrameRight {
        display: flex;
        flex: 1 1 0;
        justify-content: center;
        align-items: center;
        justify-items: center;
    }

    .Footer {
        position: absolute;
        bottom: 40px;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: center;
    }

    .TermsConditions {
        color: white;
        font-size: 10px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .PrivacySecurity {
        color: white;
        font-size: 10px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .BotagNorgeAs2024 {
        color: #7c868b;
        font-size: 10px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .AppVersion120 {
        color: #7c868b;
        font-size: 10px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .LoginTopImg {
        width: 132px;
        height: 42px;
        flex-shrink: 0;
        display: flex;
        position: absolute;
        top: 59px;
        left: 54px;
    }

    .LeftContainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        //padding-left: 96px;
    }

    .group-1000011088 {
    }

    .LeftImg {
        display: flex;
        width: 100%;
        height: auto;
        object-fit: contain;
        position: relative;
        top: -1%;
        left: 7%;
    }

    .LeftTitle {
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 32px;
        font-style: normal;
        font-weight: 100;
        line-height: normal;
        letter-spacing: -0.06rem;

        display: flex;
        align-self: center;
        width: 100%;
        position: relative;
        left: 7%;
        text-align: center;
        top: -350px;
        justify-content: center;
        padding-left: 96px;
    }

    .LeftTitle strong {
        display: contents;
        font-weight: 300;
    }

    .Frame313264 {
        min-width: 320px;
        max-width: 400px;
        flex-direction: column;
        gap: 15px;
        display: flex;
        align-self: center;
        justify-content: center;
        align-items: center;
    }

    .Image1 {
        width: 208px;
        height: 51px;
        background: url(${footerlogo}) 0% 0% / contain no-repeat;
    }

    .Frame313326 {
        align-self: stretch;
        //height: 290px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        display: flex;
    }

    .Frame313266 {
        align-self: stretch;
        //height: 262px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        display: flex;
    }

    .Frame313263 {
        align-self: stretch;
        height: 23px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        display: flex;
    }

    .LoginWithYourCredentials {
        align-self: stretch;
        text-align: center;
        color: white;
        font-size: 20px;
        font-family: Roboto;
        font-weight: 300;
        word-wrap: break-word;
        height: 23px;
    }

    .Frame313260 {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        display: flex;
        align-self: stretch;
    }

    .InputField {
        height: 50px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        display: flex;
        align-self: stretch;
    }

    .EmailAddress {
        align-self: stretch;
        color: #d1d1d1;
        font-size: 14px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
        height: 16px;
    }

    .PrefixLabel {
        align-self: stretch;
        color: #7c868b;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
        height: 14px;
        align-self: stretch;

        border: 0px;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .Frame313324 {
        align-self: stretch;
        width: 100%;
        height: 28px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 14px;
        display: flex;
        background: transparent;
        outline: none;
        border: 0;

        //color: var(--Primary-White-500, #fff);
        color: var(--text-icons-green-500, #0b0) !important;
        -webkit-text-fill-color: var(--text-icons-green-500, #0b0) !important;
        padding-left: 0px;

        /* Subtitle 2 */
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .Frame313324 {
        height: 17px;
    }

    .Line2 {
        align-self: stretch;
        height: 0px;
        width: 100%;
        border-top: 1px #3c485a solid;
    }

    .Password {
        align-self: stretch;
        color: #7c868b;
        font-size: 12px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .Frame313261 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
    }

    .Frame313327 {
        width: 393px;
        height: 28px;
        position: relative;
    }

    .EyeOff {
        width: 16px;
        height: 16px;
        top: 0px;
        position: absolute;
        right: 8px;
    }

    .Icon {
        width: 16px;
        height: 16px;
        position: absolute;
    }

    .ForgotPassword {
        align-self: stretch;
        text-align: right;
        color: #d1d1d1;
        font-size: 12px;
        font-family: Roboto;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.18px;
        word-wrap: break-word;
    }

    .mandatory-fields {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        position: relative;
        align-self: stretch;
    }
    .mandatory-fields-span {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
    }
    .mandatory-fields-span2 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: 12px;
        font-weight: var(--subtitle-3-font-weight, 400);
    }

    .Share {
        color: #4f5b6d;
        font-size: 12px;
        font-family: Roboto;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.15px;
        word-wrap: break-word;
    }

    .loginTermsPrivacyPolicies {
        align-self: stretch;
        text-align: center;
        color: #d1d1d1;
        font-size: 10px;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .Error {
        width: 100%;
        height: 100%;
        padding: 16px;
        background: rgba(255, 54, 54, 0.1);
        border-radius: 4px;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        display: inline-flex;
    }

    .EmailInvalidSection {
        flex: 1 1 0;
        text-align: center;
        color: #ff3636;
        font-size: 12px;
        font-family: Roboto;
        font-weight: 300;
        word-wrap: break-word;
        transition: all 0.3s ease;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-background-clip: text;
        -webkit-text-fill-color: #ffffff;
        transition: background-color 5000s ease-in-out 0s;
        box-shadow: inset 0 0 20px 20px transparent;
    }

    .header {
        padding: 24px 24px 0px 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;
        flex-shrink: 0;
        height: 64px;
        position: relative;
        backdrop-filter: blur(40px);
    }
    .image-2 {
        flex-shrink: 0;
        width: 96px;
        height: 30px;
        position: relative;
        object-fit: cover;
    }
    .frame-29222 {
        opacity: 0;
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29518 {
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        position: relative;
    }
    .marker-pin-04 {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        overflow: visible;
    }
    .frame-29219 {
        opacity: 0;
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .bell-02 {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        overflow: visible;
    }

    .frame-1 {
        display: flex;
        flex-direction: column;
        gap: 39px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        height: 58px;
    }
    .the-brand-stage-for-botag-technology {
        color: #ffffff;
        text-align: center;
        font-size: 22px;
        letter-spacing: -0.015em;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        height: 58px;
    }
    .the-brand-stage-for-botag-technology-span {
        color: #ffffff;
        font-size: 22px;
        letter-spacing: -0.015em;
        font-weight: 100;
    }
    .the-brand-stage-for-botag-technology-span2 {
        color: #ffffff;
        font-size: 22px;
        letter-spacing: -0.015em;
        font-weight: 300;
    }
    .the-brand-stage-for-botag-technology-span3 {
        color: #ffffff;
        font-size: 22px;
        letter-spacing: -0.015em;
        font-weight: 100;
    }
    .mobileBackground {
        border-radius: 16px;
        background: rgba(16, 20, 27, 0.7);
        backdrop-filter: blur(50px);
        padding: 0 24px 24px 24px;
    }

    .ellipse-2 {
        background: #ff9900;
        border-radius: 50%;
        width: 343px;
        height: 423px;
        position: absolute;
        left: 11px;
        top: 781px;
        filter: blur(201.45px);
    }
    .ellipse-1 {
        background: #ff99004d;
        border-radius: 50%;
        width: 250px;
        height: 1005px;
        position: absolute;
        left: -87px;
        top: 378px;
        filter: blur(130.95px);
    }
    .rectangle-10 {
        background: rgba(16, 20, 27, 0.8);
        width: 100vw;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
    }
    .frame-313865 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .frame-313866 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .terms-conditions {
        color: var(--primary-white-500, #ffffff);
        text-align: center;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .privacy-security {
        color: var(--primary-white-500, #ffffff);
        text-align: center;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .b-otag-norge-as-2024 {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: center;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .app-version-1-2-0 {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: center;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
`;

const StyledThankYou = styled.div`
    &.frame-313266,
    &.frame-313266 * {
        box-sizing: border-box;
    }
    &.frame-313266 {
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: center;
        justify-content: flex-start;
        position: relative;
    }
    .image-1 {
        flex-shrink: 0;
        width: 208px;
        height: 51px;
        position: relative;
        object-fit: contain;
    }
    .line-4 {
        margin-top: -1px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-200, #262e3e);
        border-width: 1px 0 0 0;
        align-self: stretch;
        flex-shrink: 0;
        height: 0px;
        position: relative;
    }
    .frame-259 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-286 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .thank-you-for-pre-enrolling-on-b-otag {
        color: #ffffff;
        text-align: center;
        font-family: "Roboto-Bold", sans-serif;
        font-size: 32px;
        font-weight: 700;
        position: relative;
    }
    .frame-285 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-160 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .please-check-your-email-for-next-steps {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: var(--heading-4-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--heading-4-font-size, 18px);
        font-weight: var(--heading-4-font-weight, 400);
        position: relative;
    }
`;

/* #endregion */

export default function PreEnrollmentInvitedPage() {
    document.title = `${APP_TITLE} - Pre Enrollment`;

    const FrameRightMobile = {
        placeItems: "flex-start",
        height: "100dvh"
    };

    /* #region STATES */
    const { inviteID } = useParams();

    const [showTAC, setShowTAC] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    /* #endregion */

    /* #region EFFECTS */

    // useEffect(() => {
    //     if (!inviteID) {
    //         Library.showErrorMessage("Invalid invite link.");
    //         navigate("/login");
    //     }
    // }, [inviteID]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1024) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    /* #endregion */

    return (
        <StyledPage style={{ overflow: "visible" }}>
            {showTAC && <TermsAndPrivacyPanel type="terms" onCloseClicked={() => setShowTAC(false)} />}
            {showPrivacy && <TermsAndPrivacyPanel type="privacy" onCloseClicked={() => setShowPrivacy(false)} />}

            {!isMobile && (
                <div className="FrameLeft">
                    <img src={loginTopImg} className="LoginTopImg" alt="BOtag Technology" />

                    <div className="LeftContainer">
                        <img src={preenrollmentLeftImage} className="LeftImg" alt="BOtag Technology" />
                        <div className="group-1000011088 d-none"></div>
                        <label className="LeftTitle d-none">
                            THE RETAILER STAGE FOR <br />
                            <strong>BO</strong>TAG TECHNOLOGY
                        </label>
                    </div>
                </div>
            )}

            {isMobile && (
                <>
                    <div className="ellipse-2"></div>
                    <div className="ellipse-1"></div>
                    <div className="rectangle-10"></div>
                </>
            )}

            <div className="FrameRight" style={isMobile ? FrameRightMobile : {}}>
                <FrameRightComponent
                    inviteID={inviteID}
                    isMobile={isMobile}
                    setShowTAC={setShowTAC}
                    setShowPrivacy={setShowPrivacy}
                />
            </div>

            {!isMobile && (
                <div className="Footer">
                    <div className="BotagNorgeAs2024">BOtag Norge AS © {moment().format("yyyy")}</div>
                    <div className="AppVersion120">App Version {packageJson.version}</div>
                </div>
            )}
        </StyledPage>
    );
}

/* #region SMALL COMPONENTS */

const FrameRightComponent = ({ inviteID, isMobile, setShowTAC, setShowPrivacy }) => {
    /* #region VARS */
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(true);
    const [showThankYou, setShowThankYou] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
    const [brandIsInvalid, setBrandIsInvalid] = useState(false);
    const [brandName, setBrandName] = useState("");
    const [website, setWebsite] = useState("");
    const [websiteIsInvalid, setWebsiteIsInvalid] = useState(false);
    const [instagram, setInstagram] = useState("");
    const [instagramIsInvalid, setInstagramIsInvalid] = useState(false);
    const [company, setCompany] = useState("");
    const [companyIsInvalid, setCompanyIsInvalid] = useState(false);
    const [country, setCountry] = useState({
        value: "",
        countryFromDropdown: false
    });
    const [countryIsInvalid, setCountryIsInvalid] = useState(false);
    const [registrationNr, setRegistrationNr] = useState("");
    const [registrationNrIsInvalid, setRegistrationNrIsInvalid] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [firstNameIsInvalid, setFirstNameIsInvalid] = useState(false);
    const [lastName, setLastName] = useState("");
    const [lastNameIsInvalid, setLastNameIsInvalid] = useState(false);
    const [email, setEmail] = useState("");
    const [emailIsInvalid, setEmailIsInvalid] = useState(false);

    const [showCountryDropdown, setShowCountryDropdown] = useState(false);
    const [countryOptionsData] = useState(getDropdownData("countryOptions"));

    const flexMobileContainer = {
        width: "100%",
        display: "flex",
        gap: isMobile ? "16px" : "46px",
        flexDirection: isMobile ? "column" : "row"
    };
    /* #endregion */

    /* #region METHODS */
    const validateEmail = email => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const handleValidation = () => {
        if (buttonDisable) return false;
        let formIsValid = true;
        if (!brandName) {
            formIsValid = false;
            setBrandIsInvalid(true);
        }
        if (!website) {
            formIsValid = false;
            setWebsiteIsInvalid(true);
        }
        if (!company) {
            formIsValid = false;
            setCompanyIsInvalid(true);
        }
        if (!country?.value && !country?.countryFromDropdown) {
            formIsValid = false;
            setCountryIsInvalid(true);
        }
        if (!firstName) {
            formIsValid = false;
            setFirstNameIsInvalid(true);
        }
        if (!lastName) {
            formIsValid = false;
            setLastNameIsInvalid(true);
        }
        if (!email) {
            formIsValid = false;
            setEmailIsInvalid(true);
        } else if (isNaN(email)) {
            if (!validateEmail(email)) {
                formIsValid = false;
                setEmailIsInvalid(true);
            } else setEmailIsInvalid(false);
        }
        return formIsValid;
    };

    const handleSubmit = e => {
        setBrandIsInvalid(false);
        setWebsiteIsInvalid(false);
        setInstagramIsInvalid(false);
        setCompanyIsInvalid(false);
        setCountryIsInvalid(false);
        setRegistrationNrIsInvalid(false);
        setFirstNameIsInvalid(false);
        setLastNameIsInvalid(false);
        setEmailIsInvalid(false);

        setErrorMessage("");
        e.preventDefault();
        if (handleValidation()) {
            setButtonDisable(true);

            const formData = {
                inviteID: inviteID,
                organizationInfo: {
                    organizationName: brandName,
                    stages: ["BOstageR"]
                },
                retailBrandInfo: {
                    retailBrandName: brandName
                },
                companyInfo: {
                    companyName: company,
                    website: "https://" + website,
                    companyInstagram: "https://www.instagram.com/" + instagram,
                    countryName: country?.value,
                    registrationNumber: registrationNr
                },
                userInfo: {
                    userFirstName: firstName,
                    userLastName: lastName,
                    userEmail: email
                }
            };
            setIsLoading(true);

            Library.makeCommonPostRequest("Organization", "createOrganization", formData, false, setButtonDisable).then(
                res => {
                    setIsLoading(false);
                    if (res.data.status !== 200) {
                        //Library.showErrorMessage(res.data.message);
                        return;
                    }
                    setShowThankYou(true);
                }
            );
        }
    };

    const handleKeyPress = e => {
        if (e.key === "Enter") {
            handleSubmit(e);
        }
    };

    const getInviteData = () => {
        Library.makeCommonPostRequest("Accounts", "getInvite", { inviteID: inviteID }, false, setIsLoading).then(
            res => {
                if (res.data.status !== 200) {
                    handleInvitationError(res.data.message);
                    return;
                }
                const data = res.data.data;

                /*if (!data || !["invited", "accepted"].includes(data?.status?.status?.toLowerCase())) {
                    handleInvitationError("Invitation already accepted. Use credentials sent to your email to login.");
                    return;
                }*/
                if (data?.inviteType?.toLowerCase() !== "retailbrand") {
                    handleInvitationError("Invalid invite type.");
                    return;
                }

                if (data?.status?.status?.toLowerCase() === "invited") {
                    updateInviteRequest();
                }

                setBrandName(data?.newEntityName);
                setEmail(data?.contactEmail);
                setFirstName(data?.contactFirstName);
                setLastName(data?.contactLastName);
            }
        );
    };

    const handleInvitationError = errorMessage => {
        Library.showErrorMessage(errorMessage);
        navigate("/login");
    };

    const updateInviteRequest = () => {
        Library.makeCommonPostRequest(
            "Accounts",
            "updateInvite",
            { inviteID: inviteID, status: "Accepted" },
            false,
            setIsLoading
        ).then(res => {
            if (res.data.status !== 200) {
                return;
            }
        });
    };

    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (
            brandName &&
            website &&
            company &&
            country?.value &&
            country?.countryFromDropdown &&
            firstName &&
            lastName &&
            email
        ) {
            setButtonDisable(false);
        } else {
            setButtonDisable(true);
        }
    }, [brandName, website, company, country?.value && country?.countryFromDropdown, firstName, lastName, email]);

    useEffect(() => {
        if (inviteID) getInviteData();
    }, [inviteID]);
    /* #endregion */

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                height: showThankYou ? "100%" : "auto"
                //overflowY: "auto",
                //overflowX: "hidden"
            }}>
            {isMobile && (
                <div className="header">
                    <img className="image-2" src={loginTopImg} alt="" />
                    <div className="frame-29222">
                        <div className="frame-29518"></div>
                    </div>
                    <div className="frame-29219">
                        <div className="frame-29518"></div>
                    </div>
                </div>
            )}
            <div
                className={`Frame313264 ${isMobile && !showThankYou ? "mobileBackground" : ""}`}
                style={{ height: showThankYou ? "100%" : "auto" }}>
                {!showThankYou ? (
                    <>
                        <div className="Image1" style={{ marginBottom: "0px" }} />

                        {isMobile && (
                            <div className="frame-1">
                                <div className="the-brand-stage-for-botag-technology">
                                    <span>
                                        <span className="the-brand-stage-for-botag-technology-span">
                                            THE STORE BRAND STAGE FOR 
                                            <br />
                                        </span>
                                        <span className="the-brand-stage-for-botag-technology-span2">BO</span>
                                        <span className="the-brand-stage-for-botag-technology-span3">
                                            TAG TECHNOLOGY
                                        </span>
                                    </span>
                                </div>
                            </div>
                        )}

                        <div className="Frame313326">
                            <div className="Frame313266">
                                <div className="Frame313263">
                                    <div className="LoginWithYourCredentials">PRE-ENROLLMENT FORM</div>
                                </div>
                                <div className={`Error ${errorMessage ? "" : "hidden"}`}>
                                    <div className="EmailInvalidSection">{errorMessage}</div>
                                </div>
                                <ContainerLoader isLoading={isLoading} isLoadingOverlay={true} addBlurFilters={true}>
                                    <div className="Frame313260 Frame3132622">
                                        <SingleField
                                            title="Brand"
                                            isDisabled={false}
                                            type="text"
                                            value={brandName}
                                            onChange={e => setBrandName(e.target.value)}
                                            isInvalid={brandIsInvalid}
                                            errorMessage="Brand name is required."
                                            isRequiredAsterisk={true}
                                        />

                                        <SingleField
                                            title="Website"
                                            type="text"
                                            value={website}
                                            onChange={e => setWebsite(e.target.value)}
                                            isInvalid={websiteIsInvalid}
                                            errorMessage="Website is required."
                                            prefixLabel="https://"
                                            isRequiredAsterisk={true}
                                        />

                                        <SingleField
                                            title="Instagram"
                                            type="text"
                                            value={instagram}
                                            onChange={e => setInstagram(e.target.value)}
                                            isInvalid={instagramIsInvalid}
                                            errorMessage="Instagram is required."
                                            prefixLabel="https://www.instagram.com/"
                                            limit={30}
                                        />

                                        <SingleField
                                            title="Company"
                                            type="text"
                                            value={company}
                                            onChange={e => setCompany(e.target.value)}
                                            isInvalid={companyIsInvalid}
                                            errorMessage="Company is required."
                                            isRequiredAsterisk={true}
                                        />

                                        <div style={flexMobileContainer}>
                                            <div
                                                className={`InputField w-100 ${countryIsInvalid ? "error" : ""}`}
                                                style={{ gap: "0" }}>
                                                <div
                                                    className="flex flex-row w-100 cursor-pointer"
                                                    style={{ position: "relative" }}>
                                                    <SingleField
                                                        title="Country"
                                                        type="text"
                                                        value={country?.value}
                                                        onChange={e => setCountry(e.target.value)}
                                                        isInvalid={countryIsInvalid}
                                                        isLabel={true}
                                                        showDropdownIcon={true}
                                                        errorMessage="Country is required."
                                                        isRequiredAsterisk={true}
                                                        onClick={() => {
                                                            setShowCountryDropdown(!showCountryDropdown);
                                                        }}
                                                    />
                                                    {showCountryDropdown && (
                                                        <DropdownOverlay
                                                            isSearchable={true}
                                                            data={countryOptionsData}
                                                            onSingleItemClick={singleItemData => {
                                                                setCountry({
                                                                    value: singleItemData?.value,
                                                                    countryFromDropdown: true
                                                                });
                                                                setShowCountryDropdown(false);
                                                            }}
                                                            isChangeColor={true}
                                                            overlayType={OverlayType?.UNI_SELECT}
                                                            style={{
                                                                width: "100%",
                                                                top: "56px",
                                                                zIndex: 1000,
                                                                position: "absolute"
                                                            }}
                                                        />
                                                    )}
                                                </div>

                                                {countryIsInvalid && (
                                                    <div className="EmailInvalidSection">{errorMessage}</div>
                                                )}
                                            </div>

                                            <SingleField
                                                title="Registration nr"
                                                type="text"
                                                value={registrationNr}
                                                onChange={e => setRegistrationNr(e.target.value)}
                                                isInvalid={registrationNrIsInvalid}
                                                errorMessage="Registration nr is required."
                                                limit={20}
                                            />
                                        </div>

                                        <div style={flexMobileContainer}>
                                            <SingleField
                                                title="Your first name"
                                                type="text"
                                                value={firstName}
                                                onChange={e => setFirstName(e.target.value)}
                                                isInvalid={firstNameIsInvalid}
                                                errorMessage="First name is required."
                                                limit={30}
                                                isRequiredAsterisk={true}
                                            />

                                            <SingleField
                                                title="Your last name"
                                                type="text"
                                                value={lastName}
                                                onChange={e => setLastName(e.target.value)}
                                                isInvalid={lastNameIsInvalid}
                                                errorMessage="Last name is required."
                                                limit={30}
                                                isRequiredAsterisk={true}
                                            />
                                        </div>

                                        <SingleField
                                            title="Your email"
                                            type="text"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            isInvalid={emailIsInvalid}
                                            errorMessage="Email is required."
                                            isRequiredAsterisk={true}
                                        />

                                        <div className={`Frame313261 ${isLoading ? "loading-filters" : ""}`}>
                                            <div className="mandatory-fields">
                                                <span className="mandatory-fields-span text-orange-500">*</span>
                                                <span className="mandatory-fields-span2 text-orange-500">
                                                    mandatory fields
                                                </span>
                                            </div>
                                            <div className="mandatory-fields">
                                                <span className="mandatory-fields-span2">
                                                    Problems pre-enrolling? Let us know:{" "}
                                                </span>
                                                <span className="mandatory-fields-span2 text-white">
                                                    support@botag.no
                                                </span>
                                            </div>
                                        </div>
                                        <BtnLargeSolid
                                            className={`w-100 ${isLoading ? "loading-filters" : ""}`}
                                            disabled={isLoading || buttonDisable}
                                            useBOstageColors={false}
                                            onClick={handleSubmit}
                                            onKeyUp={handleKeyPress}
                                            textStyle={{ fontSize: "0.75rem" }}
                                            style={{ height: "45px" }}>
                                            CREATE ACCOUNT
                                        </BtnLargeSolid>
                                        <div
                                            className={`loginTermsPrivacyPolicies ${
                                                isLoading ? "loading-filters" : ""
                                            }`}>
                                            <span style={{ fontSize: "11px" }}>
                                                By continuing, you agree to BOtag Norge AS{" "}
                                            </span>
                                            <span
                                                className="underline cursor-pointer"
                                                onClick={() => {
                                                    setShowTAC(true);
                                                }}>
                                                Terms of Use
                                            </span>
                                            <span> and </span>
                                            <span
                                                className="underline cursor-pointer"
                                                onClick={() => {
                                                    setShowPrivacy(true);
                                                }}>
                                                Privacy Policy
                                            </span>
                                            <span>.</span>
                                        </div>
                                    </div>
                                </ContainerLoader>
                            </div>
                        </div>
                    </>
                ) : (
                    <StyledThankYou className="frame-313266">
                        <img className="image-1" src={footerlogo} alt="BOStageR" />
                        <div className="line-4"></div>
                        <div className="frame-259">
                            <div className="frame-286">
                                <div className="thank-you-for-pre-enrolling-on-b-otag">
                                    Thank you for
                                    <br />
                                    pre-enrolling on BOtag
                                </div>
                            </div>
                            <div className="frame-285">
                                <div className="frame-160">
                                    <div className="please-check-your-email-for-next-steps">
                                        Please check your email for next steps.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </StyledThankYou>
                )}
            </div>
            {isMobile && (
                <div
                    className="frame-313865"
                    style={{ marginTop: "19px", marginBottom: "19px", gap: "2px", height: "37px" }}>
                    <div className="frame-313866">
                        <div className="terms-conditions cursor-pointer" onClick={() => setShowTAC(true)}>
                            Terms &amp; Conditions
                        </div>
                        <div className="privacy-security cursor-pointer" onClick={() => setShowPrivacy(true)}>
                            Privacy &amp; Security
                        </div>
                    </div>
                    <div className="b-otag-norge-as-2024">BOtag Norge AS © {moment().format("yyyy")}</div>
                    <div className="app-version-1-2-0">App Version {packageJson.version}</div>
                </div>
            )}
        </div>
    );
};

const SingleField = ({
    title,
    type,
    value,
    onChange,
    onKeyUp,
    isInvalid,
    isRequiredAsterisk = false,
    errorMessage,
    isDisabled = false,
    limit = 40,
    prefixLabel,
    onClick,
    isLabel = false,
    showDropdownIcon = false
}) => {
    return (
        <div className={`InputField w-100 ${isInvalid ? "error" : ""}`} onClick={onClick}>
            <div className="EmailAddress">
                {title} {isRequiredAsterisk ? <span className="required-asterisk">*</span> : ""}
            </div>
            <div className="flex flex-row w-100">
                {prefixLabel && <div className="PrefixLabel">{prefixLabel}</div>}
                <Field
                    isLabel={isLabel}
                    type={type}
                    value={value}
                    onChange={onChange}
                    onKeyUp={onKeyUp}
                    disabled={isDisabled}
                    className="Frame313324"
                    style={{
                        borderColor: isInvalid ? "red" : "",
                        color: isInvalid ? "red" : isDisabled ? "#7c868b" : ""
                    }}
                    maxLength={limit}
                />
                {showDropdownIcon && (
                    <div className="droppedRemove" style={{ height: "14px" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M3.52864 6.02864C3.78899 5.76829 4.2111 5.76829 4.47145 6.02864L8.00004 9.55723L11.5286 6.02864C11.789 5.76829 12.2111 5.76829 12.4714 6.02864C12.7318 6.28899 12.7318 6.7111 12.4714 6.97145L8.47145 10.9714C8.2111 11.2318 7.78899 11.2318 7.52864 10.9714L3.52864 6.97145C3.26829 6.7111 3.26829 6.28899 3.52864 6.02864Z"
                                fill="#D1D1D1"
                            />
                        </svg>
                    </div>
                )}
            </div>
            <div className="Line2" style={{ marginTop: "6px" }} />
            {isInvalid && <div className="EmailInvalidSection">{errorMessage}</div>}
        </div>
    );
};

const Field = props => {
    return !props.isLabel ? <input {...props} /> : <label {...props}>{props.value}</label>;
};

/* #endregion */
