import styled from "styled-components";
import MagnifierIcon from "../../../assets/icons/magnifierIcon.png";
import { useEffect, useRef, useState } from "react";
import { ReplaceStringWithColor } from "../forms/ControlbarSearch";
import Checkbox from "../checkbox/Checkbox";
import check0 from "../../../assets/icons/globalDropdownCheckbox.png";

//TODO: SHOULD WE RENAME THIS CONST TO "DropdownTypes"
export const OverlayType = {
    MULTI_SELECT: "MULTI_SELECT",
    UNI_SELECT: "UNI_SELECT"
};

/* #region styles */
const DropdownOverlayStyles = styled.div`
    & {
        position: relative;
        width: 100%;
        height: 0px;
    }

    .property-1-variant-3 {
        background: var(--backgrounds-and-lines-n-500, #141922);
        border-radius: 4px;
        padding: 12px 8px 12px 8px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        position: relative;
        box-shadow: var(--drop-shadow-100-box-shadow, 0px 0px 10px 0px rgba(17, 22, 32, 1));
    }
    .frame-74 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: absolute;
        width: 100%;
    }
    .frame-76 {
        border-radius: 4px;

        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        width: 100%;
    }
    .small-components-holder {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        flex: 1;
    }
    .uniandmultiselect-container {
        max-height: 180px; //will be changed
        overflow: auto;
        margin-top: 10px;
    }
    .uniandmultiselect-container::-webkit-scrollbar {
        width: 3px;
    }

    .uniandmultiselect-container::-webkit-scrollbar-thumb {
        background-color: #3c485a;
        border-radius: 10px;
    }

    .uniandmultiselect-container::-webkit-scrollbar-track {
        background: transparent;
        box-shadow: none;
    }

    .uniandmultiselect-container::-webkit-scrollbar-corner {
        display: none;
    }
    .nothing-to-show-container {
        color: var(--Buttons-All-Platforms-Gray-100);
        font-size: 12px;
        font-weight: 400;
        margin: 5px 0px;
    }
`;

const SearchComponentStyles = styled.div`
    .frame-48 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 4px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px;
        padding: 6px 8px 6px 8px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .search-sm {
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        position: relative;
        overflow: visible;
    }
    .search-list {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .search-input {
        border: none;
        outline: none;
        background-color: transparent;
        font-size: 12px;
        font-weight: 400;
        color: #4f5b6d; //this will change
        width: 100%;
    }
`;
const Selector = styled.div`
    .frame-75 {
        background: var(--backgrounds-and-lines-n-500, #141922);
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        margin-right: 4px;
    }
    .affiliated-color {
        color: var(--text-icons-gray-300, #4f5b6d);
    }
    .dropdown-text {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        flex: 1;
        padding: 8px;
        border-radius: 4px;
    }
    .dropdown-text:hover {
        background-color: #1e2631;
    }
    .replay-ss-001-span {
        color: var(--text-and-icons-green-500, #00bb00);
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
    }
`;
/* #endregion */

/** DropdownOverlay component that supports multi-select and uni-select.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {boolean} [props.isSearchable=true] - Whether the dropdown should include a search bar.
 * @param {string} [props.overlayType=OverlayType.UNI_SELECT] - Type of overlay, can be "MULTI_SELECT" and "UNI_SELECT".
 * @param {Array} [props.data=[]] - Array of items to display in the dropdown. Each item should have `value` and `name` properties.
 * @param {Function} [props.onSingleItemClick=() => {}] - Function to handle clicking a single item.
 * @param {boolean} [props.isChangeColor=false] - Whether to change the color of the matching text in search results.
 * @param {Function} [props.multiSelectData=() => {}] - Function to return selected items in multi-select mode.
 * @returns {JSX.Element} The rendered DropdownOverlay component.
 *
 * @example //!IMPORTANT - Data should be always like this:
 * const testArr = [{value: "value", name: "name", selected: true}];
 *
 * <DropdownOverlay
 *      isSearchable={true}
 *      data={getDropdownData("countryOptions")}
 *      onSingleItemClick={singleItemData => {
 *          console.log(singleItemData); //this is single Item data when we click on a single Item
 *      }}
 *      isChangeColor={true}
 *      overlayType={OverlayType?.MULTI_SELECT}
 *      multiSelectData={data => {
 *         console.log(data); //this is data with the selected options with checkbox
 *      }}
 * />
 */
const DropdownOverlay = ({
    isSearchable = true,
    overlayType = OverlayType.UNI_SELECT,
    data = "",
    isVisible = true,
    onSingleItemClick = () => {},
    isChangeColor = false,
    multiSelectData = () => {},
    style
}) => {
    /* #region states */
    const searchInputRef = useRef(null);

    const [searchedData, setSearchedData] = useState(data);
    const [searchedValue, setSearchedValue] = useState("");
    const [checkedItems, setCheckedItems] = useState({});
    /* #endregion */

    /* #region effects */
    useEffect(() => {
        setSearchedData(data);
    }, [data]);

    useEffect(() => {
        if (overlayType === OverlayType.MULTI_SELECT) {
            const selectedItems = data?.filter(item => item.selected);
            //console.log("selectedItems", selectedItems);
            setCheckedItems(
                selectedItems?.reduce((acc, item) => {
                    acc[item.value] = true;
                    return acc;
                }, {})
            );
        }
    }, []);

    useEffect(() => {
        if (overlayType === OverlayType.MULTI_SELECT) {
            const selectedData = data?.map(item => {
                const selected = checkedItems[item.value] ?? false;

                return {
                    ...item,
                    selected
                };
            });

            if (multiSelectData) multiSelectData(selectedData);
            //console.log("checkedItems", selectedData);
        }
    }, [checkedItems]);

    useEffect(() => {
        if (isSearchable && searchInputRef.current && isVisible) {
            searchInputRef.current.focus();
        }
    }, [isSearchable, isVisible]);
    /* #endregion */

    /* #region methods */
    const onChange = e => {
        const searchedValue = e?.target?.value?.toLowerCase();
        setSearchedValue(searchedValue);
        const filteredData = data?.filter(item => {
            return item?.name?.toLowerCase()?.includes(searchedValue);
        });
        setSearchedData(filteredData);
    };
    const handleCheckboxClick = item => {
        setCheckedItems(prevState => ({
            ...prevState,
            [item.value]: !prevState[item.value]
        }));
    };
    /* #endregion */

    return (
        <DropdownOverlayStyles style={{ ...style, display: isVisible ? "block" : "none" }}>
            <div className="frame-74">
                <div className="property-1-variant-3">
                    <div className="frame-76">
                        <div className="small-components-holder">
                            {isSearchable ? <SearchComponent onChange={onChange} inputRef={searchInputRef} /> : null}
                            <div className="uniandmultiselect-container">
                                {searchedData?.length ? (
                                    searchedData?.map(item => (
                                        <UniAndMultiSelect
                                            key={item.value}
                                            overlayType={overlayType}
                                            isAffiliated={false}
                                            onSingleItemClick={onSingleItemClick}
                                            item={item}
                                            searchedValue={searchedValue}
                                            isChecked={checkedItems[item.value] || false}
                                            onCheckboxClick={() => handleCheckboxClick(item)}
                                            isChangeColor={isChangeColor}
                                        />
                                    ))
                                ) : (
                                    <div className="nothing-to-show-container">There’s nothing to show here...</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DropdownOverlayStyles>
    );
};

export default DropdownOverlay;

/* #region small components */
const SearchComponent = ({ onChange = () => {}, inputRef }) => {
    return (
        <SearchComponentStyles>
            <div className="frame-48">
                <img className="search-sm" src={MagnifierIcon} alt="Search Icon" />
                <input
                    type="text"
                    className="search-input"
                    placeholder="Search List"
                    onChange={onChange}
                    ref={inputRef}
                />
            </div>
        </SearchComponentStyles>
    );
};

const UniAndMultiSelect = ({
    overlayType = OverlayType.UNI_SELECT,
    isAffiliated = false,
    onSingleItemClick = () => {},
    item = {},
    searchedValue = "",
    isChecked = false,
    onCheckboxClick = () => {},
    isChangeColor = false
}) => {
    return (
        <Selector>
            <div className="frame-75">
                <div
                    className={`dropdown-text ${isAffiliated ? "hover-not-allowed" : "cursor-pointer"}`}
                    onClick={() => {
                        onSingleItemClick(item);
                        if (overlayType === OverlayType.MULTI_SELECT) {
                            onCheckboxClick();
                        }
                    }}>
                    <div className={`align-items-center gap-8px ${isAffiliated ? "affiliated-color" : ""}`}>
                        {overlayType === OverlayType.MULTI_SELECT && !isAffiliated && (
                            <Checkbox isChecked={isChecked} onCheckboxClick={() => {}} uncheckedSrc={check0} /> //in our case oncheckbox click is handled in the above onclick
                        )}
                        <div className={`flex justify-space-between w-100 ${isAffiliated ? "affiliated-color" : ""}`}>
                            {isChangeColor ? (
                                <ReplaceStringWithColor searchText={searchedValue} text={item?.name} />
                            ) : (
                                item?.name
                            )}
                            {isAffiliated ? <i>Affiliated</i> : null}
                        </div>
                    </div>
                </div>
            </div>
        </Selector>
    );
};
/* #endregion */
