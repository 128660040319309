import OrdersPage from "../components/pages/orders/OrdersPage";

const routes = [
    {
        path: "/orders",
        element: <OrdersPage />
    }
];

export default routes;
