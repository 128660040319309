import Chart from "react-apexcharts";

const TotalSalesGraphComponent = ({ type, height, options, series }) => {
    return (
        <div>
            <Chart type={type} height={height} options={options} series={series} />
        </div>
    );
};

export default TotalSalesGraphComponent;
