import React, { useEffect } from "react";
import AppContext from "../../../AppContext";
import ProductCardSidePanelUI from "./ProductCardSidePanelUI";

/**
 * Renders a side panel for a product card.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.productID - The product ID from the sidePanelProducts state in AppContext.
 * @param {Object} props.number - The BO tag number.
 * @param {Object} props.selectedColor - The selected color.
 * @param {Object} props.selectedSize - The selected size.
 * @param {boolean} props.isSelected - The flag to indicate if the product is selected.
 * @returns {JSX.Element} The rendered side panel component.
 */
export default function ProductCardLinkingSidePanel(props) {
    /* #region USE STATES */
    const [productID, setProductID] = React.useState(undefined);
    const [product, setProduct] = React.useState(undefined);
    const [isSelected, setIsSelected] = React.useState(false);

    const {
        setSidePanelBOtagProductLink,
        sidePanelProducts,
        sidePanelSelectedProductID,
        setSidePanelSelectedProductID,
        sidePanelSelectedProductNumber
    } = React.useContext(AppContext);
    /* #endregion */

    /* #region USE EFFECTS */
    // UPDATES THE product AND productID INTERNAL STATES, when the productID prop changes (OK)
    useEffect(() => {
        if (!props?.productID) return;
        const tmpProduct = sidePanelProducts?.find(product => product.productID === props?.productID);
        setProduct(tmpProduct ?? {});
        setProductID(props?.productID);
    }, [props?.productID]);

    // UPDATES THE product INTERNAL STATE, when the sidePanelProducts state changes
    useEffect(() => {
        if (!productID) return;
        const tmpProduct = sidePanelProducts.find(product => product.productID === productID);
        setProduct(tmpProduct);
    }, [sidePanelProducts]);

    // HANDLES LINK ENABLED STATE FOR THE LINK BUTTON, when the selectedColor or selectedSize changes
    useEffect(() => {
        if (props?.selectedColor) setProduct({ ...product, selectedColor: props?.selectedColor });
        if (props?.selectedSize) setProduct({ ...product, selectedSize: props?.selectedSize });
    }, [props?.selectedColor, props?.selectedSize]);

    // HANDLES SET TO UNSELECTED, when the sidePanelSelectedProductID is different than the productID
    useEffect(() => {
        if (!isSelected) return;
        if (sidePanelSelectedProductID !== productID) handleSetUnselected();
    }, [sidePanelSelectedProductID]);

    // HANDLES SET TO UNSELECTED, when the sidePanelSelectedProductNumber is changed to undefined
    useEffect(() => {
        if (sidePanelSelectedProductNumber) return;
        if (isSelected && sidePanelSelectedProductNumber === undefined) handleSetUnselected();
    }, [sidePanelSelectedProductNumber]);
    /* #endregion */

    /* #region METHODS */
    const handleColorChange = color => {
        if (!sidePanelSelectedProductNumber) return;
        handleSetSelected();

        if (product.selectedColor === color) {
            setProduct({ ...product, selectedColor: "" });
            return;
        }

        setProduct({ ...product, selectedColor: color });
    };

    const handleSizeChange = size => {
        if (!sidePanelSelectedProductNumber) return;
        handleSetSelected();

        if (product.selectedSize === size) {
            setProduct({ ...product, selectedSize: "" });
            return;
        }

        setProduct({ ...product, selectedSize: size });
    };

    const handleSetSelected = () => {
        setIsSelected(true);
        setSidePanelSelectedProductID(productID);
    };

    const handleSetUnselected = () => {
        setIsSelected(false);
        setProduct({ ...product, selectedColor: "", selectedSize: "" });
    };

    const handleTagLink = () => {
        if (!sidePanelSelectedProductNumber || !product?.selectedColor || !product?.selectedSize) return;

        const productWithTag = {
            ...product,
            tagNumber: sidePanelSelectedProductNumber,
            image: product?.image,
            category: product?.category,
            productName: product?.productName,
            colors: product?.colors,
            sizes: product?.sizes
        };
        setSidePanelBOtagProductLink(productWithTag);

        // Reset the product color and size
        handleSetUnselected();
    };

    const allowChanges = () => {
        return isSelected;
    };
    /* #endregion */

    return (
        <ProductCardSidePanelUI
            image={product?.image}
            category={product?.category}
            number={sidePanelSelectedProductNumber}
            productName={product?.productName}
            colors={product?.colors}
            selectedColor={product?.selectedColor}
            onColorChange={handleColorChange}
            sizes={product?.sizes}
            selectedSize={product?.selectedSize}
            onSizeChange={handleSizeChange}
            buttonText="Link"
            onButtonClick={handleTagLink}
            isCompletedCard={false}
            allowChanges={allowChanges()}
        />
    );
}
