import "../../../assets/css/components/AnalyticsGraph.css";
import AnalyticsGraphComponent from "../../pages/dashboardGraphs/AnalyticsGraphComponent";
import { visitorChartOptions } from "../../../utils/utilHelpers";

const AnalyticsGraph = ({ chartData }) => {
    return (
        <div className="analytics-main-container">
            <div className="analytics-header">
                <p>
                    BOTAG ANALYTICS &nbsp; <i className="fa-light fa-circle-info"></i>
                </p>
                <div className="headers-data">
                    <p>
                        <i style={{ color: "#49B6D6", fontSize: "8px" }} className="fa-sharp fa-solid fa-circle"></i>
                        &nbsp;BOtag sales
                    </p>
                    <p>
                        <i style={{ color: "#348FE2", fontSize: "8px" }} className="fa-sharp fa-solid fa-circle"></i>
                        &nbsp;Overall sales
                    </p>
                </div>
            </div>
            <div className="analytics-data-container">
                <div>
                    <h2 style={{ marginBottom: "10px" }}>{Math.ceil(Math.random() * 355)}</h2>
                    <p style={{ marginBottom: "4px" }}>ACTIVE BOIMAGES</p>
                    <p>--% compared to set period</p>
                </div>
                <div>
                    <h2 style={{ marginBottom: "10px" }}>{(Math.random() * 50).toFixed(3)}</h2>
                    <p style={{ marginBottom: "4px" }}>CLIICKS AND SCAN</p>
                    <p>--% compared to set period</p>
                </div>
                <div>
                    <h2 style={{ marginBottom: "10px" }}>{(Math.random() * 50).toFixed(3)}</h2>
                    <p style={{ marginBottom: "4px" }}>CONVERTED INTO SALES</p>
                    <p>--% compared to set period</p>
                </div>
                <div>
                    <h2 style={{ marginBottom: "10px" }}>{Math.ceil(Math.random() * 100)}%</h2>
                    <p style={{ marginBottom: "4px" }}>CONVERSION RATE</p>
                    <p>--% compared to set period</p>
                </div>
            </div>
            <div className="analytics-hr-container">
                <div className="analytics-hr"></div>
            </div>

            <div>
                <AnalyticsGraphComponent
                    type="area"
                    height="254"
                    width="100%"
                    options={visitorChartOptions}
                    series={chartData}
                />
            </div>
        </div>
    );
};

export default AnalyticsGraph;
