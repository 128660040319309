import ProductPage from "../components/pages/products/ProductPage";

const routes = [
    {
        path: "/products",
        element: <ProductPage />
    }
];

export default routes;
