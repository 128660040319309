import React from "react";
import styled from "styled-components";
import GridCardComponent from "./generic/GridCardComponent";
import { defaultStatusColors, getSpanWithColor } from "../../../utils/CardUtils";
import { formatDefaultDateTime } from "../../../utils/Formatter";

/* #region  SCOPED CSS */
const StyledPage = styled.div``;
/* #endregion */

/**
 * Renders a grid card component for displaying user information.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.className - The CSS class name for the component.
 * @param {Object} props.primaryButton - The primary button configuration.
 * @param {string} props.primaryButton.title - The title of the primary button.
 * @param {string} props.title - The title of the grid card.
 * @param {string} props.id - The ID of the grid card.
 * @param {boolean} props.hasBorders - Indicates whether the card has borders.
 * @param {string} props.titlePrefix - The prefix for the title.
 * @param {string} props.idPrefix - The prefix for the ID.
 * @param {boolean} props.isCardEnabled - Indicates whether the card is enabled.
 * @param {boolean} props.isCardSuspended - Indicates if the card is suspended.
 * @param {string} props.image - The image URL for the card.
 * @param {Object[]} props.details - The array of key-value pairs for displaying additional details.
 * @param {string} props.details[].key - The key of the detail.
 * @param {string} props.details[].value - The value of the detail.
 * @param {Object} props.data - The user data object.
 * @param {string} props.data.role - The role of the user.
 * @param {string} props.data.currentStatus - The current status of the user.
 * @param {string} props.data.createdBy - The creator of the user.
 * @param {string} props.data.createdOn - The creation date of the user.
 * @param {string} props.defaultSecondTitle - The default second title in the details
 * @returns {JSX.Element} The rendered UsersGridCard component.
 */
export default function UsersGridCard(props) {
    const status = props?.data?.currentStatus || "--";

    const secondKey = props?.data?.labelBrandName
        ? { key: "Brand:", value: props?.data?.labelBrandName }
        : props?.data?.companyName
        ? { key: "Company:", value: props?.data?.companyName || "--" }
        : props?.data?.organizationName
        ? { key: "Account:", value: props?.data?.organizationName || "--" }
        : { key: props?.defaultSecondTitle ?? "Account:", value: "--" };

    return (
        <StyledPage className={`${props?.className || ""}`}>
            <GridCardComponent
                primaryButton={props?.primaryButton ? props?.primaryButton : { title: "Details" }}
                title={props?.title}
                id={props?.id}
                hasBorders={props?.hasBorders}
                titlePrefix={props?.titlePrefix || "USER"}
                idPrefix={props?.idPrefix || "BOTAG ID"}
                isCardEnabled={props?.isCardEnabled}
                isCardSuspended={props?.isCardSuspended}
                image={props?.image}
                imageFit="cover"
                details={[
                    { key: "Role:", value: props?.data?.role || "--" },
                    secondKey,
                    { key: "Enrolled on:", value: formatDefaultDateTime(props?.data?.enrolledOn) },
                    { key: "Current status:", value: getSpanWithColor(status ?? "--", defaultStatusColors) }
                ]}
            />
        </StyledPage>
    );
}
