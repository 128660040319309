import { useEffect, useState } from "react";
import GrossMargin from "../dashboardComponents/GrossMargin";
import TotalBotagSales from "../dashboardComponents/TotalBotagSales";
import AnalyticsGraph from "../dashboardComponents/AnalyticsGraph";
import TotalSales from "../dashboardComponents/TotalSales";
import BotagGrossMargin from "../dashboardComponents/BotagGrossMargin";
import SalesByBrandAndStore from "../dashboardComponents/SalesByBrandAndStore";
import UnitSold from "../dashboardComponents/UnitSold";
import DashboardHeader from "../dashboardComponents/DashboardHeader";
import MapComponent from "../dashboardComponents/MapComponent";
import replayBrand from "../../../assets/images/replay-brand.png";
import TheProduct from "../../../assets/images/TheProduct.png";
import Gant from "../../../assets/images/Gant.png";
import Samsoe from "../../../assets/images/Samsoe.png";
import Norrona from "../../../assets/images/Norrona.png";
import follestad from "../../../assets/images/follestad.png";
import hoyer from "../../../assets/images/hoyer.png";
import { useSelector } from "react-redux";
import NDAPopup from "../dashboardComponents/NDAPopup";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import { visitorChartSeriesA, visitorChartSeriesB, visitorChartSeriesC } from "../../../utils/utilHelpers";
import { totalSalesChartSeriesA, totalSalesChartSeriesB, totalSalesChartSeriesC } from "../../../utils/utilHelpers";
import { GrossMarginChartSeriesA, GrossMarginChartSeriesB, GrossMarginChartSeriesC } from "../../../utils/utilHelpers";
import { useOutletContext } from "react-router";

const DashboardPage = () => {
    const [visitorChartSeries, setVistorChartSeries] = useState([]);
    const [totalSalesChartSeriesData, setTotalSalesChartSeriesData] = useState([]);
    const [GrossMarginChartSeries, setGrossMarginChartSeries] = useState([]);
    const userAppData = useSelector(state => state.userData.userAppData);
    const [showNda, setShowNda] = useState(false);

    const BOstagesAccountID = userAppData?.BOStagesAccount?._id;

    const { MainLayoutProps, layoutType } = useOutletContext();
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };

    useEffect(() => {
        if (userAppData?.NDAaccepted === false) {
            setShowNda(true);
        }
    }, [userAppData]);

    const changeData = () => {
        const data = [visitorChartSeriesA, visitorChartSeriesB, visitorChartSeriesC];
        const totalSalesData = [totalSalesChartSeriesA, totalSalesChartSeriesB, totalSalesChartSeriesC];
        const grossMarginData = [GrossMarginChartSeriesA, GrossMarginChartSeriesB, GrossMarginChartSeriesC];

        const visitorChartSeries = data[Math.floor(Math.random() * 3)];
        const totalSalesChartSeries = totalSalesData[Math.floor(Math.random() * 3)];
        const GrossMarginChartSeries = grossMarginData[Math.floor(Math.random() * 3)];

        setVistorChartSeries(visitorChartSeries);
        setTotalSalesChartSeriesData(totalSalesChartSeries);
        setGrossMarginChartSeries(GrossMarginChartSeries);
    };

    useEffect(() => {
        changeData();
    }, []);

    useEffect(() => {
        if (!props?.layoutType) return;
        setupHeaders(props);
    }, [props?.layoutType]);

    const store = [
        {
            image: replayBrand,
            price: "13,936.07",
            name: "Replay"
        },
        {
            image: TheProduct,
            price: "13,936.07",
            name: "The Product"
        },
        {
            image: Gant,
            price: "5,001.07",
            name: "Gant"
        },
        {
            image: Samsoe,
            price: "$1,919.68",
            name: "Samsøe Samsøe"
        },
        {
            image: Norrona,
            price: "$1,362.34",
            name: "Norrona"
        }
    ];
    const brand = [
        {
            image: follestad,
            price: "13,936.07",
            name: "Follestad Hamar"
        },
        {
            image: hoyer,
            price: "13,936.07",
            name: "Høyer Paleet"
        },
        {
            image: replayBrand,
            price: "5,001.07",
            name: "Replay Karl Johann"
        },
        {
            image: Gant,
            price: "13,936.07",
            name: "Gant Bogstadvein"
        },
        {
            image: replayBrand,
            price: "$1,362.34",
            name: "Replay Bergen"
        }
    ];

    return (
        <div>
            {showNda && (
                <FullScreenOverlay
                    children={<NDAPopup BOstagesAccountID={BOstagesAccountID} setShowNda={setShowNda} />}
                />
            )}
            <div>
                <DashboardHeader onClick={changeData} />
            </div>
            <div className="total-sale-chart">
                <TotalSales totalSalesChartSeries={totalSalesChartSeriesData} />
                <GrossMargin GrossMarginChartSeries={GrossMarginChartSeries} />
                <TotalBotagSales totalSalesChartSeries={totalSalesChartSeriesData} />
                <BotagGrossMargin GrossMarginChartSeries={GrossMarginChartSeries} />
            </div>
            <div className="graph-container-two">
                <AnalyticsGraph chartData={visitorChartSeries} />
                <MapComponent />
            </div>
            <div className="three-component-container">
                <SalesByBrandAndStore
                    type={"BRAND"}
                    brandName={"Replay"}
                    totalPrice={(Math.random() * 9).toFixed(5)}
                    data={store}
                />
                <UnitSold />
                <SalesByBrandAndStore
                    type={"STORE"}
                    brandName={"Follestad Hamar"}
                    totalPrice={(Math.random() * 9).toFixed(5)}
                    data={brand}
                />
            </div>
        </div>
    );
};

export default DashboardPage;
function setupHeaders(props) {
    props.setControlBarLeftBtns([]);
    props.setControlBarCenterBtns([{ to: "#", active: true, disabled: false, title: "DASHBOARD" }]);
    props.setControlBarRightBtns([]);

    props.setFilterHeaderProperties({
        visible: false,
        gridEnabled: false,
        listEnabled: false,
        onRefreshAction: undefined
    });
}
