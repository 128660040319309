import Announcement01 from "@untitled-ui/icons-react/build/esm/Announcement01";
import Box from "@untitled-ui/icons-react/build/esm/Box";
import Building07 from "@untitled-ui/icons-react/build/esm/Building07";
import Speedometer04 from "@untitled-ui/icons-react/build/esm/Speedometer04";
import Tag03 from "@untitled-ui/icons-react/build/esm/Tag03";
import Dataflow01 from "@untitled-ui/icons-react/build/esm/Dataflow01";
import UserSquare from "@untitled-ui/icons-react/build/esm/UserSquare";
import { ReactComponent as FFCIcon } from "../assets/icons/ffcenter-icon.svg";
import { ReactComponent as Shop1 } from "../assets/icons/shop-1.svg";
import { ReactComponent as Shirt } from "../assets/icons/tshirt-linear.svg";
import { ReactComponent as id } from "../assets/icons/id.svg";

export const navigationBar = path => {
    //test
    const hasSubscriptionPlan = ![null, undefined, "", "undefined", "null", "-"].includes(
        localStorage.getItem("subscriptionPlanID")
    );
    const hasOrganizationName = ![null, undefined, "", "undefined", "null", "-"].includes(
        localStorage.getItem("currentOrganizationName")
    );

    return [
        {
            to: "/dashboard",
            enabled: true && hasSubscriptionPlan && hasOrganizationName,
            title: "Dashboard",
            iconSVG: Speedometer04,
            isActive: path.toLocaleLowerCase().includes("/dashboard")
        },
        {
            to: "/boimages",
            enabled: true && hasSubscriptionPlan && hasOrganizationName,
            title: "BOimages",
            iconSVG: UserSquare,
            isActive: path.toLocaleLowerCase().includes("/boimages")
        },
        {
            to: "/ffcenters",
            enabled: true && hasSubscriptionPlan && hasOrganizationName,
            title: "Fulfillment Centers",
            iconSVG: FFCIcon,
            isActive: path.toLocaleLowerCase().includes("/ffcenters")
        },
        {
            to: "/stores",
            enabled: true && hasSubscriptionPlan && hasOrganizationName,
            title: "Stores",
            iconSVG: Shop1,
            isActive: path.toLocaleLowerCase().includes("/stores")
        },
        {
            to: "/retailers",
            enabled: false,
            title: "Retailers",
            iconSVG: Dataflow01,
            isActive: path.toLocaleLowerCase().includes("/retailers")
        },
        {
            to: "/orders",
            enabled: true && hasSubscriptionPlan && hasOrganizationName,
            title: "Orders",
            iconSVG: Box,
            isActive: path.toLocaleLowerCase().includes("/orders")
        },
        {
            to: "/products",
            enabled: true && hasSubscriptionPlan && hasOrganizationName,
            title: "Products",
            iconSVG: Shirt,
            isActive: path.toLocaleLowerCase().includes("/products")
        },
        {
            to: "/brands",
            enabled: true && hasSubscriptionPlan && hasOrganizationName,
            title: "Brands",
            iconSVG: Tag03,
            isActive: path.toLocaleLowerCase().includes("/brands")
        },

        {
            to: "/promoters",
            enabled: false,
            title: "Promoters",
            iconSVG: Announcement01,
            isActive: path.toLocaleLowerCase().includes("/promoters")
        },
        {
            to: "/companies",
            enabled: true && hasSubscriptionPlan && hasOrganizationName,
            title: "Companies",
            iconSVG: Building07,
            isActive: path.toLocaleLowerCase().includes("/companies")
        },
        {
            to: "/account",
            enabled: true && hasSubscriptionPlan,
            title: "Account",
            iconSVG: id,
            isActive: path.toLocaleLowerCase().includes("/account")
        }
    ];
};
