import React from "react";
import styled from "styled-components";
import GridCardComponent from "./generic/GridCardComponent";
import { defaultStatusColors, getSpanWithColor } from "../../../utils/CardUtils";
import { formatDefaultDateTime } from "../../../utils/Formatter";

/* #region  SCOPED CSS */
const StyledPage = styled.div``;
/* #endregion */

/**
 * FFCGridCard component displays a grid card with specific data.
 *
 * @component
 * @param {Object} props - The props object containing the component's properties.
 * @param {string} props.className - The CSS class name for the component.
 * @param {string} props.title - The title of the grid card.
 * @param {string} props.id - The ID of the grid card.
 * @param {boolean} props.hasBorders - Indicates if the card has borders.
 * @param {string} [props.titlePrefix="FFCENTER"] - The prefix for the title.
 * @param {string} [props.idPrefix="BOTAG ID"] - The prefix for the ID.
 * @param {boolean} props.isCardEnabled - Indicates whether the card is enabled or not.
 * @param {boolean} props.isCardSuspended - Indicates if the card is suspended.
 * @param {string} props.image - The image URL for the card.
 * @param {Object[]} props.data - The data to be displayed in the card details section.
 * @param {string} props.data.retailBrand - The retail brand value.
 * @param {string} props.data.affiliatedTo - The affiliated to value.
 * @param {string} props.data.acceptedOn - The accepted on value.
 * @param {string} props.data.status - The status value.
 * @returns {JSX.Element} The rendered FFCGridCard component.
 */
export default function FFCGridCard(props) {
    return (
        <StyledPage className={`${props?.className || ""}`}>
            <GridCardComponent
                title={props?.title}
                id={props?.id}
                hasBorders={props?.hasBorders}
                titlePrefix={props?.titlePrefix || "STORE"}
                idPrefix={props?.idPrefix || "BOTAG ID"}
                isCardEnabled={props?.isCardEnabled}
                isCardSuspended={props?.isCardSuspended}
                image={props?.image}
                imageFit="cover"
                isImageWithOverlay={true}
                imageOverlay={props?.imageOverlay}
                details={[
                    { key: "Retail Brand:", value: props?.data?.retailBrand ?? "--" },
                    { key: "Affiliated to:", value: props?.data?.affiliatedTo ?? "--" },
                    { key: "Accepted on:", value: formatDefaultDateTime(props?.data?.acceptedOn) },
                    { key: "Status:", value: getSpanWithColor(props?.data?.status ?? "--", defaultStatusColors) }
                ]}
                edit={{
                    hidden: true
                }}
                suspend={{
                    hidden: true
                }}
                delete={{
                    hidden: true
                }}
                primaryButton={props?.primaryButton ?? { title: "Details" }}
                secondaryButton={props?.secondaryButton ?? { title: "Stock" }}
            />
        </StyledPage>
    );
}
