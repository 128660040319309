import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import { useNavigate, useOutletContext } from "react-router";
import ContainerLoader from "../../common/loaders/ContainerLoader";
import CardComponentContainer from "../../common/cardContainers/CardComponentContainer";
import BtnLargeSolid from "../../common/buttons/BtnLargeSolid";
import * as Library from "../../../utils/Library";
import StoreHours from "./createStoreComponents/StoreHours";
import DeliveryOptionsAvailable from "./createStoreComponents/DeliveryOptionsAvailable";
import StoreLocation from "./createStoreComponents/StoreLocation";
import StoreIDCard from "./createStoreComponents/StoreIDCard";
import InputTimePicker from "./createStoreComponents/InputTimePicker";

/* #region SCOPED CSS */
const StyledPage = styled.div`
    .input-remove-default {
        border: none;
        outline: none;
        background: none;
    }

    .selects {
        background: var(--backgrounds-and-lines-n-600, #10141b);
    }

    .selects.mandatory {
        border: 1px solid transparent;
        border-left: 5px solid var(--text-icons-orange-500);
        border-radius: 4px;
        outline: none;
    }

    .selects.mandatory > div {
        border: none;
        cursor: pointer;
    }

    .selects.mandatory.error {
        border: 1px solid var(--text-icons-red-500);
        border-left: 5px solid var(--text-icons-red-500);
    }

    .main-window-header {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        display: flex;
        flex-direction: row;
        row-gap: 0px;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        height: 68px;
        position: relative;
    }
    .frame-40 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
    .btn-large-solid {
        background: var(--buttons-all-platforms-gray-300, #1e2631);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 120px;
        height: 35px;
        position: relative;
    }
    .button {
        color: var(--buttons-all-platforms-gray-200, #4f5b6d);
        text-align: left;
        font-family: var(--button-text-small-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 10px);
        letter-spacing: var(--button-text-small-letter-spacing, 0.0125em);
        font-weight: var(--button-text-small-font-weight, 400);
        text-transform: uppercase;
        position: relative;
    }
`;

/* #endregion */

export default function CreateStorePage() {
    document.title = `${APP_TITLE} - Create Store`;

    /* #region STATES */
    const navigate = useNavigate();
    const { MainLayoutProps, layoutType } = useOutletContext();
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };

    const [isLoading, setIsLoading] = useState(false);
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);

    const [formFieldRetailBrandID, setFormFieldRetailBrandID] = useState(null);
    const [formFieldRetailBrandName, setFormFieldRetailBrandName] = useState("");
    const [formFieldStoreName, setFormFieldStoreName] = useState("");
    const [imageStoreFacadeData, setImageStoreFacadeData] = useState(null);
    const [imageStoreInteriorData, setImageStoreInteriorData] = useState(null);

    const [formFieldGooglePlusCode, setFormFieldGooglePlusCode] = useState("");
    const [formFieldCountry, setFormFieldCountry] = useState("");
    const [formFieldCity, setFormFieldCity] = useState("");
    const [formFieldPostalCode, setFormFieldPostalCode] = useState("");
    const [formFieldAddressLine1, setFormFieldAddressLine1] = useState("");
    const [formFieldAddressLine2, setFormFieldAddressLine2] = useState("");
    const [formFieldShoppingCenter, setFormFieldShoppingCenter] = useState("");
    const [formFieldLatitude, setFormFieldLatitude] = useState("");
    const [formFieldLongitude, setFormFieldLongitude] = useState("");

    const [pickupEnabled, setPickupEnabled] = useState(false);
    const [courierEnabled, setCourierEnabled] = useState(false);
    const [shippingEnabled, setShippingEnabled] = useState(false);

    const [storeHoursData, setStoreHoursData] = useState({});

    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setupHeaders(props);
    }, []);

    useEffect(() => {
        if (
            formFieldRetailBrandID &&
            formFieldStoreName &&
            formFieldGooglePlusCode &&
            formFieldCountry &&
            formFieldCity &&
            formFieldAddressLine1 &&
            formFieldLatitude &&
            formFieldLongitude &&
            imageStoreFacadeData &&
            imageStoreInteriorData
        ) {
            setSaveBtnDisabled(false);
        } else {
            setSaveBtnDisabled(true);
        }
    }, [
        formFieldRetailBrandID,
        formFieldStoreName,
        formFieldGooglePlusCode,
        formFieldCountry,
        formFieldCity,
        formFieldAddressLine1,
        formFieldAddressLine2,
        formFieldShoppingCenter,
        formFieldLatitude,
        formFieldLongitude,
        imageStoreFacadeData,
        imageStoreInteriorData
    ]);
    /* #endregion */

    /* #region METHODS */

    const onSaveClicked = async () => {
        const postData = {
            retailBrandID: formFieldRetailBrandID,
            name: formFieldStoreName,
            facadeImageData: imageStoreFacadeData,
            interiorImageData: imageStoreInteriorData,

            latitude: formFieldLatitude,
            longitude: formFieldLongitude,
            googlePlusCode: formFieldGooglePlusCode,
            country: formFieldCountry,
            city: formFieldCity,
            postalCode: formFieldPostalCode,
            address: formFieldAddressLine1,
            addressNumber: formFieldAddressLine2, //TODO: review this... addressLine2 was changed to addressNumber
            shoppingCenter: formFieldShoppingCenter,

            openHours: storeHoursData,

            pickupEnabled: pickupEnabled,
            courierEnabled: courierEnabled,
            shippingEnabled: shippingEnabled
        };

        if (postData?.debugMode) {
            console.log("postData", postData);
            return;
        }

        const res = await Library.makePostRequest("createStore", postData, false, setIsLoading);
        console.log("res", res);

        if (res?.data?.status === 200) {
            Library.showSuccessMessage("Store created successfully");
            navigate("/stores");
        }
    };
    /* #endregion */

    return (
        <StyledPage style={{ marginTop: "-24px" }}>
            <ContainerLoader isLoading={isLoading} isLoadingOverlay={true} addBlurFilters={true}>
                <div className="flex flex-dir-column gap-16 w-100">
                    <div className="main-window-header">
                        <div className="frame-40">
                            <BtnLargeSolid
                                type="submit"
                                onClick={onSaveClicked}
                                disabled={saveBtnDisabled || isLoading}
                                text={"SAVE"}
                            />
                        </div>
                    </div>

                    <CardComponentContainer title="Store ID" className="mt-24px">
                        <InputTimePicker />
                        <StoreIDCard
                            formFieldRetailBrandID={formFieldRetailBrandID}
                            formFieldStoreName={formFieldStoreName}
                            imageStoreFacadeData={imageStoreFacadeData}
                            imageStoreInteriorData={imageStoreInteriorData}
                            setFormFieldRetailBrandID={setFormFieldRetailBrandID}
                            setFormFieldRetailBrandName={setFormFieldRetailBrandName}
                            setFormFieldStoreName={setFormFieldStoreName}
                            setImageStoreFacadeData={setImageStoreFacadeData}
                            setImageStoreInteriorData={setImageStoreInteriorData}
                        />
                    </CardComponentContainer>

                    <CardComponentContainer title="Store Location">
                        <StoreLocation
                            formFieldStoreName={formFieldStoreName}
                            formFieldGooglePlusCode={formFieldGooglePlusCode}
                            formFieldCountry={formFieldCountry}
                            formFieldCity={formFieldCity}
                            formFieldPostalCode={formFieldPostalCode}
                            formFieldAddressLine1={formFieldAddressLine1}
                            formFieldAddressLine2={formFieldAddressLine2}
                            formFieldShoppingCenter={formFieldShoppingCenter}
                            formFieldLatitude={formFieldLatitude}
                            formFieldLongitude={formFieldLongitude}
                            setFormFieldGooglePlusCode={setFormFieldGooglePlusCode}
                            setFormFieldLatitude={setFormFieldLatitude}
                            setFormFieldLongitude={setFormFieldLongitude}
                            setFormFieldAddressLine1={setFormFieldAddressLine1}
                            setFormFieldAddressLine2={setFormFieldAddressLine2}
                            setFormFieldPostalCode={setFormFieldPostalCode}
                            setFormFieldCountry={setFormFieldCountry}
                            setFormFieldCity={setFormFieldCity}
                            setFormFieldShoppingCenter={setFormFieldShoppingCenter}
                        />
                    </CardComponentContainer>

                    <CardComponentContainer title="Store Hours">
                        <StoreHours setStoreHoursData={setStoreHoursData} />
                    </CardComponentContainer>

                    <CardComponentContainer title="Delivery Options Available">
                        <DeliveryOptionsAvailable
                            pickupEnabled={pickupEnabled}
                            setPickupEnabled={setPickupEnabled}
                            courierEnabled={courierEnabled}
                            setCourierEnabled={setCourierEnabled}
                            shippingEnabled={shippingEnabled}
                            setShippingEnabled={setShippingEnabled}
                        />
                    </CardComponentContainer>
                </div>
            </ContainerLoader>
        </StyledPage>
    );
}

function setupHeaders(props, onRefreshAction) {
    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Stores", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/stores", active: true, disabled: false, title: "Stores" },
        { to: "/stores/staff", active: false, disabled: true, title: "Staff" }
    ]);
    props.setControlBarRightBtns([
        { to: "/stores", active: false, disabled: false, title: "View", icon: "eye" },
        { to: "/stores/create", active: true, disabled: false, title: "Add", icon: "plus" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        visible: false,
        gridEnabled: true,
        listEnabled: true,
        onRefreshAction: onRefreshAction
    });
}
