import React, { useState } from "react";

const LayoutContext = React.createContext();

export const LayoutProvider = ({ children }) => {
    // LOADINGS STATES
    const [isLoadingAccountPage, setIsLoadingAccountPage] = useState(false);

    // POPUPS STATES
    const [showIsSubscribedPopup, setShowIsSubscribedPopup] = useState(false);

    return (
        <LayoutContext.Provider
            value={{
                isLoadingAccountPage,
                setIsLoadingAccountPage,

                showIsSubscribedPopup,
                setShowIsSubscribedPopup
            }}>
            {children}
        </LayoutContext.Provider>
    );
};

export default LayoutContext;
