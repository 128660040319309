import Chart from "react-apexcharts";

const AnalyticsGraphComponent = ({ options, series, type, height, width }) => {
    return (
        <>
            <Chart type={type} height={height} width={width} options={options} series={series} />
        </>
    );
};

export default AnalyticsGraphComponent;
