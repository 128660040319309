import React from "react";
import styled from "styled-components";
import xclose from "../../../assets/icons/x-close1.svg";
import download from "../../../assets/icons/download-020.svg";
import {
    privacyLastUpdated,
    privacyPolicyData,
    privacyPolicyDownloadLink,
    privacyPolicyIntroData,
    privacyTitle,
    termsAndConditionsData,
    termsAndConditionsDownloadLink,
    termsAndConditionsIntroData,
    termsAndConditionsLastUpdated,
    termsAndConditionsTitle
} from "../../../config/termsAndPrivacy";

const StyledPage = styled.div`
    &.terms-conditions-container {
        width: 400px;
        background-color: #121821;
        position: absolute;
        right: 0px;
        height: 100dvh;
        z-index: 99999999;
        padding-top: 24px;
        padding-bottom: 24px;
        box-sizing: border-box;
    }

    @media (max-width: 1024px) {
        &.terms-conditions-container {
            width: 100%;
        }
    }

    .frame-313297,
    .frame-313297 * {
        box-sizing: border-box;
    }
    .frame-313297 {
        background: var(--backgrounds-and-lines-n-500, #141922);
        display: flex;
        padding: 0 24px;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        height: 100%;
        position: sticky;
        overflow-y: scroll;
    }
    .frame-313303 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313299 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313300 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .btn-icon-swap {
        background: var(--buttons-all-platforms-gray-300, #1e2631);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 120px;
        height: 35px;
        position: relative;
    }
    .frame-289 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .download-02 {
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        position: relative;
        overflow: visible;
    }
    ._3-imported {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-size: var(--button-text-small-font-size, 10px);
        letter-spacing: var(--button-text-small-letter-spacing, 0.0125em);
        font-weight: var(--button-text-small-font-weight, 400);
        text-transform: uppercase;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        top: 1px;
    }
    .frame-313301 {
        background: var(--backgrounds-and-lines-n-500, #141922);
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        width: 35px;
        position: relative;
    }
    .x-close {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .frame-313302 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313298 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .b-otag-norge-as-privacy-policy {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-size: var(--heading-3-font-size, 20px);
        font-weight: var(--heading-3-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .last-updated-01-08-2022 {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-size: var(--subtitle-1-font-size, 14px);
        letter-spacing: var(--subtitle-1-letter-spacing, 0.0025em);
        font-weight: var(--subtitle-1-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
`;

const TermsAndPrivacyStyled = styled.div`
    & {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .terms-header {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-size: var(--body-font-size, 16px);
        font-weight: var(--body-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .terms-text {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-size: var(--subtitle-1-font-size, 14px);
        letter-spacing: var(--subtitle-1-letter-spacing, 0.0025em);
        font-weight: var(--subtitle-1-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .terms-text-ul {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        list-style-type: disc;
        padding-left: 1em;
        font-size: var(--subtitle-1-font-size, 14px);
        letter-spacing: var(--subtitle-1-letter-spacing, 0.0025em);
        font-weight: var(--subtitle-1-font-weight, 400);
        margin-bottom: 0;
        margin-top: 8px;
    }
    .terms-text-ul li {
        margin-bottom: 8px;
        margin-left: 12px;
    }
    .terms-text-ul li:last-child {
        margin-bottom: 0;
    }

    .terms-block {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }

    .terms-text-tab1 {
        margin-left: 25px;
        margin-top: 8px;
    }
`;

/**
 * Shows the Terms and Conditions panel
 * @param {string} titleOverride - titleOverride of the panel
 * @param {string} type - Type of the panel (terms or privacy)
 * @param {function} onCloseClicked - Function to call when the close button is clicked
 * @returns {JSX.Element}
 */
const TermsAndPrivacyPanel = ({ titleOverride, type = "terms", onCloseClicked }) => {
    /* #region VARS */
    const lastUpdated = type === "terms" ? termsAndConditionsLastUpdated : privacyLastUpdated;
    const title = !!titleOverride ? titleOverride : type === "terms" ? termsAndConditionsTitle : privacyTitle;
    const downloadButtonURL = type === "terms" ? termsAndConditionsDownloadLink : privacyPolicyDownloadLink;
    /* #endregion */

    /* #region METHODS */
    const handleDownload = () => {
        window.open(downloadButtonURL, "_blank");
    };

    const handleClose = () => {
        if (onCloseClicked) {
            onCloseClicked();
        }
    };
    /* #endregion */

    return (
        <StyledPage className="terms-conditions-container">
            <div className="frame-313297">
                <div className="frame-313303">
                    <div className="frame-313299">
                        <div className="frame-313300">
                            {downloadButtonURL && (
                                <div className="btn-icon-swap cursor-pointer" onClick={handleDownload}>
                                    <div className="frame-289">
                                        <img className="download-02" src={download} alt="" />
                                        <div className="_3-imported">DOWNLOAD</div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="frame-313301">
                            <img className="x-close cursor-pointer" src={xclose} onClick={handleClose} alt="" />
                        </div>
                    </div>
                    <div className="frame-313302">
                        <div className="frame-313298">
                            <div className="b-otag-norge-as-privacy-policy">{title}</div>
                        </div>
                        {lastUpdated && <div className="last-updated-01-08-2022">Last updated: {lastUpdated}</div>}
                    </div>
                </div>

                {type === "terms"
                    ? GenerateContentHTML(termsAndConditionsIntroData, termsAndConditionsData)
                    : GenerateContentHTML(privacyPolicyIntroData, privacyPolicyData)}
            </div>
        </StyledPage>
    );
};
export default TermsAndPrivacyPanel;

const GenerateContentHTML = (titleLines, termsBlocks) => {
    return (
        <TermsAndPrivacyStyled>
            {titleLines.map((item, index) => (
                <div key={index} className="terms-text" dangerouslySetInnerHTML={{ __html: item }}></div>
            ))}

            {termsBlocks.map((item, index) => (
                <div key={index} className="terms-block">
                    <div className="terms-header">{item.title}</div>
                    <div className="terms-text" dangerouslySetInnerHTML={{ __html: item.html }}></div>
                </div>
            ))}
        </TermsAndPrivacyStyled>
    );
};
