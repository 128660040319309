import React from "react";
import styled from "styled-components";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        display: flex;
        width: 120px;
        height: 35px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        //flex: 1 0 0;
        //flex-shrink: 0;

        border-radius: 4px;
        background: var(--Buttons-All-Platforms-Gray-500, #10141b);
    }

    &.enabled {
        // Added Border to fix small issue
        border: 1px solid var(--Buttons-All-Platforms-Gray-500, #10141b);
    }

    .Button-Text-Large {
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Button-Text-Large */
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.15px;
        text-transform: uppercase;
    }

    // HOVER CHANGES
    &.enabled:hover {
        background: var(--Buttons-All-Platforms-Gray-500, #1e2631);
        border: 1px solid var(--App-Accent-Outline);
        cursor: pointer;
    }

    // ACTIVE CHANGES
    &.enabled.active {
        border-radius: 4px;
        border: 1px solid var(--App-Accent-Outline, #0b0);
        background: var(--Buttons-All-Platforms-Gray-300, #1e2631);
    }

    // DISABLED CHANGES
    &.disabled {
        cursor: not-allowed;
    }

    &.disabled .Button-Text-Large {
        color: var(--text-icons-gray-300, #d1d1d1);
        cursor: not-allowed;
    }

    /* TODO: DISABLED HOVER CSS NON EXISTENT
    &.disabled {
        border: 1px solid var(--Buttons-All-Platforms-Gray-500, #10141b);
    }

    &.disabled:hover {
         border: 1px solid var(--App-Accent-Outline);
    } */
`;
/* #endregion */

/**
 * Button component with large solid inverted 02 style.
 *
 * @component
 * @example
 * // Usage:
 * <BtnLargeSolidInverted02 text="Click me" disabled="false" />
 *
 * @param {Object} props - The component props.
 * @param {string} props.text - The text to display on the button.
 * @param {string} props.isActive - Whether the button is active or not.
 * @param {string} props.disabled - Whether the button is disabled or not.
 * @param {function} [props.onClick] - The function to call when the button is clicked.
 * @param {string} [props.style] - Additional CSS style for the component.
 * @param {string} [props.className] - Additional CSS class name for the component.
 *
 * @returns {JSX.Element} The rendered BtnLargeSolidInverted02 component.
 */
export default function BtnLargeSolidInverted02(props) {
    return (
        <StyledPage
            className={`btn-large ${props?.disabled === true ? "disabled" : "enabled"} ${props?.className ?? ""} ${
                props?.isActive ? "active" : ""
            }`}
            onClick={props?.disabled ? undefined : props?.onClick}
            style={props?.style}>
            <label className="Button-Text-Large text-one-line">{props?.text || "--"}</label>
        </StyledPage>
    );
}
