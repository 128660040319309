import { useInfiniteQuery, useMutation } from "react-query";
import { axiosInstance } from "../axios";

export const useGetNdaAcceptedMutation = () => {
    return useMutation({
        mutationKey: ["nda-accepted"],
        mutationFn: async payload => {
            const { data, status } = await axiosInstance.post(`updateNDAaccepted`, payload);
            return { data, status };
        }
    });
};
