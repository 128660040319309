import CompaniesPage from "../components/pages/companies/CompaniesPage";
import CompaniesUsersPage from "../components/pages/companies/CompaniesUsersPage";

const routes = [
    {
        path: "/companies",
        element: <CompaniesPage />
    },
    {
        path: "/companies/users",
        element: <CompaniesUsersPage />
    }
];

export default routes;
