import React from "react";
import { Tooltip } from "antd";
// import Tooltip from "some-library";

const Tooltips = ({ title, children }) => {
    return (
        <Tooltip title={title} trigger="hover" defaultOpen={false} overlayClassName="custom-tooltip">
            {children}
        </Tooltip>
    );
};

export default Tooltips;
